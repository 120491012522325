

/** Insert new Permit.
 * 
 * @Method: POST
 * @Body: FormData
 * 
 * - in the formData:
 */
export const ADD_PERMIT = '/permit/add-permit';

/** Get the permit by permitId.
 * 
 * @Method: GET
 * - exmpole: /permit/${permitId}
 */
export const GET_PERMIT = '/permit';

/** Update the permit.
 * 
 * @Method: POST
 * @Body: FormData
 * 
 * - in the formData:
 */
export const UPDATE_PERMIT = '/permit/update-permit';

/** Retrieve the data for the permit report page.
 * 
 * @Method: POST
 * @Body: FormData
 * 
 * - in the formData:
 * - fromDate: The date from witch start the counting, date format: DD/MM/YYYY.
 * - toDate: The date until counting, date format: DD/MM/YYYY.
 */
export const GET_PERMIT_REPORT_DETAILS = '/permit/permit-report';