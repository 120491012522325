import React from 'react'
import { formaterMoneyAmount } from '../../../../Helpers/format/numberFormat';
import PaymentMethod from '../../../../types/PaymentMethod';

const SpanPaymentAmount = ({ paymentMethodCode, moneyAmount }) => {

    const moneyAmountStr = formaterMoneyAmount(moneyAmount);

    if (paymentMethodCode === PaymentMethod.NONE) {
        return '';
    }

    return (
        <span className='badge bg-light text-dark' style={{ fontSize: '1rem' }}>{moneyAmountStr}</span>
    )
}

export default SpanPaymentAmount