import moment from "moment";

export const initExpiredPermitFormData = {
    limit: 500,
    offset: 0,
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    onlyActivePermits: '0',
}

export const initExpiredPermitData = {
    count: 0,
    list: [],
    csvHeader: [
        { displayName: "שם מלא", id: "fullName" },
        { displayName: "מס רכב", id: "vehicleNum" },
        { displayName: "טלפון", id: "cellPhone" },
        { displayName: "מספר טלפון נוסף", id: "additionalPhone" },
        { displayName: "תאריך סיום", id: "endValidityDate" },
        { displayName: "יצרן", id: "makerName" },
    ]
}