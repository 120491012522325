import * as yup from 'yup';
import { onlyNumbersRegExp, phoneRegExp } from '../../Helpers/regExps';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, MOBILE_FORMAT_MESSAGE, 
    ONLY_DIGIT_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE, 
    START_DATE_GREATER_THEN_END_DATE_MESSAGE, 
    SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from '../../Errors/errorMessages';
import { isPersonIdValid, isFirstDateGreater } from '../../Helpers/vatidation/validation';
import PaymentMethod from '../../models/PaymentMethod';

export const addPermitSchema = yup.object().shape({
    carNumber: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .min(5, 'חייב להכיל לפחות 5 ספרות')
        .max(8, 'לכל היותר 8 ספרות')
        .required(REQUIRED_FIELD_MESSAGE),
    vColor: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר צבע')
        .required(REQUIRED_FIELD_MESSAGE),
    vType: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר דגם רכב')
        .required(REQUIRED_FIELD_MESSAGE),
    privateName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    familyName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    startValidityDate: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    endValidityDate: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('end-date-greater-then-start', START_DATE_GREATER_THEN_END_DATE_MESSAGE, function(value) {

            const { startValidityDate } = this.parent;
            return !isFirstDateGreater(startValidityDate, value);
        }),
    personId: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('endDate-bofore-startDate', 'מספר ת.ז אינו חוקי. צריך להכיל ספרת ביקורת', value => isPersonIdValid(value)),
    street: yup.string()
        .notOneOf([-1, '-1'], 'אנא בחר רחוב')
        .required(REQUIRED_FIELD_MESSAGE),
    houseNum: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    appartmentNum: yup.string()
        .notRequired()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    email: yup.string()
        .email('כתובת אימייל לא חוקית')
        .required(REQUIRED_FIELD_MESSAGE),
    mobile: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    phone: yup.string()
        .optional()
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    personIdfile: yup.mixed()
        .when(["hardCopyPersonId", "permitId"], ([hardCopyPersonId, permitId], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (permitId > 0 || hardCopyPersonId == 1 || hardCopyPersonId == '1') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
    carLicensefile: yup.mixed()
        .when(["hardCopyLicense", "permitId"], ([hardCopyLicense, permitId], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (permitId > 0 || hardCopyLicense == 1 || hardCopyLicense == '1') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
    workStatementfile: yup.mixed()
        .when(["ownershipCarType", "hardCopyLeasing", "permitId"], ([ownershipCarType, hardCopyLeasing, permitId], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (permitId > 0) {
                return schema.notRequired();
            }

            if (ownershipCarType == 1 || ownershipCarType == '1') {
                return schema.notRequired();
            }

            if (hardCopyLeasing == 1 || hardCopyLeasing == '1') {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {

                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
                        }
                    )
            );
        }),
        cardHolderId: yup.string()
        .when(["amount", "paymentMethod"], ([amount, paymentMethod], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (amount <= 0 || paymentMethod === PaymentMethod.CASH) {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test('cardHolderId-test', 'מספר ת.ז אינו חוקי. צריך להכיל ספרת ביקורת', value => isPersonIdValid(value))
            );
        }),
        cardNumber: yup.string()
        .when(["amount", "paymentMethod"], ([amount, paymentMethod], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (amount <= 0 || paymentMethod === PaymentMethod.CASH) {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
            );
        }),
        cardExpireDateYY: yup.string()
        .when(["amount", "paymentMethod"], ([amount, paymentMethod], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (amount <= 0 || paymentMethod === PaymentMethod.CASH) {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
            );
        }),
        cardExpireDateMM: yup.string()
        .when(["amount", "paymentMethod"], ([amount, paymentMethod], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (amount <= 0 || paymentMethod === PaymentMethod.CASH) {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
            );
        }),
        cardAddedNumber: yup.string()
        .when(["amount", "paymentMethod"], ([amount, paymentMethod], schema) => {

            // permitId > 0 => not new permit, no need to upload files
            if (amount <= 0 || paymentMethod === PaymentMethod.CASH) {
                return schema.notRequired();
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test('cardAddedNumber-tree-nums', 'המספר צריך להכיל 3 ספרות בדיוק', value => {return value.length == 3})
                    .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
            );
        }),
});
