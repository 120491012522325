import React from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Button from './Button';

const ViewPermitRequestBtn = ({ rowDbId, size }) => {

    const url = `/permit-request/request-details/${rowDbId}`;

    return (
        <Link to={url}>
            <Button size={size} endIcon={<AiOutlineFileSearch />}>
                צפייה בבקשה
            </Button>
        </Link>
    );
}

ViewPermitRequestBtn.defaultProps = {
    size: '',
};

export default ViewPermitRequestBtn;