import React from 'react';
import LoadingDiv from '../../Components/UI/loadingDiv/LoadingDiv';
import style from './sendSms.module.css';
import useSendSms from './useSendSms';
import DangerAlert from '../../Components/Alerts/DangerAlert';
import ResponseStatus from '../../types/ResponseStatus';
import GeneralModal from '../../Components/GeneralModal/GeneralModal';
import SendSmsBtn from './components/SendSmsBtn';

const MobileDiv = ({ moblie }) => {

    const classes = 'col ' + style.mobileSpan;

    return (
        <div className='row mt-4'>
            <div className={classes}>שולח הודעה למספר נייד <span className={style.mobileNumSpan}>{moblie}</span></div>
        </div>
    );
}

const SmsContent = ({ content }) => {
    const contentSmsTitle = 'תוכן הודעה:';

    return (
        <div className='row mt-4'>
            <div className='col'>
                <span className={style.contentSmsTitle}>{contentSmsTitle}</span>
                <div className={style.contentSms}>{content}</div>
            </div>
        </div>
    );
}

const SendSmsModal = ({ request, index, closingFun, updateRequest, isShow }) => {

    const title = 'שליחת הודעת SMS';
    const { sendSms, smsMessage, isLoading, error, setError, isBtnDesable, setisBtnDesable } = useSendSms(request, isShow);


    let alertDiv = '';
    let content = '';

    content = isLoading ? <LoadingDiv /> : smsMessage

    if (error.hasError) {

        alertDiv = (
            <div className='row mt-1'>
                <div className='col'>
                    <DangerAlert type={'danger'} message={error.message} />
                </div>
            </div>
        );
    }

    if (!isShow) {
        return '';
    }

    return (
        <GeneralModal
            isShow={isShow}
            title={title}
            closingFun={closingFun}
            content={(
                <div className='container pt-2 pb-2'>
                    {alertDiv}
                    <SmsContent content={content} />
                    <MobileDiv moblie={request.mobile} />
                </div>
            )}
            footer={(
                <div className='pt-3'>
                    <SendSmsBtn disabled={isBtnDesable} onClick={handleClickSendSms} />
                </div>
            )}
        />
    );

    async function handleClickSendSms() {

        const result = await sendSms();
        setisBtnDesable(true);

        if (result.status === ResponseStatus.SUCCESS) {

            updateRequest(index, result.date);
            closingFun();
        } else {

            setError({ hasError: true, message: 'הודעה לא נשלחה' });
        }
    }
}

export default SendSmsModal