import React, { useContext } from 'react';
import { ProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const SelectStreet = ({ name, handleBlur, handleChange, errorMsg, value }) => {
    const {projectDetails} = useContext(ProjectDetailsContext);
    let streets = [{ value: -1, label: 'אין אופציות' }];

    streets = projectDetails.streets.getAllStreets().map((street, index) => {

        return { label: decodeNCRStringIfNeeded(street.name), value: street.id }
    });

    streets = [{ value: -1, label: '' }, ...streets];
    const valueStr = streets.findLast(option => option.value == value);

    return (

        <SelectWithSearch
            name={name}
            label={'רחוב'}
            selects={streets}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={valueStr}
            errorMsg={errorMsg}
        />

    )
}

export default SelectStreet