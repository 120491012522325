import React from 'react';
import Navbar from './Navbar/Navbar';
import style from './Header.module.css';

const Header = (props) => {

    return (
        <header className={style.header}>
            <Navbar />
        </header>
    )
}

export default Header
