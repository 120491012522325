import React, { useContext } from 'react';
import PersonDetails from './PersonDetails';
import VehicleDetails from './VehicleDetails';
import CommentAndPic from './CommentAndPic';
import { ProjectDetailsContext } from "../../../../contexts/ProjectDetailsContext";
import ContectInfo from './ContectInfo';
import PermitRequestDetails from './PermitRequestDetails';
import PaymentMethod from '../../../../models/PaymentMethod';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import PaymentDetails from './PaymentDetails';

const RequestDetailsForm = () => {
    const { projectDetails } = useContext(ProjectDetailsContext);
    const { values } = useRequestManagmentPageContext();
    const paymentConfig = projectDetails.paymentConfig;
    const hasPayment = paymentConfig.getHasPayment();

    let hasPayed = false;
    let paymentMethod = PaymentMethod.NONE;

    if (values.hasPaidCash) paymentMethod = PaymentMethod.CASH;
    if (values.hasPaidCredit) paymentMethod = PaymentMethod.CREDIT_CARD;

    return (
        <form>
            <div className="row mb-3">
                <div className='col col-4'>
                    <PersonDetails />
                </div>
                <div className='col col-8'>
                    <VehicleDetails />
                </div>
            </div>

            <div className="row mb-3">
                <div className='col col-6'>
                    <CommentAndPic />
                </div>
                <div className='col col-6'>
                    <ContectInfo />
                </div>
            </div>

            {
                hasPayment ? (
                    <div className="row mb-3">
                        <div className='col'>
                            <PaymentDetails />
                        </div>
                    </div>
                ) : ''
            }

            <PermitRequestDetails />
        </form>
    );
}

export default RequestDetailsForm;