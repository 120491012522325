import { createContext, useContext, useEffect, useState } from "react";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import PaymentReportPage from "../../Pages/reports/paymentsReport/PaymentReportPage";
import { useComponentState } from "../../Hooks/useComponentState";
import moment from "moment";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { GET_PAYMENTS_REPORT } from "../../api/paymentReportApi";
import translateErrorCode from '../../Helpers/errors/translateErrorCode';

export const PaymentsReportPageContext = createContext();

const PaymentsReportPageProvider = () => {

    const axiosPrivate = useAxiosPrivate();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [pRecordsChartArea, setPRecordsChartArea] = useState([]);
    const [displayByPieChart, setDisplayByPieChart] = useState(1);
    const { projectDetails } = useProjectDetailsContext();
    
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        handleSearch(signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    async function handleSearch(signal = undefined) {

        const controller = new AbortController();
        const withAbortSig = signal ? true : false;

        if (!withAbortSig) {
            signal = controller.signal;
        }

        try {
            setIsLoading();

            const response = await axiosPrivate.post(
                GET_PAYMENTS_REPORT, { from: fromDate, to: toDate }, { signal: signal }
            );

            const jsonData = response.data;

            console.log(JSON.stringify(jsonData));
            const pRecordsChartAreaTemp = convertRawDataFromServerToChartArea(jsonData.paymentsRecordsGroupByDate);

            setPRecordsChartArea(pRecordsChartAreaTemp);
            setIsNotLoading();
        } catch (error) {

            let message = 'בעיה כללית בשרת';

            if (error.response) {

                message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                message = error.message;
                console.log('user canceled the request', error.message);
            }

            setDangerAlert('בעיה קשה בשרת');
        }
    }

    function convertRawDataFromServerToChartArea(datas) {

        if (!datas || !Array.isArray(datas)) return [];

        return datas.map(data => {
            return {
                name: data.dateAndTime,
                cashAmount: data.cashAmount,
                cashNumberOfUnits: data.cashNumberOfUnits,
                creditCardAmount: data.creditCardAmount,
                creditCardNumberOfUnits: data.creditCardNumberOfUnits,
            }
        });
    }

    return (
        <PaymentsReportPageContext.Provider value={{
            fromDate, setFromDate, toDate, setToDate, componentState, removeAlert, handleSearch,
            displayByPieChart, setDisplayByPieChart, pRecordsChartArea
        }}>
            <PaymentReportPage />
        </PaymentsReportPageContext.Provider>
    );
}

export default PaymentsReportPageProvider;

export const usePaymentsReportPageContext = () => useContext(PaymentsReportPageContext);