import React from 'react'
import ActionBoxCard from '../../../../Components/UI/Card/ActionBoxCard'
import { FiUploadCloud } from 'react-icons/fi';
import GoBackBtn from '../../../../Components/Buttons/GoBackBtn'
import ViewFilesBtn from '../../../../Components/Buttons/ViewFilesBtn'
import HistoryPermitList from '../../../../Components/Buttons/History/HistoryPermitList'
import SaveBtn from '../../../../Components/Buttons/SaveBtn'
import Button from '../../../../Components/Buttons/Button'
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';

const PermitDetailsButtonsBox = () => {

    const { values, componentState, handleUpdatePermit, openHistoryModal,
        openUploadFileModal } = usePermitDetailsPageContext();
    
    const isDisabled = componentState.isLoading ? true : false;

    return (
        <ActionBoxCard>
            <div style={{ display: 'flex', columnGap: '1rem', rowGap: '0.5rem' }}>
                <SaveBtn disabled={isDisabled} onClick={event => { handleUpdatePermit(event); }} />
                <HistoryPermitList onClick={() => openHistoryModal(false, values.permitId)} />
                <Button key={'openUploadImageModal'} onClick={openUploadFileModal} endIcon={<FiUploadCloud />}>הוספת קובץ</Button>
                <ViewFilesBtn disabled={isDisabled} personId={values.personId} />
            </div>
            <GoBackBtn />
        </ActionBoxCard>
    )
}

export default PermitDetailsButtonsBox;