import { createContext, useContext, useReducer } from "react";
import { APP_INFO_ACTION, AppIngoReducer } from "../reducers/appInfoReducer";
import { initAppInfoData } from "../data/default/appInfo.data";
import moment from "moment";


export const AppInfoContext = createContext();

export const AppInfoProvider = (props) => {
    const [appInfoState, appInfoStateDispatch] = useReducer(AppIngoReducer, initAppInfoData);

    return (
        <AppInfoContext.Provider value={{ appInfoState, updateInitForm, getCurrentInitForm }}>
            {props.children}
        </AppInfoContext.Provider>
    );

    /**
     * 
     * @param {*} initFormName
     * @param {*} defaultInitFormName 
     */
    function getCurrentInitForm(initFormName, defaultInitFormName) {

        if(appInfoState.formsInitData[initFormName] && appInfoState.formsInitData[initFormName].currentForm) {

            const currentFormUpdateTime = moment(appInfoState.formsInitData[initFormName].updateDate, 'YYYY-MM-DD HH:mm:ss');
            const nowTime = moment(new Date());
            currentFormUpdateTime.add(10, 'minutes');
            const isSameOrAfter = nowTime.isAfter(currentFormUpdateTime);

            if (isSameOrAfter) {

                return appInfoState.formsInitData[initFormName].initForm;
            } 
            
            return appInfoState.formsInitData[initFormName].currentForm
        }

        return defaultInitFormName;
    }

    /**
     * 
     * @param {*} initFormName 
     * @param {*} formData 
     */
    function updateInitForm(initFormName, formData) {
        appInfoStateDispatch({
            type: APP_INFO_ACTION.UPDATE_INIT_FORM,
            payload: {
                formName: initFormName,
                formData: formData
            }
        });
    }
}

export const useAppInfoContext = () => useContext(AppInfoContext);