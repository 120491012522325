import React, { useContext } from 'react'
import { ProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const SelectCarMaker = ({name, handleBlur, handleChange, errorMsg, value}) => {
    const {projectDetails} = useContext(ProjectDetailsContext);
    let makers = [{ value: -1, name: 'אין אופציות' }];

    let key = 1;
    makers = projectDetails.types.getAllMakers().map(maker => {
    
        return {label: decodeNCRStringIfNeeded(maker.name), value: maker.id }; 
    });

    makers = [{ value: -1, label: 'צריך לבחור' }, ...makers];
    
    const valueStr = makers.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'דגם רכב'}
            value={valueStr}
            isRequired={true}
            selects={makers}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    )
}

export default SelectCarMaker