import React, { useReducer } from "react";
import { COMPONENT_STATE_ACTION, componentReducer } from "../reducers/componentReducer";
import { alertColors } from "../types/alertColors";


const initData = {
    hasAlert: false,
    alertColor: '',
    message: '',
    isLoading: false
}

/** Hook that manage the component state.
 * 
 * hookData: {
        hasAlert: boolean,
        alertColor: @{alertColors type},
        message: string,
        isLoading: boolean
    }
 * 
 * @returns 
 */
export const useComponentState = () => {

    const [componentState, stateDispatch] = useReducer(componentReducer, initData);

    function setIsLoading() {
        stateDispatch({ type: COMPONENT_STATE_ACTION.LOADING });
    }

    function setIsNotLoading() {
        stateDispatch({ type: COMPONENT_STATE_ACTION.NOT_LOADING });
    }

    function setAlert(message, hasAlert, alertColor) {

        stateDispatch({
            type: COMPONENT_STATE_ACTION.SET_ALERT,
            payload: {
                hasAlert: hasAlert,
                message: message,
                alertColor: alertColor,
            }
        });
    }

    function setDangerAlert(message) {
        
        stateDispatch({
            type: COMPONENT_STATE_ACTION.SET_ALERT,
            payload: {
                hasAlert: true,
                message: message,
                alertColor: alertColors.DANGER,
            }
        });
    }

    function setSuccessAlert(message) {
        
        stateDispatch({
            type: COMPONENT_STATE_ACTION.SET_ALERT,
            payload: {
                hasAlert: true,
                message: message,
                alertColor: alertColors.SUCCESS,
            }
        });
    }

    function removeAlert() {
        stateDispatch({ type: COMPONENT_STATE_ACTION.REMOVE_ALERT });
    }

    return {
        componentState,
        setIsLoading,
        setIsNotLoading,
        setAlert,
        setDangerAlert,
        setSuccessAlert,
        removeAlert
    };
}