import React from 'react';

const Table = ({ headers, rows, footers, messageIfNone }) => {

    const header = headers.map(title => <th key={`tableHeader_${title}`}>{title}</th>);
    const count = Array.isArray(rows) ? rows.length : 0;

    if(count <= 0) {
        return <h3><span className="badge bg-dark">{messageIfNone}</span></h3>;
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr> {header} </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
            <tfoot>
                {footers}
            </tfoot>
        </table>
    );
}

Table.defaultProps = {
    headers: [],
    rows: [],
    footers: '',
    messageIfNone: 'אין נתונים'
};

export default Table;