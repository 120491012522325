import React from 'react'
import Style from './button.module.css';

const Button = ({ children, onClick, theme, disabled, style, size, startIcon, endIcon, text, nowrap, type, title, outline, isLoading }) => {

    let spanStart = '';
    let spanEnd = '';
    let classes = Style.myBtn + ' ' + getClass(theme, outline);
    classes += getSize(size);
    let classesIcon = Style.icon;

    if (nowrap) classes += ' ' + Style.btnNowrap;

    if (endIcon) {

        classesIcon += ' ' + Style.startIcon;

        spanEnd = (
            <span className={classesIcon}>{endIcon}</span>
        );
    }

    if (startIcon) {

        classesIcon += ' ' + Style.endIcon;

        spanStart = (
            <span className={classesIcon}>{startIcon}</span>
        );
    }

    if (isLoading) {
        spanEnd = '';
        spanStart = '';
    }

    return (
        <button
            style={style}
            disabled={disabled}
            type={type}
            title={title}
            onClick={e => onClick(e)}
            className={classes}>
            {spanStart}
            {isLoading ? (
                <>
                    <span role="status">נשלח... </span>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                </>
            ) : <span>{text}{children}</span>}
            {spanEnd}
        </button>
    );

    function getClass(theme, outline) {

        let classeoutline = '';
        if (outline) classeoutline = 'outline-';

        switch (theme) {
            case 'primary':
                return 'btn btn-' + classeoutline + 'primary';
            case 'secondary':
                return 'btn btn-' + classeoutline + 'secondary';
            case 'success':
                return 'btn btn-' + classeoutline + 'success';
            case 'danger':
                return 'btn btn-' + classeoutline + 'danger';
            case 'warning':
                return 'btn btn-' + classeoutline + 'warning';
            case 'info':
                return 'btn btn-' + classeoutline + 'info';
            case 'light':
                return 'btn btn-' + classeoutline + 'light';
            case 'dark':
                return 'btn btn-' + classeoutline + 'dark';
            case 'link':
                return 'btn btn-' + classeoutline + 'link';
            default:
                return 'btn btn-' + classeoutline + 'primary';
        }
    }

    function getSize(size) {

        if (size === 'small') return ' btn-sm';

        if (size === 'big') return ' btn-lg';

        return '';
    }
}

Button.defaultProps = {
    disabled: false,
    theme: "primary",
    type: 'button',
    style: {},
    onClick: () => { },
    size: '',
    title: '',
    startIcon: null,
    endIcon: null,
    text: '',
    nowrap: true,
    outline: false,
    isLoading: false,
};

export default Button