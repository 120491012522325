import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useComponentState } from "./useComponentState";


export function useGetFiles(){

    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const axiosPrivate = useAxiosPrivate();
    const [files, setFiles] = useState([]);

    async function getFiles(personId) {

        setIsLoading();
        let status = 'success';
        let message = 'success';

        try {

            const response = await axiosPrivate.get(`/files/${personId}`);

            const data = response.data;
            const list = data.data.files;
         
            status = data.status;
            message = data.message;
            const sortedList = list
                .filter(file => file.size >= 50)
                .sort((file1, file2) => file2.lastModified - file1.lastModified);
            
            setFiles(sortedList);
            setIsNotLoading();

        } catch (error) {
            console.log('error ' + error);
            message = 'בעיה כללית בשרת';
            status = 'failure';
            setDangerAlert(message);
        }

    }

    return {files, getFiles, componentState};
}