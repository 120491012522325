import React from 'react';
import style from './Footer.module.css';
import logo from '../../Assets/images/lolatech logo.png';
import { useAuthContextContext } from '../../contexts/AuthContext';

export const Footer = () => {

    const { authState } = useAuthContextContext();
    const { loggedIn } = authState;

    let footerDivClasses = style.footerDiv + ' ';
    footerDivClasses += loggedIn ? style.smallFooterDiv : style.bigFooterDiv;

    const year = '2024';

    return (
        <footer className={footerDivClasses}>
            <span className={style.name}> לולהטק בע"מ © {year}</span>
            <img className={style.logo} src={logo} alt='image' />
        </footer>
    )
}

export default Footer;
