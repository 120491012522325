import CreatedByTypes from "../../types/CreatedByTypes";
import PaymentMethod from "../../types/PaymentMethod";
import PermitStatusType from "../../types/PermitStatusType";
import RequestStatusType from "../../types/RequestStatusType";
import HistoryRecordType from "../../types/HistoryRecordType";

export function convertCodeCreatedByToText(createdByCode){
    
    switch (createdByCode) {
        case CreatedByTypes.CITIZEN:
            return 'אזרח';
        case CreatedByTypes.SECRETARY:
            return 'קבלת קהל';
        default:
            return 'בעיה בנתונים';
    }
}

export function convertCodeStatusRequestToText(statusCode){

    switch (statusCode) {
        case RequestStatusType.PENDING:
            return 'ממתינה לאישור';
        case RequestStatusType.APPROVED:
            return 'בקשה אושרה';
        case RequestStatusType.REJECTED:
            return 'בקשה נדחתה';
        default:
            return 'בעיה בנתונים';
    }
}

export function convertCodePaymentMethodToText(paymentMethodCode){

    switch (paymentMethodCode) {
        case PaymentMethod.NONE:
            return 'ללא';
        case PaymentMethod.CASH:
            return 'מזומן';
        case PaymentMethod.CARD:
            return 'אשראי';
        default:
            return 'בעיה בנתונים';
    }
}

export function convertPermitStatusCodeToText(code) {
    switch (code) {
        case PermitStatusType.ACTIVE:
            return 'פעיל';
        case PermitStatusType.NOT_ACTIVE:
            return 'לא פעיל';
        case PermitStatusType.CANCELED:
            return 'בוטל';
        case PermitStatusType.SUSPENDED:
            return 'משהה';
        default:
            return 'אין נתונים';
    }
} 

export function convertPaymentMethodCodeToText(code) {
    switch (code) {
        case PaymentMethod.CARD:
            return 'אשראי';
        case PaymentMethod.CASH:
            return 'מזומן';
        case PaymentMethod.NONE:
            return 'ללא תשלום';
        default:
            return 'אין נתונים';
    }
} 

export function convertHistoryStatusCodeToText(code) {

    if (code === HistoryRecordType.TAV_RENEW_STATUS_SENT_TO_SERVER) {
        return 'חידוש תו';
    } else if (code === HistoryRecordType.TAV_STATUS_CREATED_BY_SYSTEM) {
        return 'יצירת בקשה/תו עי ידי מערכת';
    } else if (code === HistoryRecordType.TAV_STATUS_INSERTED_BY_BACK_OFFICE) {
        return 'יצירת ';
    } else if (code === HistoryRecordType.TAV_STATUS_INSERTED_BY_CITIZEN) {
        return 'יצירת בקשה על ידי אזרח';
    } else if (code === HistoryRecordType.TAV_STATUS_INSERTED_BY_OFFICE) {
        return 'יצירת בקשה/תו בקבלת קהל';
    } else if (code === HistoryRecordType.TAV_STATUS_SENT_TO_SERVER_6) {
        return 'אישור בקשה';
    } else if (code === HistoryRecordType.TAV_STATUS_UPDATED_BY_OFFICE) {
        return 'עידכון בקשה/תו';
    } else if (code === HistoryRecordType.SENT_EMAIL_STATUS) {
        return 'נשלח אימייל';
    } else if (code === HistoryRecordType.PEMIT_CANCELED_PORTAL) {
        return 'תו בוטל מהפורטל';
    } else if (code === HistoryRecordType.ADD_FILE_TO_REQUEST_PORTAL) {
        return 'הוספת קובץ מהפורטל';
    } else if (code === HistoryRecordType.SENT_SMS_STATUS) {
        return 'נשלח מסרון';
    } else {
        return 'אין נתונים';
    }
}