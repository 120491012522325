import moment from "moment/moment";
import PaymentMethod from "../../models/PaymentMethod";
import OwnershipCarType from "../../types/OwnershipCarType";
import PermitType from "../../types/PermitType";

export const initPermitData = {
    projectId: -1,
    permitId: -1,
    vehicleId: -1,
    residentId: -1,
    ownershipCarType: OwnershipCarType.PRIVATE,
    carNumber: '',
    vColor: -1,
    vType: -1,
    permitType: PermitType.REGULAR,
    familyName: '',
    privateName: '',
    personId: '',
    phone: '',
    mobile: '',
    houseNum: '',
    appatmentNum: '',
    street: '',
    email: '',

    cardNumber: '',
    cardExpireDateMM: '',
    cardExpireDateYY: '',
    cardAddedNumber: '',
    cardHolderId: '',
  
    paymentMethod: PaymentMethod.NONE,
    asmachta: '',
    amount: 0,
    zone: -1,

    loginUser: -1,
    approveUserId: -1,
    issueDate: -1,
    startValidityDate: moment(new Date()).format("YYYY-MM-DD"),
    endValidityDate: moment(new Date()).format("YYYY-MM-DD"), 
    hardCopyPersonId: '0',
    hardCopyLicense: '0',
    hardCopyLeasing: '0',
    personIdfile: undefined,
    carLicensefile: undefined,
    workStatementfile: undefined,
    comments: '',
    userInsertName: '',
    createdBy: '',
    status: -1,
    statusTav: -1,
}