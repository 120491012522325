import moment from "moment";
import DateUtils from "../../Helpers/time/DateUtils";

const dateUtils = new DateUtils();

export const initSearchPermitReportFormData = {
    permitReportRows: [],
    totalCount: 0,
    totalPayment: 0,
    totalCitizenCount: 0,
    totalCashPayment: 0,
    totalCardPayment: 0,
    footer: [0, 'סיכום', 0, 0, `אשראי: 0`, `מזומן: 0`, `קבלת קהל: 0`, `אזרח: 0` ],
    form: {
        fromDate: dateUtils.getDateString(new Date()),
        toDate: dateUtils.getDateString(new Date()),
    }
}