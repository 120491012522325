import React from 'react';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import SelectStreet from '../../../../Components/Forms/select/SelectStreet';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import { ProjectDetailsContext, useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const ContectInfo = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { handleChange, errors, values, touched, handleBlur, setFieldValue, setFieldTouched } = useRequestManagmentPageContext();

    const hasAdditionalAddress = projectDetails.paymentConfig.getHasAdditionalAddress();

    return (
        <FormSection title={"פרטי הכתובת"}>
            <div className="row mb-2">
                <div className="col-md-6 col-lg-4">
                    <SelectStreet
                        name='street'
                        value={values.street}
                        errorMsg={(errors.street && touched.street) ? errors.street : undefined}
                        handleChange={(option) => setFieldValue('street', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('street', true);
                        }}
                    />
                </div>

                <div className="col-md-6 col-lg-4">
                    <InputGroup
                        name={'house'}
                        label={'מספר בית'}
                        value={values.house}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.house && touched.house) ? errors.house : undefined}
                    />
                </div>

                <div className="col-md-6 col-lg-4">
                    <InputGroup
                        name={'appartment'}
                        label={'מספר דירה'}
                        value={values.appartment}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.appartment && touched.appartment) ? errors.appartment : undefined}
                    />
                </div>

            </div>

            <div className="row mb-2">
                <div className="col-md-6 col-lg-4">
                    <InputGroup
                        name={'email'}
                        label={'אימייל'}
                        value={values.email}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.email && touched.email) ? errors.email : undefined}
                    />
                </div>

                <div className="col-md-6 col-lg-4">
                    <InputGroup
                        name={'mobile'}
                        label={'טלפון נייד'}
                        value={values.mobile}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.mobile && touched.mobile) ? errors.mobile : undefined}
                    />
                </div>
                <div className="col-md-6 col-lg-4">
                    <InputGroup
                        name={'phone'}
                        label={'טלפון נוסף'}
                        value={values.phone}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.phone && touched.phone) ? errors.phone : undefined}
                    />
                </div>
            </div>
            { hasAdditionalAddress ? (
                <div className="row mt-4">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <InputGroup
                                    label={'עיר למשלוח'}
                                    name={'mailCity'}
                                    value={values.mailCity}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errorMsg={(errors.mailCity && touched.mailCity) ? errors.mailCity : undefined}
                                />
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <InputGroup
                                    label={'רחוב למשלוח'}
                                    name={'mailStreet'}
                                    value={values.mailStreet}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errorMsg={(errors.mailStreet && touched.mailStreet) ? errors.mailStreet : undefined} />
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <InputGroup
                                    label={'מספר בית למשלוח'}
                                    name={'mailHouse'}
                                    value={values.mailHouse}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errorMsg={(errors.mailHouse && touched.mailHouse) ? errors.mailHouse : undefined} />
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-md-6 col-lg-6">
                                <InputGroup
                                    label={'מספר דירה למשלוח'}
                                    name={'mailAppartment'}
                                    value={values.mailAppartment}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errorMsg={(errors.mailAppartment && touched.mailAppartment) ? errors.mailAppartment : undefined} />
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <InputGroup
                                    name={'mailMikod'}
                                    label={'מיקוד'}
                                    value={values.mailMikod}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errorMsg={(errors.mailMikod && touched.mailMikod) ? errors.mailMikod : undefined} />
                            </div>
                        </div>
                    </div>

                </div>
            ) : ''}


        </FormSection>
    );

}
// <strong style={{ 'fontSize': '1.1rem' }}>פרטי כתובת למשלוח</strong>
export default ContectInfo;