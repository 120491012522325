class StreetsMap {

    constructor(jsonStreets = []){
        let arr = [];

        if(Array.isArray(jsonStreets)) arr = jsonStreets;
        
        this.streetsMap = arr;
    }

    getAllStreets(){

        return this.streetsMap;
    }

    getStreetByCode(code){
        
        let street = '';

        for(let i = 0; i < this.streetsMap.length; i++){

            if(this.streetsMap[i].streetId === code){

                street = this.streetsMap[i].streetName;
            }
        }

        if(street == null || street === ''){

            street = 'קוד לא קיים ברשימה';
        }

        return street; 
    }
}

export default StreetsMap;