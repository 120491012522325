class ColorsMap {

    constructor(jsonColor = []){
        let arr = [];

        if(Array.isArray(jsonColor)) arr = jsonColor;
        
        this.colorsMap = arr;
    }

    getAllColors(){

        return this.colorsMap;
    }

    getColorByCode(code){

        let color = '';

        for(let i = 0; i < this.colorsMap.length; i++){

            if(this.colorsMap[i].colorId === code){

                color = this.colorsMap[i].colorName;
            }
        }

        if(color == null || color === ''){

            color = 'קוד לא קיים ברשימה';
        }

        return color; 
    }
}

export default ColorsMap;