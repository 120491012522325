import React from 'react';
import ItemList from './ItemList';
import style from '../viewFilesPage.module.css';

const ListContainer = ({ files, handleSetFile }) => {
    return (
        <div className={style.listContainer}>
            {files.map((file, index) => {
                return (<ItemList 
                    key={`itemFile${index}`} file={file} handleSetFile={handleSetFile} index={index} />
            )})}
        </div>
    );
}

export default ListContainer;