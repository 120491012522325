import React from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectInput from './SelectInput';

const SelectZone = ({ name, label, errorMsg, isRequired, handleChange, handleBlur, value }) => {

    const { projectDetails } = useProjectDetailsContext();

    const options = projectDetails.zones.getAllZones().map((zone, index) => {
        return  {name: zone, value: zone};
    });

    const optionsZones = [{ name: 'כל האזורים', value: '' }, ...options];

    return (

        <SelectInput
            name={name}
            value={value}
            handleBlur={handleBlur}
            handleChange={handleChange}
            isRequired={isRequired}
            errorMsg={errorMsg}
            selects={optionsZones}
            label={label}
        />
    );
}

SelectZone.defaultProps = {
    value: '',
    isRequired: false,
    disabled: false,
    errorMsg: undefined,
    handleChange: () => { },
    handleBlur: () => { },
    name: '',
    label: 'איזור'
}

export default SelectZone;