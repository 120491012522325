import React from 'react';

const OutlineCheckBtn = ({ value, id, name, onClick, onBlur, label, isChecked, disabled }) => {
   
    return (
        <>
            <input
                disabled={disabled}
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                onClick={(e) => onClick(e)}
                onChange={(e) => { }}
                id={id}
                name={name}
                className="btn-check"
                type='checkbox'
            />
            <label
                className="btn btn-outline-success" 
                htmlFor={id}
            >
                {label}
            </label>
        </>
    );
}

OutlineCheckBtn.defaultProps = {

    label: '',
    value: '',
    disabled: false,
    isChecked: false,
    errorMsg: '',
    onBlur: () => { },
    onClick: () => { }
}

export default OutlineCheckBtn;