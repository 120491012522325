import React, { useContext } from 'react';
import { HistoryPermitList, ViewPermitBtn } from '../../../../Components/Buttons/buttuns.import';
import { RowCard, RowCardItem, PaymentRowItem } from '../../../../Components/UI/displayData/listOfRows/listOfRows.import';
import BtnOpenCPSModal from './changePermitStatusModal/BtnOpenCPSModal';
import { usePermitsListPageContext } from '../../../../contexts/permit/PermitsListPageContext';
import { convPermitRowRawDataFromServer } from '../../../../Helpers/conversion/permit/convPermitRowRawDataFromServer';
import { ProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import SpanPermitStatus from './SpanPermitStatus';

const PermitCard = ({ rawData, index, openHistory }) => {

    const { projectDetails } = useContext(ProjectDetailsContext);
    const { openChangeStatusModal } = usePermitsListPageContext();
    const data = convPermitRowRawDataFromServer(rawData);

    const paymentConfig = projectDetails.paymentConfig;
    const hasPayment = paymentConfig.getHasPayment();
    const permitStatusCode = data.statusTavCode;

    return (
        <RowCard
            index={index}
            content={(
                <>
                    <RowCardItem label='מספר תו' value={data.id} />
                    <RowCardItem label='תאריך התחלה' value={data.startDate} />
                    <RowCardItem label='תאריך סוף' value={data.endDate} />
                    <RowCardItem label='מספר רכב' value={data.vehicleNum} />
                    <RowCardItem label='מספר זהות' value={data.personId} />
                    <RowCardItem label='שם פרטי' value={data.fName} />
                    <RowCardItem label='שם משפחה' value={data.lName} />
                    <PaymentRowItem isShow={hasPayment} label='תשלום' paymentMethodCode={data.paymentMethodCode} moneyAmount={data.moneyAmount} />
                    <RowCardItem
                        label='סטטוס'
                        value={<SpanPermitStatus statusName={data.statusTav} permitStatusCode={permitStatusCode} />}
                    />
                </>
            )}
            footer={(
                <>
                    <BtnOpenCPSModal onClick={() => openChangeStatusModal(data)} />
                    <HistoryPermitList onClick={() => openHistory(false, data.id)} />
                    <ViewPermitBtn rowDbId={data.id} />
                </>
            )}
        />
    );
}

export default PermitCard;