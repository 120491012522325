import React, { useContext } from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestManagmentPageContext';
import { ProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { UpdateRquestStatusBtn, HistoryPermitList, ViewPermitRequestBtn, CreatePermitBtn } from '../../../../Components/Buttons/buttuns.import';
import OpenSmsModalBtn from '../../../../features/sendSms/components/OpenSmsModalBtn';
import RequestStatusType from '../../../../types/RequestStatusType';
import { convRequestRowRawDataFromServer } from '../../../../Helpers/conversion/request/convRequestRowRawDataFromServer';
import { RowCardItem, PaymentRowItem, RowCard } from '../../../../Components/UI/displayData/listOfRows/listOfRows.import';
import SpanRequestStatus from './SpanRequestStatus';
import { ComparingFileStatus } from '../../../../types/ComparingFileStatus';


const RequestCard = ({ index, request }) => {

    const { openSmsModal, openHistoryModal, handlerUpdateStatus, openModalPreApproveReq } = useRequestManagmentPageContext();
    const { projectDetails } = useContext(ProjectDetailsContext);
    const data = convRequestRowRawDataFromServer(request);

    const paymentConfig = projectDetails.paymentConfig;
    const hasSendSmsOption = paymentConfig.getSendSmsOption();
    const hasPayment = paymentConfig.getHasPayment();
    const statusReqCode = data.statusRequestCode;

    const hasApproveRequest = (data.comparingFtatus === ComparingFileStatus.COPARING_FILES_PASSED && statusReqCode === RequestStatusType.PENDING);
    const hasUpdateBtn = (statusReqCode === RequestStatusType.PENDING || statusReqCode === RequestStatusType.REJECTED);
    const hasSendSmsBtn = (hasSendSmsOption && data.sentToSmsDateNum <= 0);

    return (
        <RowCard
            index={index}
            content={(
                <>
                    <RowCardItem label='תאריך עידכון' value={data.updateDate} />
                    <RowCardItem label='מספר רכב' value={data.carNum} />
                    <RowCardItem label='תעודת זהות' value={data.personId} />
                    <RowCardItem label='שם פרטי' value={data.fName} />
                    <RowCardItem label='שם משפחה' value={data.lName} />
                    <RowCardItem label='נוצר על ידי' value={data.createdBy} />
                    {hasSendSmsOption && <RowCardItem label='תאריך שליחת הודעה' value={data.sentToSmsDate} />}
                    <PaymentRowItem isShow={hasPayment} label='תשלום' moneyAmount={data.moneyAmount} paymentMethodCode={data.paymentMethod} />
                    <RowCardItem
                        label='סטטוס בקשה'
                        value={
                            <SpanRequestStatus reqStatusCode={statusReqCode} statusName={data.status} comparingFStatus={data.comparingFtatus} />
                        }
                    />
                </>
            )}
            footer={(
                <>
                    {hasApproveRequest ? (
                        <CreatePermitBtn onClick={() => openModalPreApproveReq(request)} label={'אישור'} />
                    ) : ''}
                    {hasUpdateBtn ? (
                        <UpdateRquestStatusBtn
                            currentStatus={statusReqCode}
                            onClick={newStatus => handlerUpdateStatus(data.id, newStatus)}
                        />
                    ) : ''}
                    <HistoryPermitList onClick={() => openHistoryModal(true, data.reportNum)} />
                    <ViewPermitRequestBtn rowDbId={data.id} />
                </>
            )}
        />);
}

export default RequestCard;