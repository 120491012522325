import React from 'react';
import Button from '../Buttons/Button';
import { MdDownloadDone } from 'react-icons/md';

const ContinueModalBtn = ({ disabled, theme, label, onClick, style, title }) => {
    return (
        <Button
            onClick={onClick}
            theme={theme}
            endIcon={<MdDownloadDone />}
            style={style}
            text={label}
            disabled={disabled}
            title={title}
        />
    );
}

ContinueModalBtn.defaultProps = {
    disabled: false,
    theme: "success",
    label: "המשך",
    onClick: () => { },
    style: {},
    title: ''
};

export default ContinueModalBtn;