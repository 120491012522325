import React from 'react';
import CheckBtn from '../../../Components/Forms/check/CheckBtn';
import InputErrorMessage from '../../../Components/Forms/InputErrorMessage';
import style from '../sendEmail.module.css';

const CityEmailRow = ({values, touched, errors, handleClickHasCityEmail, handleChange, handleBlur}) => {
   
    return (
        <div className='row mb-4'>
            <div className='col-12'>
                <p>
                    <CheckBtn
                        inlineFilx={true}
                        name={'email.sendCopyToCity'}
                        isChecked={values.email.sendCopyToCity && values.email.sendCopyToCity === 1}
                        value={1}
                        label={`שליחת העתק מהמייל ישלח לכתובת:`}
                        id={'sendCopyToCity'}
                        onClick={handleClickHasCityEmail}
                        key={`sendCopyToCityKey`} />

                    <input
                        className={style.cityEmail}
                        value={values.email.cityEmail}
                        name='email.cityEmail'
                        id='idcityEmail'
                        placeholder='מייל לכיתוב...'
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    {errors?.email?.cityEmail && touched?.email?.cityEmail ? (
                        <InputErrorMessage key='errorChecksInputAndFreeTextId' message={errors.email.cityEmail} />
                    ) : ''}
                </p>
            </div>
        </div>
    )
}

export default CityEmailRow;