import * as yup from 'yup';
import { ONLY_DIGIT_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE } from '../Errors/errorMessages';
import { onlyNumbersRegExp } from '../Helpers/regExps';

export const loginSchema = yup.object().shape({
    userName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    password: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    projectId: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
});
