import React from 'react';
import { usePermitReport } from '../../../Hooks/permits/usePermitReport';
import { FORM_ACTION } from '../../../reducers/permits/permitReportReducer';
import SearchBtnLoading from '../../../Components/Buttons/SearchBtnLoading';
import DisplayPermitReport from './components/DisplayPermitReport';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import Alert from '../../../Components/Alerts/Alert';
import DateInput from '../../../Components/Forms/DateInput';
import FormBox from '../../../Components/Forms/FormBox';

const containerStyle = { display: 'flex', flexDirection: 'column', rowGap: '1rem' };

const PermitReportPage = () => {

    const { getPermitReportRows, dataState, dataDispatch, componentState } = usePermitReport();

    return (
        <div style={containerStyle}>
            <Alert isShow={componentState.hasError} message={componentState.errorMessage} />
            <PageTitle title={'דוח תווים'} />
            <FormBox>
                <div className="row">
                    <div className="col-9">
                        <div className="row">
                            <div className="col col-lg-6 col-xl-4">
                                <DateInput
                                    value={dataState.form.fromDate}
                                    label='תאריך התחלה'
                                    name="fromDate"
                                    handleChange={event => {
                                        const value = event.currentTarget.value;
                                        console.log(`value: ${value}`)
                                        dataDispatch({
                                            type: FORM_ACTION.FROM_DATE,
                                            date: value
                                        });
                                    }}
                                    isRequired={true}
                                />
                            </div>
                            <div className="col col-lg-6 col-xl-4">
                                <DateInput
                                    label='תאריך סוף'
                                    name="toDate"
                                    value={dataState.form.toDate}
                                    handleChange={event => {
                                        const value = event.currentTarget.value;
                                        console.log(`value: ${value}`)
                                        dataDispatch({
                                            type: FORM_ACTION.TO_DATE,
                                            date: value
                                        });
                                    }}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 align-self-end">
                        <div className='d-flex justify-content-end'>
                            <SearchBtnLoading onClick={onSubmitHandler} isLoading={componentState.isLoading} />
                        </div>
                    </div>
                </div>
            </FormBox>
            <DisplayPermitReport dataState={dataState} />
        </div>
    );


    function onSubmitHandler(event) {

        event.preventDefault();
        getPermitReportRows();
    }
}

export default PermitReportPage;