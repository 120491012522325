import React from 'react';

const DisplayHistoryInput = ({ value, inputName, labalName, colSize }) => {

    const classes = 'col ' + colSize;

    return (
        <div className={classes}>
            <label htmlFor={inputName} className="form-label">
                {labalName}
            </label>
            <input
                disabled={true}
                className="form-control form-control-sm"
                id={inputName}
                type="text"
                value={value}
            />
        </div>
    )
}

export default DisplayHistoryInput;