import React from 'react';
import ExportToCsvButton from '../../../../Components/Buttons/ExportToCsvButton';

const BtnExportCsvPermitReport = ({ rows, footer }) => {

    if (rows.length <= 0) {
        return '';
    }

    const createdBy = `${footer[6]} ${footer[7]}`;
    const paymentMethod = `${footer[4]} ${footer[5]}`;

    const columnsCsv = [
        { id: 'index', displayName: '#' },
        { id: 'loginUser', displayName: 'שם משתמש' },
        { id: 'count', displayName: 'מספר תווים' },
        { id: 'payment', displayName: 'סכום תשלום' },
        { id: 'paymentMethod', displayName: 'סוג תשלום' },
        { id: 'createdBy', displayName: 'נוצר על ידי' }
    ];

    const list = rows.map(permit => {
        return {
            index: permit.index,
            loginUser: permit.loginUser,
            count: permit.count,
            payment: permit.payment,
            paymentMethod: permit.paymentMethod,
            createdBy: permit.createdBy
        }
    });

    const listOfJsonForCsv = [
        ...list,
        {
            index: footer[1].replaceAll(',', ''),
            loginUser: "",
            count: footer[2],
            payment: footer[3].replaceAll(',', ''),
            paymentMethod: paymentMethod.replaceAll(',', ''),
            createdBy: createdBy.replaceAll(',', '')
        }
    ];

    return (
        <ExportToCsvButton data={listOfJsonForCsv} headers={columnsCsv} fileName='permit report' />
    );
}

export default BtnExportCsvPermitReport;