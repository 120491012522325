import React, { useContext } from 'react'
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import { ProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import PaymentConfig from '../../../../models/projectDetails/PaymentConfig';
import projectDetails from '../../../../models/projectDetails/ProjectDetails';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioBtn from '../../../../Components/Forms/radio/RadioBtn';
import PaymentMethod from '../../../../models/PaymentMethod';
import InputGroup from '../../../../Components/Forms/InputGroup';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PaymentDetails = () => {

    const { projectDetails } = useContext(ProjectDetailsContext);
    const { values } = useRequestManagmentPageContext();
    const paymentConfig = projectDetails.paymentConfig;
    const hasPayment = paymentConfig.getHasPayment();
    const paymentAmount = paymentConfig.getPaymentAmount();

    let hasPaidCash = false;
    let hasPaidCredit = false;
    let hasNotPaid = false;
    /*
        if (paymentMethod == PaymentMethod.CASH) {
            hasPaidCash = true;
        } else if (paymentMethod == PaymentMethod.CREDIT_CARD) {
            hasPaidCredit = true;
        } else {
            hasNotPaid = true;
        }
    */
    return (
        <FormSection title={"פרטי תשלום"}>
            <div className="row">
                <div className="col">
                    <RadioBtnsContainer label='אופן תשלום'>
                        <RadioBtn
                            label='לא שולם'
                            id={'notPaid'}
                            name={'notPaid'}
                            disabled={true}
                            isChecked={!values.hasPaidCash && !values.hasPaidCredit}
                            value='1'
                        />
                        <RadioBtn
                            label='שולם באשראי'
                            id={'hasPaidCredit'}
                            name={'hasPaidCredit'}
                            disabled={true}
                            isChecked={values.hasPaidCredit}
                            value='1'
                        />
                        <RadioBtn
                            label='שולם במזומן'
                            id={'hasPaidCash'}
                            name={'hasPaidCash'}
                            disabled={true}
                            isChecked={values.hasPaidCash}
                            value='1'
                        />
                    </RadioBtnsContainer>
                </div>
            </div>
            <div className="row">
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        name={'carNumber'}
                        label='מספר אסמכתא'
                        value={values.asmachta} />
                </div>
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        name={'carNumber'}
                        label='סכום'
                        value={values.moneyAmount} />

                </div>
            </div>
        </FormSection>
    );
}

export default PaymentDetails;