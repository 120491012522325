import React, { useContext } from 'react';
import { ProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const SelectCarColor = ({ name, handleBlur, handleChange, errorMsg, value }) => {
    
    const {projectDetails} = useContext(ProjectDetailsContext);

    let key = 1;
    let colors = projectDetails.colors.getAllColors().map(color => {
        return {label: decodeNCRStringIfNeeded(color.name), value: color.id}; 
    });

    colors = [{value: -1, label: 'צריך לבחור'}, ...colors];

    const valueStr = colors.findLast(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'צבע רכב'}
            value={valueStr}
            isRequired={true}
            selects={colors}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    );
}

export default SelectCarColor;