import React from 'react';

const PdfContainerViewer = (props) => {

    const path = props.path;
    const name = props.name
   
    return (
        <div>
            {<h3>{props.title}</h3>}
            <object data={path} type="application/pdf" width="100%" height="500px"></object>
        </div>
    );
}

// <a target='_blank' href={path}>{name}</a>

export default PdfContainerViewer;