import React from 'react';
import {TfiArchive} from 'react-icons/tfi';
import Button from '../Button';

const HistoryPermitList = ({ onClick, label, color, disabled, style, size }) => {
    return (
        <Button
        key={'HistoryPermitList'}
            disabled={disabled}
            theme={color}
            onClick={onClick}
            style={style}
            size={size}
            endIcon={<TfiArchive />}>
            {label}
        </Button>
    )
}

HistoryPermitList.defaultProps = {
    disabled: false,
    label: "הסטוריה",
    onClick: () => { },
    style: {},
    size: ''
};

export default HistoryPermitList