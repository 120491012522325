export const FORM_ACTION = {
    FROM_DATE: 'FROM_DATE',
    TO_DATE: 'TO_DATE',
    UPDATE_DATA: 'UPDATE_DATA',
}

export const permitReportReducer = (state, action) => {
    
    let newForm = {};

    switch (action.type) {
        case FORM_ACTION.FROM_DATE:
    
            newForm = {...state.form, fromDate:action.date}
            return {...state, form: newForm};
        case FORM_ACTION.TO_DATE:
    
            return {...state, form: {...state.form, toDate:action.date}};
        case FORM_ACTION.UPDATE_DATA:
            
            return {...state,
                permitReportRows: action.payload.permitReportRows, 
                totalCardPayment: action.payload.totalCardPayment,
                totalCashPayment: action.payload.totalCashPayment,
                totalCitizenCount: action.payload.totalCitizenCount,
                totalCount: action.payload.totalCount,
                totalPayment: action.payload.totalPayment,
                footer: action.payload.footer
            };        
        default:
            return state;
    }
} 