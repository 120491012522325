import React from 'react';
import PermitCard from './PermitCard';
import ListOfRows from '../../../../Components/UI/displayData/listOfRows/ListOfRows';
import { usePermitsListPageContext } from '../../../../contexts/permit/PermitsListPageContext';
import ExportToCsvBtn from './ExportToCsvBtn';

const DisplayManagementPermit = () => {

    const { getPermitsState, values, changePage, componentState, openHistoryModal, setFieldValue, getRowForCsv } = usePermitsListPageContext();

    const permits = getPermitsState.list;
    const count = getPermitsState.count;
    const offset = values.offset;
    const limit = values.limit;
    const startIndex = offset * limit + 1;
    const headers = [
        "#",
        "מספר תו",
        "תחילת תוקף",
        "סוף תוקף",
        "מספר רכב",
        "תעודת זהות",
        "שם משפחה",
        "שם פרטי",
        "מצב תו",
        "פעולות",
    ];

    const paigingData = {
        offset: offset,
        limit: limit,
        totleCount: count,
        changePage: changePage,
        isLoading: componentState.isLoading
    }

    const header = headers.map(title => <th>{title}</th>);

    const rows = permits.map((permit, index) => {

        return <PermitCard rawData={permit} key={index} index={startIndex + index} openHistory={openHistoryModal} />
    });

    return (

        <>
            <div className='row'>
                <div className='col'>
                    <ExportToCsvBtn permitsSize={permits.length} getRowForCsv={getRowForCsv} />
                </div>
            </div>
            
            <ListOfRows
                headers={header}
                rows={rows}
                name={'תווים'}
                paigingData={paigingData}
                handleChenge={event => setFieldValue('limit', event.currentTarget.value)} />
        </>
    );
}

export default DisplayManagementPermit;