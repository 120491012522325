import React from 'react'
import Style from './listOfRows.module.css';

const RowCard = ({ content, footer, index }) => {
    return (
        <div className={Style.rowCardDiv}>
            <div className={Style.index}>
                <span>{index}</span>
            </div>
            <div className={Style.body}>
                <div className={Style.rowsDiv}>{content}</div>
                <div className={Style.footer}>{footer}</div>
            </div>
        </div>
    );
}

export default RowCard;