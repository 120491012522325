import { SendMsgOptionType } from "../../types/SendMsgOptionType";

export const initMessageConfig = {
    'defaultOption': SendMsgOptionType.EMAIL,
    'email': {
        'isEnableOption': false,
        'cityEmail': '',
        'emailTitle': '',
        'textOptions': [],
        'sendCopyToCity': false,
    },
    'sms': {
        'isEnableOption': false,
        'textOptions': [],
    }
}