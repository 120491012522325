import React from 'react';
import { MdOutlineFilterListOff, MdOutlineFilterList } from "react-icons/md";
import Button from './Button';

const AdvancedSearchBtn = ({isAdvancedSearchOpen, handleOnClickAdvSearch}) => {

    const textBtn = isAdvancedSearchOpen ? 'סגור חיפוש מתקדם' : 'פתח חיפוש מתקדם';
    const icon = isAdvancedSearchOpen ? <MdOutlineFilterListOff /> : <MdOutlineFilterList />;

    return (
        <Button type='button' onClick={handleOnClickAdvSearch}  endIcon={icon} 
            text={textBtn} outline={true} theme='secondary' />
    );
}

export default AdvancedSearchBtn