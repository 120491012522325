import React from 'react'
import { RiArrowGoBackLine } from 'react-icons/ri';
import Button from '../Button';
import stylee from '../button.module.css';

const GoBackToListBtn = ({onClick}) => {
    const title = 'חזור לרשימה';

    return (
        <Button onClick={onClick} endIcon={<RiArrowGoBackLine />}>
            {title}
        </Button>
    );
}

export default GoBackToListBtn