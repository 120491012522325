import React from 'react';
import { TiDeleteOutline } from 'react-icons/ti';
import style from './forms.module.css';

const ClearButton = ({onClick}) => {


    return (
        <button type='button' onClick={onClick} style={{}}><TiDeleteOutline /></button>
    )
}

export default ClearButton