import React from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import style from '../history.module.css';
import GoBackToListBtn from '../../../Components/Buttons/History/GoBackToListBtn';
import { convertHistoryRecordRawData } from '../../../Helpers/conversion/convertHistoryRecordRawData';
import DisplayRequestHistory from './DisplayRequestHistory';
import DisplayPermitHistory from './DisplayPermitHistory';

const DisplayHistoryRecord = ({ historyRecDataRaw, GoBackFunc, isRequest }) => {

    const { projectDetails } = useProjectDetailsContext();
    const historyRecord = convertHistoryRecordRawData(historyRecDataRaw, projectDetails, isRequest);

    return (
        <div className='container'>

            <div className={`${style.displayRecordHeader}`}>
                <div></div>
                <div>
                    {historyRecord.statusName} ({historyRecord.dateTime})
                </div>
                <div className={`${style.goBackToListBtnBox}`}>
                    <GoBackToListBtn onClick={GoBackFunc} />
                </div>
            </div>

            <div className='row mt-1 p-1' style={{ fontSize: '1rem' }}>
                {isRequest ? <DisplayRequestHistory historyRecord={historyRecord} /> : <DisplayPermitHistory historyRecord={historyRecord} />}
            </div>
        </div>
    );
}

export default DisplayHistoryRecord;