import React from 'react';
import PermitStatusType from '../../../types/PermitStatusType';
import SelectInput from './SelectInput';

const SelectPermitStatus = ({ errorMsg, isRequired, handleChange, handleBlur, value, name }) => {

  const selects = [
    { name: 'פעיל', value: PermitStatusType.ACTIVE },
    { name: 'לא פעיל', value: PermitStatusType.NOT_ACTIVE },
    { name: 'בוטל', value: PermitStatusType.CANCELED },
    { name: 'מושהה', value: PermitStatusType.SUSPENDED }
  ];

  return (
    <SelectInput
      handleChange={handleChange}
      name={name}
      label={'סטטוס תו'} 
      value={value}
      selects={selects}
      isRequired={isRequired}
      handleBlur={handleBlur}
      errorMsg={errorMsg}
    />
  );
}

export default SelectPermitStatus;