class PaymentConfig {

    constructor(
        hasAdditionalAddress = false,
        colorAndType = true,
        hasPayment = false,
        paymentAmount = 0,
        gilHaZahavOption = false,
        gilHaZahavPayment = 0,
        gilHaZahavValidityPeriod = 12,
        gilHaZahavAge = 67,
        studentOption = false,
        studentPayment = 0,
        studentValidityPeriod = 12,
        sendSmsOption = false,
        smsMessage = '',
        validityPeriod = 12,
        municipalEmployeeOption = false,
        municipalEmployeePayment = 0,
        municipalEmployeeValidityPeriod = 12) {

        this.hasAdditionalAddress = hasAdditionalAddress;
        this.colorAndType = colorAndType;
        this.hasPayment = hasPayment;
        this.paymentAmount = paymentAmount;
        this.gilHaZahavOption = gilHaZahavOption;
        this.gilHaZahavPayment = gilHaZahavPayment;
        this.gilHaZahavValidityPeriod = gilHaZahavValidityPeriod;
        this.gilHaZahavAge = gilHaZahavAge;
        this.studentOption = studentOption;
        this.studentPayment = studentPayment;
        this.studentValidityPeriod = studentValidityPeriod;
        this.sendSmsOption = sendSmsOption;
        this.smsMessage = smsMessage;
        this.validityPeriod = validityPeriod;
        this.municipalEmployeeOption = municipalEmployeeOption;
        this.municipalEmployeePayment = municipalEmployeePayment;
        this.municipalEmployeeValidityPeriod = municipalEmployeeValidityPeriod;
    }

    getColorAndType() {

        return this.colorAndType;
    }

    getHasAdditionalAddress() {

        return this.hasAdditionalAddress;
    }

    getHasPayment() {

        return this.hasPayment;
    }

    getPaymentAmount() {

        return this.paymentAmount;
    }

    getGilHaZahavOption() {

        return this.gilHaZahavOption;
    }

    getGilHaZahavAge() {

        return this.gilHaZahavAge;
    }

    getStudentOption() {

        return this.studentOption;
    }

    getSendSmsOption() {

        return this.sendSmsOption;
    }

    getSmsMessage() {

        return this.getSmsMessage;
    }

    getValidityPeriod() {

        return this.validityPeriod;
    }
    
}

export default PaymentConfig;