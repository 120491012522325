import React from 'react';
import RowCardItem from './RowCardItem';
import SpanPaymentMethod from './SpanPaymentMethod';
import SpanPaymentAmount from './SpanPaymentAmount';

const PaymentRowItem = ({ isShow, label, paymentMethodCode, moneyAmount }) => {

    if (!isShow) {
        return '';
    }

    return (
        <RowCardItem
            label={label}
            value={(
                <>
                    <SpanPaymentMethod paymentMethodCode={paymentMethodCode} />
                    <SpanPaymentAmount moneyAmount={moneyAmount} paymentMethodCode={paymentMethodCode} />
                </>
            )}
        />
    );
}

export default PaymentRowItem;