import React from 'react';
import { Link } from 'react-router-dom';

const Li = ({link, setActiveNav, nameTag, activeNavId, currentActiveId}) => {

    let classes = 'nav-link';

    if(currentActiveId === activeNavId){
        classes = 'nav-link active';
    }

    return (
        <li className="nav-item">
            <Link className={classes} aria-current="page" to={link} onClick={handleOnClick}>{nameTag}</Link>
        </li>
    );

    function handleOnClick() {
        setActiveNav(activeNavId);
    }
}

export default Li;