import { createContext, useContext, useEffect, useReducer } from "react";
import { useFormik } from "formik";
import useViewExpiredPermits from "../../Hooks/permits/useExpiredPermits";
import ExpiredPermitsPage from "../../Pages/Permits/expiredPermitsPage/ExpiredPermitsPage";
import { initExpiredPermitData, initExpiredPermitFormData } from "../../data/default/expiredPermitForm.data";
import { expiredPermitSchema } from "../../validation/permit/expiredPermitValidation";
import { EXPIRED_PERMITS_ACTION, expiredPermitsReducer } from "../../reducers/permits/expiredPermitsReducer";
import { convExpiredPermitRowData } from "../../Helpers/conversion/permit/convExpiredPermitRowData";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import { useComponentState } from "../../Hooks/useComponentState";

export const ExpiredPermitsPageContext = createContext();

const ExpiredPermitsPageProvider = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { getExpiredPermits } = useViewExpiredPermits();
    const [expiredPermitsState, expiredPermitsDispatch] = useReducer(expiredPermitsReducer, initExpiredPermitData);

    const { errors, values, touched, handleSubmit, handleBlur, handleChange, setFieldValue } = useFormik({
        initialValues: {...initExpiredPermitFormData},
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: values => {
            handleGetExpiredPermits(values);
        }, 
        validationSchema: expiredPermitSchema,
    });

    const typesMap = projectDetails.types.getAllMakers();


    useEffect(() => {
        handleGetExpiredPermits(values);
      }, []);

    return (
        <ExpiredPermitsPageContext.Provider value={{ 
            errors, values, touched, handleSubmit, handleBlur, handleChange, setFieldValue,
            componentState, handleGetExpiredPermits, expiredPermitsState }}>
            <ExpiredPermitsPage />
        </ExpiredPermitsPageContext.Provider>
    );

    async function handleGetExpiredPermits(values) {

        setIsLoading();

        const response = await getExpiredPermits(values);

        if(!response.isSuccess) {

            setDangerAlert(response.message);
            return;
        }

        const permits = response.data.permits.map(expiredPermit => convExpiredPermitRowData(expiredPermit, typesMap));
        const count = response.data.count;

        expiredPermitsDispatch({
            type:EXPIRED_PERMITS_ACTION.UPDATE_LIST,
            payload:{ count: count, list: permits }
        });
        
        setIsNotLoading();
    }
}

export default ExpiredPermitsPageProvider;

export const useExpiredPermitsPageContext = () => useContext(ExpiredPermitsPageContext);