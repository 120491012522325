import React from 'react';
import { AiOutlineCloudServer } from "react-icons/ai";
import ActionBoxCard from '../../../../Components/UI/Card/ActionBoxCard'
import GoBackBtn from '../../../../Components/Buttons/GoBackBtn'
import CreatePermitBtn from '../../../../Components/Buttons/CreatePermitBtn'
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import Button from '../../../../Components/Buttons/Button';

const PermitDetailsButtonsBox = () => {

    const { componentState, handleAddPermit, handleGetInitPermitData } = useAddPermitPageContext();
  
    let isDisabled = false;

    if (componentState.isLoading) isDisabled = true;

    return (
        <ActionBoxCard>
            <div style={{ display: 'flex', columnGap: '1rem', rowGap: '0.5rem' }}>
                <Button onClick={handleGetInitPermitData} text='בדיקת נתונים עם השרת' endIcon={<AiOutlineCloudServer />} />
                <CreatePermitBtn disabled={isDisabled} onClick={handleAddPermit} />
            </div>
            <GoBackBtn />
        </ActionBoxCard>
    );
}

export default PermitDetailsButtonsBox;