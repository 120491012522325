import React, { useContext } from 'react';
import PaymentForm from './PaymentForm';
import FilesCard from './FilesCard';
import { ProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import PermitContectDetails from './PermitContectDetails';
import PermitDatesDetails from './PermitDatesDetails';
import PermitPersonDetails from './PermitPersonDetails';
import PermitGeneralDetails from './PermitGeneralDetails';
import PermitCarDetails from './PermitCarDetails';


const FormAddPermit = () => {

    const { projectDetails } = useContext(ProjectDetailsContext);
    const paymentConfig = projectDetails.paymentConfig;

    return (
        <form>
            <div className='row'>
                <div className='col-12 col-lg-6 mb-3'>
                    <PermitPersonDetails />
                </div>

                <div className='col-12 col-lg-6 mb-3'>
                    <PermitDatesDetails />
                </div>

                <div className='col-12 col-lg-7 mb-3'>
                    <PermitContectDetails />
                </div>

                <div className='col-12 col-lg-5 mb-3'>
                    <PermitGeneralDetails />
                </div>

                <div className='col-12 mb-3'>
                    <PermitCarDetails />
                </div>

                <div className='col-12 mb-3'>
                    <FilesCard />
                </div>

                {paymentConfig.getHasPayment() ? (
                    <div className='col-12 mb-3'>
                        <PaymentForm /> 
                    </div>
                ) : ''}
            </div>
        </form>
    );
}

export default FormAddPermit;