
const PermitType = {

    REGULAR: 0,
    GOLDEN: 1,
    STUDENT: 2,
    HANDICAPPED: 3,
    MUNICIPAL_EMPLOYEE: 4,
    ALL: -1
}

export default PermitType;