import React, { useState, createContext, useContext, useReducer, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import { FORM_ACTION, dataReducer, useGetPermitRequsets } from "../../Hooks/requests/useGetPermitRequests";
import RequestManagementPage from "../../Pages/request/requestManagmentPage/RequestManagementPage";
import {ResponseStatus} from "../../types/types.import";
import { useAuthContextContext } from "../AuthContext";
import { useAppInfoContext } from "../AppInfoContext";
import usePublicHistoryModal from "../../Hooks/history/usePublicHistoryModal";
import { useComponentState } from "../../Hooks/useComponentState";

export const RequestManagmentPageContext = createContext();

const RequestManagmentPageProvider = () => {
    
    const initFormName = 'requestPermit';
    const { logout } = useAuthContextContext()
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { updateInitForm, getCurrentInitForm } = useAppInfoContext();
    const { historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal } = usePublicHistoryModal();
    const [confirmModalIsShown, setConfirmModalIsShown] = useState({ isOpen: false, request: null });
    const [sendSmsModalData, setSendSmsModalData] = useState({ isOpen: false, request: null, index: -1 });
    const { getPermitsRequest, updateStatus, approveRequestByReqId } = useGetPermitRequsets();
    const [dataState, dataDispatch] = useReducer(dataReducer, { data: [], count: 0 });

    const { values, handleSubmit, handleBlur, setFieldValue } = useFormik({ 
        initialValues: {...getCurrentInitForm(initFormName, {})},
        onSubmit: values => handlerGetPermitsRequest(values)
    });

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        handlerGetInitRequests(values, signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    return (
        <RequestManagmentPageContext.Provider value={{
            values, handleSubmit, handleBlur,
            componentState,
            sendSmsModalData, 
            handlerGetPermitsRequest, handlerUpdateStatus,
            dataState, dataDispatch, 
            openSmsModal, closeSmsModal,
            updateRequestSms,
            historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal,
            changePage, handleChange, handleChengeAndOffset, openModalPreApproveReq, closeModalPreApproveReq, approveReq,
            confirmModalIsShown
        }}>
            <RequestManagementPage />
        </RequestManagmentPageContext.Provider>
    );

    function openSmsModal(request, index) {

        setSendSmsModalData({
            isOpen: true, request: request, index: index
        })
    }

    function closeSmsModal() {

        setSendSmsModalData({
            isOpen: false, request: null, index: -1
        })
    }

    function updateRequestSms(index, date){
        
        const newRequest = { ...dataState.data[index] };
        newRequest['sendToSms'] = Number(date);
        
        updateRequestInGUI(index, newRequest);
    }

    function handleChengeAndOffset(event) {

        setFieldValue('offset', 0);
        handleChange(event);
    }

    function changePage(offset) {
 
        setFieldValue('offset', offset);
        handlerGetPermitsRequest(values, offset);
    }

    async function handlerGetInitRequests(values, signal){
        
        const sentValues = {...values};
        setIsLoading();
        
        updateInitForm(initFormName, sentValues);

        const response = await getPermitsRequest(sentValues, signal);

        if(response.status === ResponseStatus.FAILURE) {
    
            if(response.message === 'צריך להתחבר מחדש 401') {
                
                logout();
            }
            
            setDangerAlert(response.message);
            return;
        }

        dataDispatch({
            type: FORM_ACTION.UPDATE_LIST,
            payload: {
                count: response.data.count,
                data: response.data.requets
            }
        });

        setIsNotLoading();
    } 

    async function handlerGetPermitsRequest(values, offset = -1){
        
        const sentValues = {...values};
        setIsLoading();

        if(offset > -1) {
            sentValues.offset = offset;
        }
        
        updateInitForm(initFormName, sentValues);

        const response = await getPermitsRequest(sentValues);

        if(response.status === ResponseStatus.FAILURE) {

            if(response.message === 'צריך להתחבר מחדש 401') {

                logout();
            }

            setDangerAlert(response.message);
            return;
        }

        dataDispatch({
            type: FORM_ACTION.UPDATE_LIST,
            payload: {
                count: response.data.count,
                data: response.data.requets
            }
        });

        setIsNotLoading();
    } 
    
    async function handlerUpdateStatus(requestId, newStatus) {

        setIsLoading();

        const response = await updateStatus(requestId, newStatus);

        if(response.status === ResponseStatus.FAILURE){

            if(response.message === 'צריך להתחבר מחדש 401'){
                logout();
            }

            setDangerAlert(response.message);
            return;
        }
 
        updateRequestInGUI(requestId, newStatus);
        setIsNotLoading();
    }

    function updateRequestInGUI(index, newRequest) {
        dataDispatch({
            type: FORM_ACTION.SWITCH_STATUS_REQUEST,
            payload: {rowId: index, status: newRequest}
        });
    }

    function openModalPreApproveReq(fullData) {

        setConfirmModalIsShown({ isOpen: true, request: fullData.request });
    }

    function closeModalPreApproveReq() {
        setConfirmModalIsShown({ isOpen: false, request: null });
    }

    async function approveReq() {

        console.log(confirmModalIsShown.request)
        setIsLoading();
        closeModalPreApproveReq();
        
        const response = await approveRequestByReqId(confirmModalIsShown.request.id);
        setIsNotLoading();

        handlerGetPermitsRequest(values, values.offset);
    }
}

export default RequestManagmentPageProvider;

export const useRequestManagmentPageContext = () => useContext(RequestManagmentPageContext);