import PermitType from "../types/PermitType";

/** Calculate the end date of the permit base on it permit type and privuse permit.
 * @Method POST
 * @Body
 * {
 *   permitType - {@link PermitType},
 *   carNum - string,
 *   personId - string,
 * }
 */
export const CALCULATE_END_DATE = "/request/calculate-end-date";