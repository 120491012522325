import React from 'react'
import SelectInput from '../SelectInput';

const SelectYearPayment = ({ name, errorMsg, isRequired, handleChange, handleBlur, value }) => {
  const yearSelect = [
    { key: 31, value: '-1', name: 'צריך לבחור' },
    { key: 24, value: '24', name: '2024' },
    { key: 25, value: '25', name: '2025' },
    { key: 26, value: '26', name: '2026' },
    { key: 27, value: '27', name: '2027' },
    { key: 28, value: '28', name: '2028' },
    { key: 29, value: '29', name: '2029' },
    { key: 30, value: '30', name: '2030' },
  ];
 
  return (
    <SelectInput
      errorMsg={errorMsg}
      name={name}
      label={'שנה'}
      value={value}
      isRequired={true}
      selects={yearSelect}
      handleBlur={handleBlur}
      handleChange={handleChange} />
  )
}

export default SelectYearPayment