import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuthContextContext } from './contexts/AuthContext';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Page from "./Components/UI/page/Page";
import NotFound from "./Pages/NotFound";
import ViewFilesPage from "./Pages/ViewFilesPage/ViewFilesPage";
import PermitReportPage from './Pages/Permits/permitReport/PermitReportPage';
import RequestDetailsPageProvider from "./contexts/request/RequestDetailsPageContext";
import RequestManagmentPageProvider from "./contexts/request/RequestManagmentPageContext";
import PermitDetailsPageProvider from "./contexts/permit/PermitDetailsPageContext";
import PermitsListPageProvider from "./contexts/permit/PermitsListPageContext";
import ExpiredPermitsPageProvider from "./contexts/permit/ExpiredPermitsPageContext";
import LoginPage from "./Pages/LoginPage/LoginPage";
import AddPermitPageProvider from "./contexts/permit/AddPermitPageContext";
import PaymentsReportPageProvider from "./contexts/reports/PaymentsReportPageContext";

function App() {

  const { authState } = useAuthContextContext();
  const { loggedIn } = authState;
  let page = '';

  if (loggedIn) {

    page = (
      <React.Fragment>
        <Page>
          <Routes>
            <Route path="/" element={<RequestManagmentPageProvider />} />
            <Route path="/permit-request/requestManagementPage" element={<RequestManagmentPageProvider />} />
            <Route path="/permits/permitManagementPage" element={<PermitsListPageProvider />} />
            <Route path="/permit-request/request-details/:requestId" element={<RequestDetailsPageProvider />} />
            <Route path="/permits/addPermit/:permitId" element={<AddPermitPageProvider />} />
            <Route path="/permits/permit-details/:permitId" element={<PermitDetailsPageProvider />} />
            <Route path="/permits/report/expired-permits" element={<ExpiredPermitsPageProvider />} />
            <Route path="/permits/report/permit-report" element={<PermitReportPage />} />
            <Route path="/reports/payments-report" element={<PaymentsReportPageProvider />} />
            <Route path="/files/:personId" element={<ViewFilesPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Page>
      </React.Fragment>
    );
  } else {
    page = <LoginPage />;
  }


  return (
    <div className="App">
      {loggedIn ? <Header /> : ''}
      {page}
      <Footer />
    </div>
  );
}

export default App;