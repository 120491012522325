export const AUTH_ACTION = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
    SET_TOKENS: 'SET_TOKENS',
}

export const authReducer = (state, action) => {

    const payload = action.payload;
    const type = action.type;

    switch (type) {
        case AUTH_ACTION.LOGIN:

            return {
                ...state,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
                loggedIn: true,
            };
        case AUTH_ACTION.LOGOUT:

            return {
                ...state,
                accessToken: '-1',
                refreshToken: '-1',
                loggedIn: false,
            };
        case AUTH_ACTION.SET_ACCESS_TOKEN:

            return { ...state, accessToken: payload.accessToken };
        case AUTH_ACTION.SET_TOKENS:

            return {
                ...state,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
            };
        default:
            return state;
    }
}