import React from 'react'
import { ComparingFileStatus } from '../../../../types/ComparingFileStatus';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { LuFileWarning } from 'react-icons/lu';
import { GrStatusGood } from 'react-icons/gr';

const style = { 
    padding: '0.2rem', 
    width: '1.85rem',
    display: 'flex', 
    fontSize: '1rem', 
    justifyContent: 'center', 
    alignItems: 'center',
    cursor: 'help',
}

const ComparingFilesStatus = ({ comparingFStatus }) => {

    let comparingFStatusName = 'לא בוצע בדיקה';
    let statusIcon = <AiOutlineInfoCircle />;
    let classesSpan = ' bg-secondary';
    let title = 'עדיין לא בוצע בדיקה אוטומטית של המערכת.';

    if (comparingFStatus === ComparingFileStatus.COPARING_FILES_NOT_PASSED) {

        comparingFStatusName = 'דרוש בדיקה ידנית';
        classesSpan = ' bg-danger';
        statusIcon = <LuFileWarning />
        title = 'הבקשה לא עברה בהצלחה את הבדיקה האוטומטית, דרושה בדיקה ידנית!.';
    } else if (comparingFStatus === ComparingFileStatus.COPARING_FILES_PASSED) {

        comparingFStatusName = 'עבר בדיקה אוטומטית';
        classesSpan = ' bg-success';
        statusIcon = <GrStatusGood />
        title = 'הבקשה עברה בהצלחה את הבדיקה האוטומטית.';
    }

    return (
        <span title={title} className={'badge' + classesSpan} style={style}>{statusIcon}</span>
    );
}

export default ComparingFilesStatus