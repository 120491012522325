import React from 'react'
import { BsImage} from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Button from './Button';

const ViewFilesBtn = ({ personId, disabled, label }) => {
    const navigate = useNavigate();
    const url = `/files/${personId}`;

    return (
        <>
        <Button key={'ViewFilesBtn'} disabled={disabled} onClick={onClick} theme={'primary'} endIcon={<BsImage />}>
            {label}
        </Button>
        </>
    );

    function onClick(){
        
        navigate(url);
    }
}

ViewFilesBtn.defaultProps = {
    disabled: false,
    label: "צפייה בקבצים",
    personId: -1
};

export default ViewFilesBtn