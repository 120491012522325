import React from 'react'

const SmallLoadingDiv = () => {

    return (
        <div className="spinner-border text-primary" style={{textAlign: 'center'}} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );
}

export default SmallLoadingDiv