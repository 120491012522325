import React from 'react';
import style from './box.module.css';

const Flexbox = ({ children, justifyContent, alignItems, flexDirection, 
    marginLeft, marginRight, marginBottom, marginTop }) => {

    let classes = style.box;

    const Style = {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        marginLeft: '0', 
        marginRight: '0', 
        marginBottom: '0', 
        marginTop: '0'
    }

    //  flex-direction: row | row-reverse | column | column-reverse;
    if (flexDirection === 'row' || flexDirection === 'row-reverse' ||
        flexDirection === 'column' || flexDirection === 'column-reverse') {
        Style.flexDirection = flexDirection;
    }

    //  justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly ;
    if (justifyContent === 'flex-start' || justifyContent === 'flex-end' || justifyContent === 'center' ||
        justifyContent === 'space-between' || justifyContent === 'space-around' || justifyContent === 'space-evenly') {
        Style.justifyContent = justifyContent;
    }

    //  align-items: stretch | flex-start | flex-end | center | baseline
    if (alignItems === 'stretch' || alignItems === 'flex-end' || alignItems === 'center' ||
        alignItems === 'flex-start' || alignItems === 'baseline') {
        Style.alignItems = alignItems;
    }

    if(marginRight) {
        Style.marginRight = marginRight;
    }

    if(marginLeft) {
        Style.marginLeft = marginLeft;
    }

    return (
        <div className={classes} style={Style}>
            {children}
        </div>
    );
}

Flexbox.defaultProps = {
    justifyContent: '',
    alignItems: '',
    flexDirection: '',
    marginRight: '',
    marginLeft: '',
}

export default Flexbox;