import React from 'react';
import Alert from '../../../../../Components/Alerts/Alert';
import { useRequestManagmentPageContext } from '../../../../../contexts/request/RequestDetailsPageContext';

const AlertExistingPermit = () => {

    const { greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit, values } = useRequestManagmentPageContext();

    const isApprovedRequest = values.approvalDate ? true : false;

    if (isApprovedRequest || !greatestEndDateOfActivePermit)
        return '';

    return (
        <div className='row mt-1'>
            <div className='col-12'>
                <Alert
                    subject={`קיים תו לרכב ומספר תעודת זהות אשר עדיין בתוקף ונגמר בתאריך ${greatestEndDateOfActivePermit}`}
                    isShow={true}
                    message={`קיים תו לרכב ומספר תעודת זהות אשר עדיין בתוקף ונגמר בתאריך ${greatestEndDateOfActivePermit}, לכן תאריך סיום התו הנוכחי מתארך באתאם.`}
                    type={'warning'}
                    onClose={() => setGreatestEndDateOfActivePermit('')}
                />
            </div>
        </div>
    );
}

export default AlertExistingPermit;