import * as yup from 'yup';
import { NOT_VALID_EMAIL_ADDRESS_MESSAGE, REQUIRED_FIELD_MESSAGE } from '../Errors/errorMessages';

const REQUIRED_ONE_OF_TWO = 'חובה להוסיף מלל חופשי או לסמן את אחד מהתגובות המובנות.';

const yupObjectEmailRequired = {
    emailSubject: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    citizenEmail: yup.string()
        .required(NOT_VALID_EMAIL_ADDRESS_MESSAGE)
        .email(NOT_VALID_EMAIL_ADDRESS_MESSAGE),
    cityEmail: yup.string()
        .when(["sendCopyToCity"], ([sendCopyToCity], schema) => {

            if (Number(sendCopyToCity) === 1) {
                return schema
                    .required(NOT_VALID_EMAIL_ADDRESS_MESSAGE)
                    .email(NOT_VALID_EMAIL_ADDRESS_MESSAGE);
            }

            return schema.notRequired();
        }),
    chosenTextOptionCodes: yup.array()
        .notRequired(),
    freeText: yup.string()
        .notRequired()
        .when(["chosenTextOptionCodes"], ([chosenTextOptionCodes], schema) => {

            if (!Array.isArray(chosenTextOptionCodes) || chosenTextOptionCodes.length === 0) {
                return schema.required(REQUIRED_ONE_OF_TWO);
            }

            return schema.notRequired();
        }),
}

const yupObjectSmsRequired = {
    chosenTextOptionCodes: yup.array()
        .notRequired(),
    freeText: yup.string()
        .notRequired()
        .when(["chosenTextOptionCodes"], ([chosenTextOptionCodes], schema) => {

            if (!Array.isArray(chosenTextOptionCodes) || chosenTextOptionCodes.length === 0) {
                return schema.required(REQUIRED_ONE_OF_TWO);
            }

            return schema.notRequired();

        }),
}

const yupObjectEmailNotRequired = {
    chosenTextOptionCodes: yup.array()
        .notRequired(),
    freeText: yup.string()
        .notRequired(),
    emailSubject: yup.string()
        .notRequired(),
    citizenEmail: yup.string()
        .notRequired(),
    cityEmail: yup.string()
        .notRequired(),
}

const yupObjectSmsNotRequired = {
    chosenTextOptionCodes: yup.array()
        .notRequired(),
    freeText: yup.string()
        .notRequired()
}


export const sendMessageVatidationSchema = yup.object().shape({
    sendViaEmail: yup.string()
        .required(),
    email: yup
        .object()
        .shape(yupObjectEmailRequired)
        .when(["sendViaEmail"], ([sendViaEmail], schema) => {
            return (sendViaEmail === "EMAIL") ? schema : schema.shape(yupObjectEmailNotRequired)
        }),
    sms: yup
        .object()
        .shape(yupObjectSmsRequired)
        .when(["sendViaEmail"], ([sendViaEmail], schema) => {
            return (sendViaEmail === "SMS") ? schema : schema.shape(yupObjectSmsNotRequired);
        }),
});