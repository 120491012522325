import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import style from './paging.module.css';

const NextPageBtn = ({ disabled, onClick }) => {
    return (
        <button
            className={style.pagingBtn}
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <MdKeyboardArrowLeft />
        </button>
    );
}

export default NextPageBtn