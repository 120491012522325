import React from 'react';
import Style from './Page.module.css';

const Page = (props) => {

    return (
        <main className={Style.page}>
            {props.children}
        </main>
    );
}

export default Page;