class MakersMap {

    constructor(jsonMaker = []){
        let arr = [];

        if(Array.isArray(jsonMaker)) arr = jsonMaker;
        
        this.makersMap = arr;
    }

    getAllMakers(){

        return this.makersMap;
    }

    getMakerByCode(code){

        let maker = '';
        
        for(let i = 0; i < this.makersMap.length; i++){

            if(this.makersMap[i].vehicleId === code){

                maker = this.makersMap[i].vehicleName;
            }
        }

        if(maker == null || maker === ''){

            maker = 'קוד לא קיים ברשימה';
        }

        return maker; 
    }
}

export default MakersMap;