import { ServerExceptionTypes } from "../../types/ServerExceptionTypes";

/**
 * 
 * @param {object} error - Axios error object, need to contain response property, the response need to contain status(number) and data.
 * Data - timestamp(string), message(string), errorCode(number), errorName(string).
 * @param {object} messageDescrption - An object containing an errors messages to overriding the default.
 * @returns {string} message to display in the GUI.
 */
export default function translateErrorCode(error, messageDescrption = { generalError: 'בעיה כללית בשרת' }) {
    let message = messageDescrption.generalError ? messageDescrption.generalError : 'בעיה כללית בשרת';
    const data = error.response.data;

    if (data.errorCode === ServerExceptionTypes.LOGIN_FAILD) {

        message = messageDescrption.LOGIN_FAILD ? messageDescrption.LOGIN_FAILD : 'כניסה למערכת נכשלה - אחד או יותר מהנתונים אינו נכון';
    } else if (data.errorCode === ServerExceptionTypes.BAD_REQUEST) {

        message = messageDescrption.BAD_REQUEST ? messageDescrption.BAD_REQUEST : 'לא נשלחו כל הנתונים לשרת';
    } else if (data.errorCode === ServerExceptionTypes.VALID_PERMIT_ALREADY_EXIST) {

        message = messageDescrption.PENDING_REQUEST_ALREADY_EXIST ? messageDescrption.PENDING_REQUEST_ALREADY_EXIST : 'קיים במערכת תו עם מספר רכב ותעודת זהות זהים';
    } else if (data.errorCode === ServerExceptionTypes.PENDING_REQUEST_ALREADY_EXIST) {

        message = messageDescrption.PENDING_REQUEST_ALREADY_EXIST ? messageDescrption.PENDING_REQUEST_ALREADY_EXIST : 'קיימת בקשה שממתינה לאישור עם מספר רכב ותעודת זהות זהים';
    } else if (data.errorCode === ServerExceptionTypes.OTP_FAILD) {

        message = messageDescrption.OTP_FAILD ? messageDescrption.OTP_FAILD : 'קוד אימות נכשל, צריך להתחבר מחדש';
    } else if (data.errorCode === ServerExceptionTypes.PERMIT_NOT_FOUND) {

        message = messageDescrption.PERMIT_NOT_FOUND ? messageDescrption.PERMIT_NOT_FOUND : 'לא נמצא תו עם הפרטים שהיזנת';
    }
    else if (data.errorCode === ServerExceptionTypes.REQUEST_NOT_FOUND) {

        message = messageDescrption.REQUEST_NOT_FOUND ? messageDescrption.REQUEST_NOT_FOUND : 'בקשה לא נמצאה עם הפרטים שהזנת';
    } else if (data.errorCode === ServerExceptionTypes.EMAIL_NOT_VALID) {

        message = messageDescrption.EMAIL_NOT_VALID ? messageDescrption.EMAIL_NOT_VALID : 'האימייל במערכת לא חוקי או לא קיים';
    } else if (data.errorCode === ServerExceptionTypes.MOBILE_NOT_VALID) {

        message = messageDescrption.MOBILE_NOT_VALID ? messageDescrption.MOBILE_NOT_VALID : 'הטלפון במערכת לא חוקי או לא קיים';
    } else if (data.errorCode === ServerExceptionTypes.PAYMENT_TRANZILA) {

        message = messageDescrption.PAYMENT_TRANZILA ? messageDescrption.PAYMENT_TRANZILA : (
            <>
                בעיה בתהליך סליקת כרטיס האשראי. אנא בדוק תקינות כרטיס האשראי.
                <br />
                במידת הצורך, אנא פנה לחברת האשראי לצורך בדיקה
            </>);
    } else if (data.errorCode === ServerExceptionTypes.PAYMENT_CARD_ALREADY_IN_HASHMAP) {

        message = messageDescrption.PAYMENT_CARD_ALREADY_IN_HASHMAP ? messageDescrption.PAYMENT_CARD_ALREADY_IN_HASHMAP : 'תהליך התשלום נכשל - לא ניתן לשלם באותו הכרטיס בטווח של פחות מ12 שעות.';
    }

    console.log(error.response.data);
    return message;
}