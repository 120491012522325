import React from 'react';
import Button from '../Button';

const ViewHistoryRecord = ({ onClick }) => {

    const title = 'צפייה בהסטוריה';

    return (
        <Button onClick={onClick}>
            {title}
        </Button>
    )
}

export default ViewHistoryRecord