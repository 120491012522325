import React from 'react';
import SelectInput from '../../../../Components/Forms/select/SelectInput';

const SelectComparingFiles = ({ name, value, handleChange, handleBlur }) => {

    const selects = [
        { 'value': '-1', 'name': 'כל הבקשות' },
        { 'value': '1', 'name': 'בדיקה הצליחה' },
        { 'value': '2', 'name': 'בדיקה נכשלה' },
    ]

    const nameTag = 'בדיקה אוטומטית';

    return (
        <SelectInput
            label={nameTag}
            name={name}
            value={value}
            selects={selects}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={true}
        />
    )
}

export default SelectComparingFiles;