import axios from "../api/axios";
import { useAuthContextContext } from '../contexts/AuthContext';
import { useProjectDetailsContext } from "../contexts/ProjectDetailsContext";
import ProjectDetails from "../models/projectDetails/ProjectDetails";
import ResponseStatus from "../types/ResponseStatus";
import { convertMessageConfigFromServer } from "../Helpers/conversion/convertSystemTable";
import { ERROR_BAD_REQUEST, ERROR_USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL, ERROR_USER_NO_IN_PARKING, ERROR_USER_NOT_FOUND, GET_SYSTEM_TABLES, LOGIN } from "../api/loginApi";

const useLogin = () => {

    const { setProjectDetails, updateUserDetails } = useProjectDetailsContext();
    const { login: authLogin } = useAuthContextContext();

    async function login(values) {

        const result = {
            isSuccess: false,
            message: '',
        }

        const json = {
            userName: values.userName,
            projectId: values.projectId,
            password: values.password,
        };

        try {

            const response = await axios.post(LOGIN, json);
            const responseData = response.data;

            if (responseData.status !== ResponseStatus.SUCCESS) {

                throw new Error(`status is not ${ResponseStatus.SUCCESS}, response.data.message- ${responseData.message}`);
            }

            const {accessToken, refreshToken, userName, userRole} = responseData['data'];
            
            const userDetails = {
                userName: userName,
                userRole: userRole,
            }
            
            authLogin(accessToken, refreshToken);
            updateUserDetails(userDetails);

            // updating the system tables
            const config = {
                headers: {
                    'Authorization': "Bearer " + accessToken,
                    'Content-Type': 'application/json'
                }
            };

            const response2 = await axios.get(GET_SYSTEM_TABLES, config);

            const projectConfdig = { ...response2.data.data.bean };

            projectConfdig?.streets.sort((a, b) => a.name.localeCompare(b.name));
            projectConfdig?.colors.sort((a, b) => a.name.localeCompare(b.name));
            projectConfdig?.types.sort((a, b) => a.name.localeCompare(b.name));

            projectConfdig.messageConfig = convertMessageConfigFromServer(projectConfdig.messageConfig);
            

            const projectDetails = new ProjectDetails(projectConfdig);

            setProjectDetails(projectDetails);
           
            result.isSuccess = true;

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.isSuccess = false;

            if (error.response) {

                //result.message = translateErrorCode(error, { BAD_REQUEST: 'כניסה למערכת נכשלה - השרת לא קיבל את כל הנתונים' });
                const errorData = error.response.data;

                if (errorData.errorCode === ERROR_USER_NOT_FOUND.code) {

                    result.message = ERROR_USER_NOT_FOUND.desplayMsg;
                } else if (errorData.errorCode === ERROR_USER_NO_IN_PARKING.code) {

                    result.message = ERROR_USER_NO_IN_PARKING.desplayMsg;
                }else if (errorData.errorCode === ERROR_USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL.code) {

                    result.message = ERROR_USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL.desplayMsg;
                } else if (errorData.errorCode === ERROR_BAD_REQUEST.code) {

                    result.message = ERROR_BAD_REQUEST.desplayMsg;
                } 
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

        }

        return result;
    }

    return login;
}


export default useLogin;