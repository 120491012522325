import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import FormLogin from './components/FormLogin';
import Style from './LoginPage.module.css';
import { initDammyLoginData, initLoginData } from '../../data/default/login.data';
import { loginSchema } from '../../validation/loginValidation';
import LoadingModal from '../../Components/LoadingModal/LoadingModal';
import DangerAlert from '../../Components/Alerts/DangerAlert';
import useLogin from '../../Hooks/useLogin';
import { useComponentState } from '../../Hooks/useComponentState';


const LoginPage = () => {

    const navigate = useNavigate();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const login = useLogin();

    const { isLoading, message, hasAlert } = componentState;

    const { errors, values, touched, handleSubmit, handleBlur, setFieldValue } = useFormik({
        initialValues: { ...initLoginData },
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: loginSchema,
        onSubmit: (values) => {
            handleLogin(values);
        }
    });

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    return (
        <>
            {isLoading && <LoadingModal />}

            <div className={Style.loginPageLayout}>
                <DangerAlert isShow={hasAlert} message={message} onClose={removeAlert} />
                <h1 className={Style.pageTitle}>לולהטק -  כניסה לניהול תווים</h1>
                <div className={Style.formContainer}>
                    <div className={Style.formTitle}>פרטי התחברות</div>
                    <FormLogin errors={errors} handleBlur={handleBlur} handleChange={handleChange}
                        handleSubmit={handleSubmit} touched={touched} values={values} componentState={componentState} />
                </div>
            </div>
        </>
    );

    async function handleLogin(values) {
        setIsLoading();

        const respone = await login(values);

        if (!respone.isSuccess) {

            setDangerAlert(respone.message);
            return;
        }

        setIsNotLoading();
        navigate('/permit-request/requestManagementPage');
    }
}

export default LoginPage;