
export const initHistoryModalData = {
    isShowHistoryModal: false,
    isRequest: true,
    identifier: -1,
    recordList: [],
    isShowRecordRow: false,
    currentRecord: {},
    modalStatus: {
        isLoading: false,
        msg: '',
    } 
}