import React from 'react'

const RowPermitReport = ({ data }) => {

  return (
      <tr>
        <th scope="row">{data.index}</th>
        <td>{data.loginUser}</td>
        <td>{data.count}</td>
        <td>{data.payment}</td>
        <td>{data.paymentMethod}</td>
        <td>{data.createdBy}</td>
      </tr>
  )
}

export default RowPermitReport