import React from 'react';
import { FaReceipt } from "react-icons/fa";
import SuccessModal from '../../../../Components/GeneralModal/GeneralModal';
import ContinueModalBtn from '../../../../Components/GeneralModal/ContinueModalBtn';
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import Button from '../../../../Components/Buttons/Button';

const SuccesCreatePermitModal = () => {

    const { handleCloseModal, successModalIsShown, successModalData } = useAddPermitPageContext();

    const url_to_nav = '/permit-request/requestManagementPage';
    const {hasReceipt, receiptUrl} = successModalData;

    return (
        <SuccessModal
            title='תו נוצר בהצלחה'
            isShow={successModalIsShown}
            closingFun={() => handleCloseModal(url_to_nav)}
            content={(
                <div style={{ padding: '0.5rem', fontSize: '1.25rem' }}>
                    לאחר יצירת תו תופנה לדף רשימת תווים.
                </div>
            )}
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                    {hasReceipt ? <Button text='הצג קבלה' endIcon={<FaReceipt />} onClick={openReceipt} /> : ''}
                    <ContinueModalBtn onClick={() => handleCloseModal(url_to_nav)} />
                </div>
            )}
        />
    );

    function openReceipt() {
        window.open(receiptUrl, '_blank');
    }
}

export default SuccesCreatePermitModal;