import React from 'react';
import style from '../history.module.css';

const InputCard = ({children}) => {
    
  return (
    <div className={`row mb-3 ${style.inputCard}`}>
        {children}
    </div>
  )
}

export default InputCard