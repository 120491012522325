import React, { useContext } from 'react'
import { ProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestManagmentPageContext';
import RequestCard from './RequestCard';
import ListOfRows from '../../../../Components/UI/displayData/listOfRows/ListOfRows';

const headers = ["#", "תאריך עידכון הבקשה", "מספר רכב",
    "תעודת זהות", "שם משפחה", "שם פרטי", "הוגש באמצעות",
    "מצב בקשה"];

const DisplayManagmentRequestTavim = ({ }) => {

    const { componentState, values, dataState, changePage, handleChengeAndOffset } = useRequestManagmentPageContext();
    const { projectDetails } = useContext(ProjectDetailsContext);
    const paymentConfig = projectDetails.paymentConfig;
    const hasSendSmsOption = paymentConfig.getSendSmsOption();

    const list = dataState.data;
    const offset = values.offset;
    const limit = values.limit;
    const startIndex = offset * limit + 1;
   
    let rows = [];

    if (hasSendSmsOption) headers.splice(8, 0, "שליחת סמס");

    const paigingData = {
        offset: values.offset,
        limit: values.limit,
        totleCount: dataState.count,
        changePage: changePage,
        isLoading: componentState.isLoading
    }

    rows = list.map((requestPermit, index) => {

        return (
            <RequestCard index={startIndex + index}
                key={requestPermit.id} request={requestPermit} />
        );
    });


    return (
        <ListOfRows 
            rows={rows}
            name='בקשות' 
            headers={headers} 
            paigingData={paigingData} 
            handleChenge={handleChengeAndOffset}
        />
    );
}

export default DisplayManagmentRequestTavim;