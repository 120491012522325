import React from 'react'
import { useRequestManagmentPageContext } from '../../../../../contexts/request/RequestDetailsPageContext';
import Alert from '../../../../../Components/Alerts/Alert';

const AlertExitingPermitWithSomePersonId = () => {
    const { exitingPermitWithSamePId, setExitingPermitWithSamePId, values } = useRequestManagmentPageContext();
    const multipleOfCars = exitingPermitWithSamePId > 1;

    const isApprovedRequest = values.approvalDate ? true : false;

    if (isApprovedRequest || exitingPermitWithSamePId < 1)
        return '';

    const msg = `${multipleOfCars ? 'קיימים' : 'קיים'} ${multipleOfCars ? exitingPermitWithSamePId : ''} ${multipleOfCars ? 'תווים נוספים' : 'תו נוסף'} עם אותו מספר תעודת זהות.(מספר רכב שונה)`;

    return (
        <div className='row mt-1'>
            <div className='col-12'>
                <Alert
                    isShow={true}
                    message={msg}
                    type={'warning'}
                    onClose={() => setExitingPermitWithSamePId(0)}
                />
            </div>
        </div>
    );
}

export default AlertExitingPermitWithSomePersonId;