
/** For login and retrive the tokens.
 * @Method POST
 */
export const LOGIN = '/secretary/login';

/** To retrive all system tables.
 * @Method GET
 */
export const GET_SYSTEM_TABLES = '/systemTables/get-backoffice-data-config';

export const ERROR_USER_NOT_FOUND = {
    code: 1,
    desplayMsg : 'כניסה למערכת נכשלה - אחד או יותר מהנתונים לא נכון!'
}

export const ERROR_USER_NO_IN_PARKING = {
    code: 2,
    desplayMsg : 'כניסה למערכת נכשלה - המשתמש לא הוקם בחניה!'
}

export const ERROR_USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL = {
    code: 3,
    desplayMsg : 'כניסה למערכת נכשלה - למשתמש אין את ההרשאה המתאימה!'
}

export const ERROR_BAD_REQUEST = {
    code: 4,
    desplayMsg : 'כניסה למערכת נכשלה - השרת לא קיבל את כל הנתונים'
}