export const ServerExceptionTypes = {
    INTERNAL_SERVER_ERROR: 1,
    BAD_REQUEST: 2,
    SYSTEM_TABLE_NOT_FOUND: 3,
    LOGIN_FAILD: 4,
    VALID_PERMIT_ALREADY_EXIST: 5,
    PENDING_REQUEST_ALREADY_EXIST: 6,
    OTP_FAILD: 7,
    PERMIT_NOT_FOUND: 8,
    REQUEST_NOT_FOUND: 9,
    EMAIL_NOT_VALID: 10,
    MOBILE_NOT_VALID: 11,
    UNAUTHORIZED: 12,
    PAYMENT_CARD_ALREADY_IN_HASHMAP: 13,
    PAYMENT_TRANZILA: 14,
}