import moment from "moment/moment";

/**
 * Function takes string as a argoment and check if its a valid Israeli Person Id.
 * @param {*} personId String
 * @returns Return True if its valid Israeli Person Id.
 */
export function isPersonIdValid(personId){

    if(personId === null  || personId === undefined  || personId.trim() === '') 
        return false;
        
    if (personId.length !== 9 || isNaN(personId)) return false;
    
    return Array.from(personId, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0;
}

/**
 * Function takes to dates and checks if 'firstDate' is geater or same as the 'secondDate'
 * @param {*} firstDate Required, must be a valid date format ('YYYY-MM-DD')
 * @param {*} secondDate Required, must be a valid date format ('YYYY-MM-DD')
 * @returns The function return True if first date is greater or same as the second date,
 * if 'firstDate' or 'secondDate' is undefide or not valid date format the function will return False.
 */
export function isFirstDateGreaterOrSame(firstDate, secondDate){

    let defaultFormat = 'YYYY-MM-DD'
    const firstDateMoment = moment(firstDate, defaultFormat); 
    const secondDateMoment = moment(secondDate, defaultFormat); 
    return firstDateMoment.isAfter(secondDateMoment);
    return firstDateMoment.isSameOrAfter(secondDateMoment);
}

/**
 * Function takes to dates and checks if 'firstDate' is geater then the 'secondDate'
 * @param {*} firstDate Required, must be a valid date format ('YYYY-MM-DD')
 * @param {*} secondDate Required, must be a valid date format ('YYYY-MM-DD')
 * @returns The function return True if first date is greater then the second date,
 * if 'firstDate' or 'secondDate' is undefide or not valid date format the function will return False.
 */
export function isFirstDateGreater(firstDate, secondDate){

    let defaultFormat = 'YYYY-MM-DD'
    const firstDateMoment = moment(firstDate, defaultFormat); 
    const secondDateMoment = moment(secondDate, defaultFormat); 
    return firstDateMoment.isAfter(secondDateMoment);
}