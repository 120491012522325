import React from 'react';
import SimpleTable from '../../../../Components/UI/displayData/Table/SimpleTable';
import ExpiredPermitsRow from './ExpiredPermitsRow';
import BtnsRow from './BtnsRow';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';

const DisplayExpiredPermits = ({ expiredPermitsState }) => {

    const rows = expiredPermitsState.list.map((expiredPermit, index) => {
        return <ExpiredPermitsRow key={`ExpiredPermitsRow${index + 1}`} permit={expiredPermit} index={index + 1} />
    });

    return (
        <>
            <BtnsRow list={expiredPermitsState.list} header={expiredPermitsState.csvHeader} />
            <div className='row'>
                <SimpleCard>
                    <div className='col'>
                        <SimpleTable
                            headers={
                                ['#',
                                    'שם מלא',
                                    'מספר רכב',
                                    'סלולרי',
                                    'מספר טלפון נוסף',
                                    'תאריך סיום',
                                    'יצרן',
                                    'פעולות']
                            }
                            rows={rows}
                            name={'תווים'} />
                    </div>
                </SimpleCard>
            </div>
        </>
    );
}

export default DisplayExpiredPermits;
