import React from 'react'
import { RiArrowGoBackLine } from 'react-icons/ri';
import Button from './Button';

const GoBackBtn = ({}) => {

    return (
        <Button
            theme='secondary'
            onClick={onClickHandle}
            endIcon={<RiArrowGoBackLine />}>
            חזור
        </Button>
    );

    function onClickHandle() {
        window.history.back();
    }
}


export default GoBackBtn;