import React from 'react';
import style from '../sendEmail.module.css';

const PrelogTextRow = ({email}) => {
    return (
        <div className='row mt-4'>
            <div className='col-12'>
                <p>כתובת האזרח: <span className={style.emailSendTo}>{email}</span></p>
            </div>
        </div>
    )
}

export default PrelogTextRow