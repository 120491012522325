import React from 'react';
import Alert from '../../../../../Components/Alerts/Alert';
import { useAddPermitPageContext } from '../../../../../contexts/permit/AddPermitPageContext';

const AlertExitingPermitWithSomePersonId = () => {
    const { countActivePermitWithSamePersinId, setCountActivePermitWithSamePersinId } = useAddPermitPageContext();
    const multipleOfCars = countActivePermitWithSamePersinId > 1;

    if (countActivePermitWithSamePersinId < 1)
        return '';

    const msg = `${multipleOfCars ? 'קיימים' : 'קיים'} ${multipleOfCars ? countActivePermitWithSamePersinId : ''} ${multipleOfCars ? 'תווים נוספים' : 'תו נוסף'} עם אותו מספר תעודת זהות.(מספר רכב שונה)`;

    return (
        <div className='row mt-1'>
            <div className='col-12'>
                <Alert
                    isShow={true}
                    message={msg}
                    type={'warning'}
                    onClose={() => setCountActivePermitWithSamePersinId(0)}
                />
            </div>
        </div>
    );
}

export default AlertExitingPermitWithSomePersonId;