export function convertColorCodeToText(code, colors) {

    if(colors == null) return '';

    if(!Array.isArray(colors)) return '';

    const arr = colors.filter(color => color.id === code);

    if(arr.length === 0) return '';

    return arr[0].name;
}

export function convertMakerCodeToText(code, makers) {
 
    if(makers == null) return '';

    if(!Array.isArray(makers)) return '';

    const arr = makers.filter(maker => maker.id === code);

    if(arr.length === 0) return '';

    return arr[0].name;
}

export function convertStreetCodeToText(code, streets) {
    
    if(streets == null) return '';

    if(!Array.isArray(streets)) return '';

    const arr = streets.filter(street => street.id === code);

    if(arr.length === 0) return '';

    return arr[0].name;
}