import React from 'react';
import ReactDOM from 'react-dom';
import style from './loadingModal.module.css'

const Backdrop = () => {
    return <div className={style.backdrop}></div>
}

const ModalOverlay = ({ children }) => {

    return (
        <div className={style.modal}>
            <div className={style.content}>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary" style={{ width: '7rem', height: '7rem', fontSize: '1.5rem' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div className={style.text}>
                טוען...
                </div>
            </div>
        </div>
    );
}

const portalElement = document.getElementById('overlays');

const LoadingModal = () => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(<Backdrop />, portalElement)}
            {ReactDOM.createPortal(<ModalOverlay>{'loading'}</ModalOverlay>, portalElement)}
        </React.Fragment>
    )
}

export default LoadingModal