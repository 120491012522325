import React from 'react';
import Style from './formSection.module.css';

const FormSection = ({children, title}) => {
    return (
        <div className={Style.formSection}>
            {title ? <div className={Style.headerTitle}>{title}</div> : ''}
            {children}
        </div>
    )
}

export default FormSection;