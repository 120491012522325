import React from 'react';
import PrelogTextRow from '../PrelogTextRow';
import CityEmailRow from '../CityEmailRow';
import SmallTitle from '../SmallTitle';
import InputGroup from '../../../../Components/Forms/InputGroup';
import TextOptionsRow from '../TextOptionsRow';
import InputTextArea from '../../../../Components/Forms/InputTextArea';
import { SendMsgOptionType } from '../../../../types/SendMsgOptionType';
import { RiMailSendLine } from 'react-icons/ri';
import Button from '../../../../Components/Buttons/Button';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import style from '../../sendEmail.module.css';

const EmailContainer = ({ formik, componentState, handleClickOptionList }) => {

    const { projectDetails } = useProjectDetailsContext();
    
    const messageConfig = projectDetails.messageConfig;
    const messageEmailConfig = messageConfig.messageEmailConfig;
    
    const { values, handleBlur, setFieldValue, handleChange, errors, touched } = formik;
    const { isLoading } = componentState;

    const textOptions = messageEmailConfig.generalEmailConfig.contentOptions;

    let errorChecksInputAndFreeText = '';
    let errorEmailSubject = '';

    if(touched?.email && touched.email.emailSubject) {

        if(errors.email && errors.email.emailSubject) errorEmailSubject = errors.email.emailSubject;
    }

    if (touched?.email && (touched.email.chosenTextOptionCodes || touched.email.freeText)) {

        if (errors?.email?.freeText) errorChecksInputAndFreeText = errors.email.freeText;
    }

    const sendEmailBtnDisabled = !formik.isValid || isLoading;

    if (SendMsgOptionType.EMAIL !== formik.values.sendViaEmail) {
        return '';
    }

    return (
        <>
            <PrelogTextRow email={values.email.citizenEmail} />

            <CityEmailRow errors={errors} touched={touched} values={values}
                handleClickHasCityEmail={handleClickHasCityEmail} handleBlur={handleBlur}
                handleChange={handleChange} />

            <div className={`container ${style.emailContainer}`}>
                <div className='row'>
                    <div className='col-12'>
                        <SmallTitle title='נושא האימייל' />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-10'>
                        <InputGroup
                            name='email.emailSubject'
                            value={values.email.emailSubject}
                            styleInput={{ fontSize: '1.2rem' }}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorMsg={errorEmailSubject}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <SmallTitle title='גוף האימייל' />
                    </div>
                </div>
                <TextOptionsRow
                    handleBlur={handleBlur}
                    handleClick={handleClickOptionList}
                    textOptions={textOptions}
                    values={values}
                    name={'email.chosenTextOptionCodes'}
                    errorChecksInputAndFreeText={errorChecksInputAndFreeText}
                />

                <div className='row mt-2'>
                    <div className='col-12'>
                        <InputTextArea
                            placeholder='מלל חופשי...'
                            name='email.freeText'
                            value={values.email.freeText}
                            rows='3'
                            inputStyle={{ fontSize: '1.2rem' }}
                            handleBlur={handleBlur}
                            handleChange={handleChange} />
                    </div>
                </div>

            </div>
            <div className='row mt-2 mb-2'>
                <div className='col-12'>
                    <Button isLoading={isLoading} disabled={sendEmailBtnDisabled} text='שלח אמייל' endIcon={<RiMailSendLine />} onClick={formik.handleSubmit} />
                </div>
            </div>
        </>
    );

    function handleClickHasCityEmail(event) {
        const name = event.currentTarget.name;
        const isChecked = event.currentTarget.checked;
        const value = Number(event.currentTarget.value);

        setFieldValue(name, isChecked ? Number(value) : 0);
    }
}

export default EmailContainer;