export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
export const MAX_FILE_SIZE = 5942880; // 5*1024*1024 bytes
export const ONLY_DIGIT_PERMITED_MESSAGE = 'יכול להכיל ספרות בלבד.';
export const ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE = 'יכול להכיל אותיות בעברית ,אנגלית ורווח בלבד.';

export const MAX_FILE_SIZE_MESSAGE = 'קובץ גדול מידי, ניתן לעלות עד 5 MB.';
export const TYPE_FILE_MESSAGE = `ניתן לעלות קבצים מסוג: ${SUPPORTED_FORMATS} בלבד.`;
export const REQUIRED_FIELD_MESSAGE = 'שדה חובה!.';
export const MOBILE_FORMAT_MESSAGE = 'מספר סלולרי אינו תקין.';
export const MIKOD_MESSAGE = 'מיקוד לא תקין.';
export const START_DATE_GREATER_THEN_END_DATE_MESSAGE = 'תאריך סיום חייב להיות זהה או גדול יותר.';
export const NOT_VALID_EMAIL_ADDRESS_MESSAGE = 'כתובת אימייל לא חוקית.';