import React from 'react';
import { MdFace6 } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import style from './Navbar.module.css';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import { UserRoleType } from '../../../types/UserRoleType';

const UserBox = () => {

    const { userDetails } = useProjectDetailsContext();
    const {userName, userRole} = userDetails;

    return (
        <div title={userName} className={style.userBox}>
            <div>{userRole === UserRoleType.ADMIN ? <MdOutlineAdminPanelSettings /> : <MdFace6 />}</div>
            <div>{userName}</div>
        </div>
    )
}

export default UserBox;