import React from 'react'
import SelectInput from '../SelectInput';

const SelectMonthPayment = ({ name, errorMsg, isRequired, handleChange, handleBlur, value }) => {

  const monthSelect = [
    { key: 13, value: '-1', name: 'צריך לבחור' },
    { key: 1, value: '01', name: '01' },
    { key: 2, value: '02', name: '02' },
    { key: 3, value: '03', name: '03' },
    { key: 4, value: '04', name: '04' },
    { key: 5, value: '05', name: '05' },
    { key: 6, value: '06', name: '06' },
    { key: 7, value: '07', name: '07' },
    { key: 8, value: '08', name: '08' },
    { key: 9, value: '09', name: '09' },
    { key: 10, value: '10', name: '10' },
    { key: 11, value: '11', name: '11' },
    { key: 12, value: '12', name: '12' },
  ];


  return (
    <SelectInput
      errorMsg={errorMsg}
      name={name}
      label={'חודש'}
      value={value}
      isRequired={true}
      selects={monthSelect}
      handleBlur={handleBlur}
      handleChange={handleChange} />
  )
}

export default SelectMonthPayment