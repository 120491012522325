import React from 'react';
import PagingBox from '../PagingBox/PagingBox';

const ListOfRows = ({ rows, name, paigingData, handleChenge }) => {

    let content = 'אין נתונים';
    let count = 0;
    if(Array.isArray(rows)) count = rows.length;

    const offset = paigingData.offset;
    const limit = paigingData.limit;
    const totleCount = paigingData.totleCount;
    const changePage = paigingData.changePage;
    const isLoading = paigingData.isLoading;

    if (count > 0) {
        content = rows;
    }

    return (
        <div>
            <PagingBox offset={offset} limit={limit} count={totleCount} 
                changePage={changePage} name={name} isLoading={isLoading} handleChengeLimit={handleChenge} />
            {content}
            {(count > 5) ? <PagingBox offset={offset} limit={limit} count={totleCount} changePage={changePage} isLoading={isLoading} handleChengeLimit={handleChenge} /> : ''}
        </div>
    );
}

export default ListOfRows;