import moment from "moment";
import Languages from "../../types/Languages";
import { initSearchPermitFormData } from "./searchPermitForm.data";
import { initSearchRequestFormData } from "./searchRequestForm.data";

export const initAppInfoData = {
    languages: Languages.HEBRED,
    formsInitData: {
        requestPermit: {
            updateDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            currentForm: initSearchRequestFormData,
            initForm: initSearchRequestFormData,
        },
        permit: {
            updateDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            currentForm: initSearchPermitFormData,
            initForm: initSearchPermitFormData,
        }
    }
}