import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import Button from '../Buttons/Button';

const CloseModalBtn = ({ disabled, theme, label, onClick, style, title }) => {

    return (
        <Button
            type="button"
            onClick={onClick}
            theme={theme}
            style={style}
            text={label}
            disabled={disabled}
            title={title}
            endIcon={<IoCloseOutline size={20} />}
        />
    );
}

CloseModalBtn.defaultProps = {
    disabled: false,
    theme: "secondary",
    label: "ביטול",
    onClick: () => { },
    style: {},
    title: ''
};

export default CloseModalBtn;