import React from 'react';
import Style from './listOfRows.module.css';
import PaymentMethod from '../../../../types/PaymentMethod';
import { convertPaymentMethodCodeToText } from '../../../../Helpers/conversion/convertTypeCodeToText';

const SpanPaymentMethod = ({ paymentMethodCode }) => {

    const paymentMethod = convertPaymentMethodCodeToText(paymentMethodCode);
    let classes = 'badge text-dark';

    if (paymentMethodCode === PaymentMethod.CASH) {

        classes += ' ' + Style.spanPaymentMethodCash;
    } else if (paymentMethodCode === PaymentMethod.CARD)  {

        classes += ' ' + Style.spanPaymentMethodCard;
    }else {

        classes = 'badge bg-light text-dark';
    }

    // badge bg-light text-dark
    return (
        <span className={classes} style={{ fontSize: '1rem' }}>{paymentMethod}</span>
    );
}

export default SpanPaymentMethod;