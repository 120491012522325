import React from 'react';
import { TbDeviceAnalytics } from "react-icons/tb";
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import ActionBoxCard from '../../../../Components/UI/Card/ActionBoxCard';
import RequestStatusType from '../../../../types/RequestStatusType';
import {Button, SendMessageBtn, HistoryPermitList, GoBackBtn, ViewFilesBtn, CreatePermitBtn, UpdateRquestStatusBtn, SaveBtn} from '../../../../Components/Buttons/buttuns.import';
import style from '../requestDetailsPage.module.css';

const ButtonsContainer = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { handleUpdateRequest, handlePreApprove, handleUpdateStatus,
        openHistoryModal, componentState, values, openFilesModal, refSendEmailModal } = useRequestManagmentPageContext();

    const requesStatus = values.status;
    let hasApproveBtn = false;
    let hasUpdateStatusBtn = false;
    let isAdmin = false;
    let isDisabled = false;
    const openSendEmailFunc = refSendEmailModal.current?.openSendMessageModal ? refSendEmailModal.current.openSendMessageModal : null;
 

    if (projectDetails?.userName && projectDetails.userName.startsWith('plola')) {
        isAdmin = true;
    }

    if (componentState.isLoading) isDisabled = true;


    if (requesStatus === RequestStatusType.PENDING) {

        hasApproveBtn = true;
        hasUpdateStatusBtn = true
    }

    if (requesStatus === RequestStatusType.APPROVED) {
        //comparisonBtn = false;
    }

    if (requesStatus === RequestStatusType.REJECTED) {
        hasUpdateStatusBtn = true
    }

    return (
        <ActionBoxCard>
            <div className={style.btnContainers}>
                <div className={style.btnContainersItem}>
                    <ViewFilesBtn disabled={false} personId={values.personId} />
                    <HistoryPermitList onClick={() => openHistoryModal(true, values.reportNum)} />
                    <SaveBtn onClick={handleUpdateRequest} label={'שמירה'} />
                    <Button endIcon={<TbDeviceAnalytics />} text='השוואת קבצים וטופס' onClick={openFilesModal} />
                    <SendMessageBtn disabled={!openSendEmailFunc} 
                    onClick={() => openSendEmailFunc(true, values.requestId, values.email, values.mobile)} />
                </div>
                <div className={style.btnContainersItem}>
                    {hasApproveBtn ? (
                        <CreatePermitBtn
                            disabled={isDisabled} onClick={() => handlePreApprove()} label={'אישור'} />
                    ) : ''}

                    {hasUpdateStatusBtn ? (
                        <UpdateRquestStatusBtn
                            currentStatus={values.status}
                            onClick={newStatus => handleUpdateStatus(newStatus)}
                        />
                    ) : ''}
                </div>
            </div>
            <GoBackBtn />
        </ActionBoxCard>
    )
}

export default ButtonsContainer