
export const COMPONENT_STATE_ACTION = {
    SET_ALERT: 'SET_ALERT',
    LOADING: 'LOADING',
    NOT_LOADING: 'NOT_LOADING',
    REMOVE_ALERT: 'REMOVE_ALERT',
}

export function componentReducer(state, action) {

    const payload = action.payload;

    switch (action.type) {
        case COMPONENT_STATE_ACTION.SET_ALERT:

            return {
                ...state,
                hasAlert: payload.hasAlert,
                message: payload.message,
                alertColor: payload.alertColor,
                isLoading: false,
            }
        case COMPONENT_STATE_ACTION.LOADING:

            return {
                ...state,
                hasAlert: false,
                message: '',
                alertColor: '',
                isLoading: true,
            };
        case COMPONENT_STATE_ACTION.NOT_LOADING:

            return {
                ...state,
                isLoading: false,
            };
        case COMPONENT_STATE_ACTION.REMOVE_ALERT:
            console.log(COMPONENT_STATE_ACTION.REMOVE_ALERT)
            return {
                ...state,
                hasAlert: false,
                message: '',
                alertColor: '',
            };
        default:
            return state;
    }
} 