import React from 'react';
import InputGroup from '../../../../Components/Forms/InputGroup';
import PaymentMethod from '../../../../models/PaymentMethod';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioBtn from '../../../../Components/Forms/radio/RadioBtn';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';


const PermitPaymentDetails = () => {

    const { values } = usePermitDetailsPageContext();
    
    return (
        <FormSection title={"פרטי תשלום"}>
            <div className="row">
                <div className="col">
                    <RadioBtnsContainer label={'צורת תשלום'}>
                        <RadioBtn
                            label='שולם במזומן'
                            name='paymentMethod'
                            id="paymentMethod"
                            isChecked={values.paymentMethod === PaymentMethod.CASH ? true : false}
                            value={PaymentMethod.CASH}
                            disabled={true}
                        />
                        <RadioBtn
                            label='שולם באשראי'
                            name='paymentMethod'
                            id="paymentMethod"
                            isChecked={values.paymentMethod === PaymentMethod.CREDIT_CARD ? true : false}
                            value={PaymentMethod.CREDIT_CARD}
                            disabled={true}
                        />
                        <RadioBtn
                            label='לא שולם'
                            name='paymentMethod'
                            id="paymentMethod"
                            isChecked={values.paymentMethod === PaymentMethod.NONE ? true : false}
                            value={PaymentMethod.NONE}
                            disabled={true}
                        />
                    </RadioBtnsContainer>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        label={'מספר אסמכתא'}
                        name={'asmachta'}
                        value={values.asmachta}
                    />
                </div>
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        label={'סכום'}
                        name='amount'
                        value={values.amount}
                    />
                </div>
            </div>
        </FormSection>
    );

    
}

export default PermitPaymentDetails;