import { useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import { useAuthContextContext } from '../contexts/AuthContext';
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const {authState} = useAuthContextContext()  

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            
            config => {

                if(!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${authState.accessToken}`;
                }

                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {

                const prevRequest = error?.config;
                if (error?.response?.status === 401 && ! prevRequest?.sent){
                    
                    console.log(`in interceptor`);
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();

                    console.log(`newAccessToken: ${newAccessToken}`);
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }

    }, [authState, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;