
/** Get the history records of the permit.
 * @Method GET
 * - Exmpole: /history/permit/history-records/{permitId} 
 */
export const GET_PERMIT_HISTORY_RECORDS = "/history/permit/history-records";


/** Get the history records of the request.
 * @Method GET
 * - Exmpole: /history/permit/history-records/{permitId} 
 */
export const GET_REQUEST_HISTORY_RECORDS = "/history/request/history-records";