import React from 'react';
import { RiMailSendLine } from "react-icons/ri";
import Button from './Button';

const SendMessageBtn = ({title, theme, onClick, disabled, style}) => {

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      theme={theme}
      endIcon={<RiMailSendLine />}>
      {title}
    </Button>
  );
}


SendMessageBtn.defaultProps = {
  title: 'שליחת הודעה',
  disabled: false,
  theme: "primary",
  style: {},
  onClick: () => { },
}

export default SendMessageBtn;