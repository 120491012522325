import * as yup from 'yup';
import { REQUIRED_FIELD_MESSAGE, START_DATE_GREATER_THEN_END_DATE_MESSAGE } from '../../Errors/errorMessages';
import { isFirstDateGreater } from '../../Helpers/vatidation/validation';

export const expiredPermitSchema = yup.object().shape({
    startDate: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    endDate: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('end-date-greater-then-start', START_DATE_GREATER_THEN_END_DATE_MESSAGE, function(value) {

            const { startDate } = this.parent;
            return !isFirstDateGreater(startDate, value);
        }),
});
