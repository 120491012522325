export default function getFullBootstarpClass(type) {
    let classes = "alert alert-primary";

    if(type === 'secondary') classes = "alert alert-secondary";
    if(type === 'success') classes = "alert alert-success";
    if(type === 'danger') classes = "alert alert-danger";
    if(type === 'warning') classes = "alert alert-warning";
    if(type === 'info') classes = "alert alert-info";
    if(type === 'light') classes = "alert alert-light";
    if(type === 'dark') classes = "alert alert-dark";

    return classes;
}