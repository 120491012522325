import React from 'react';
import Style from './Card.module.css';

const ActionBoxCard = ({ title, withTitle, children }) => {

    const classes = 'card ' + Style.cardLightGreen;
    const classesBody = 'card-body ' + Style.myCard;
    let cardTitle = '';

    if (withTitle === true) {
        cardTitle = (
            <div className="card-header">
                <h3>{title}</h3>
            </div>
        );
    }

    return (
        <div className={classes}>
            {cardTitle}
            <div className={classesBody}>
                {children}
            </div>
        </div>
    );
}

ActionBoxCard.defaultProps = {
    title: '',
    withTitle: false
}

export default ActionBoxCard;