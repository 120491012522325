import React from 'react';
import DateInput from '../../../../Components/Forms/DateInput';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitDatesDetails = () => {
    const { errors, values, touched, handleChange, handleBlur } = usePermitDetailsPageContext();
    const dateTitle = 'תאריך';

    return (
        <FormSection title={dateTitle}>
            <div className='row'>
                <div className='col-lg-6 col-xl-4'>
                    <DateInput
                        label='תחילת תאריך'
                        name='startValidityDate'
                        value={values.startValidityDate}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.startValidityDate && touched.startValidityDate) ? errors.startValidityDate : undefined}
                    />
                </div>
                <div className='col-lg-6 col-xl-4'>
                    <DateInput
                        label='סוף תאריך'
                        name='endValidityDate'
                        value={values.endValidityDate}
                        isRequired={true}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.endValidityDate && touched.endValidityDate) ? errors.endValidityDate : undefined}
                    />
                </div>
            </div>
        </FormSection>
    );
}

export default PermitDatesDetails