import React, { useState, createContext, useContext, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { addPermitSchema } from "../../validation/permit/addPermitValidation";
import { useAddPermit } from "../../Hooks/permits/useAddPermit";
import useUploadFileModal from "../../features/uploadFile/useUploadFileModal";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import AddPermitPage from "../../Pages/Permits/addPermit/AddPermitPage";
import { initPermitData } from "../../data/default/permit.data";
import { useComponentState } from "../../Hooks/useComponentState";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { CALCULATE_INIT_PERMIT_DATA } from "../../api/calculateInitPermitData.api";
import PaymentMethod from "../../models/PaymentMethod";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";
import { useAuthContextContext } from "../AuthContext";

export const AddPermitPageContext = createContext();

const AddPermitPageProvider = () => {

    const navigate = useNavigate();

    const axiosPrivate = useAxiosPrivate();
    const { logout } = useAuthContextContext();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { projectDetails } = useProjectDetailsContext();
    const { uploadFileModal, setUploadFileModal, errorFileModal, isLoadingFileModal } = useUploadFileModal();
    const [successModalIsShown, setSuccessModalIsShown] = useState(false);
    const [successModalData, setSuccessModalData] = useState({
        permitId: -1, hasReceipt: false, receiptUrl: ''
    })
    const [countActivePermitWithSamePersinId, setCountActivePermitWithSamePersinId] = useState(0);
    const [greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit] = useState('');
    const { addPermit } = useAddPermit();

    const paymentConfig = projectDetails.paymentConfig;
    const colorAndType = paymentConfig.getColorAndType();
    const vColor = colorAndType ? -1 : 164;
    const vType = colorAndType ? -1 : 2096;

    const { errors, values, touched, isValid, handleSubmit, handleBlur,
        setFieldValue, validateForm, validateField, setFieldTouched } = useFormik({
            initialValues: { ...initPermitData, vType: vType, vColor: vColor },
            validateOnMount: true,
            validateOnChange: false,
            validationSchema: addPermitSchema,
        });

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    useEffect(() => {
        handleGetInitPermitData();
    }, [values.permitType]);

    async function handleGetInitPermitData() {

        setIsLoading();
        const streetIdStr = values.street.toString();

        const sendingBody = {
            permitType: values.permitType,
            carNum: values.carNumber,
            personId: values.personId,
            streetId: streetIdStr ? streetIdStr : '-1',
            houseNum: values.houseNum ? values.houseNum : '1'
        }

        try {

            const response = await axiosPrivate.post(CALCULATE_INIT_PERMIT_DATA, sendingBody);
            const data = response.data;

            const moneyAmount = data.moneyAmount ? data.moneyAmount : 0;
            const countActivePermitWithSamePersinId = data.countActivePermitWithSamePersinId ? data.countActivePermitWithSamePersinId : 0;
            const greatestEndDateOfActivePermit = data.greatestEndDateOfActivePermit ? data.greatestEndDateOfActivePermit : '';
            const estimateEndDate = data.estimateEndDate ? data.estimateEndDate : '';

            if (estimateEndDate) {
                setFieldValue('endValidityDate', estimateEndDate);
            }

            setFieldValue('amount', moneyAmount);

            if (moneyAmount <= 0) {
                setFieldValue('paymentMethod', PaymentMethod.NONE);
            } else {
                setFieldValue('paymentMethod', PaymentMethod.CREDIT_CARD);
            }

            setCountActivePermitWithSamePersinId(countActivePermitWithSamePersinId);
            setGreatestEndDateOfActivePermit(greatestEndDateOfActivePermit);

            setIsNotLoading();
        } catch (e) {

            console.log(e);
            setDangerAlert(e?.message);
        }
    }

    async function handleAddPermit(event) {

        event.preventDefault();
        setIsLoading();

        // 1. checking validation.
        const jsonErrors = await validateForm();

        const errorName = Object.keys(jsonErrors);

        if (errorName.length > 0) {

            errorName.forEach(name => {
                setFieldTouched(name, true);
            });

            setIsNotLoading();
            return;
        }

        // 2. Add permit http request.
        try {

            const responseData = await addPermit(values);

            // 3. Getting from the http response the response data.
            const permitId = responseData.permitId ? responseData.permitId : -1;
            const hasReceipt = responseData.hasReceipt ? responseData.hasReceipt : false;
            const receiptUrl = responseData.receiptUrl ? responseData.receiptUrl : '';

            setIsNotLoading();

            if (permitId < 1) {
                throw new Error('no permitId');
            }

            setSuccessModalData({ permitId: permitId, hasReceipt: hasReceipt, receiptUrl: receiptUrl });
            setSuccessModalIsShown(true);
            return;

        } catch (error) {
            console.log('error ' + error);
            //onFocusAlert();

            let message = 'בעיה כללית בשרת';

            if (error.response) {

                message = translateErrorCode(error, { BAD_REQUEST: 'כניסה למערכת נכשלה - השרת לא קיבל את כל הנתונים' });
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            if (error?.response?.status === 401) {

                message = 'צריך להתחבר מחדש 401';
                logout();
            }

            setDangerAlert(message);
            console.log('error: ' + error);
        }

    }

    function handleCloseModal(url) {

        setSuccessModalIsShown(false);
        navigate(url);
    }

    function closeUploadFileModal() {
        setUploadFileModal(false);
    }

    function openUploadFileModal() {
        setUploadFileModal(true);
    }

    function clearInput(name) {
        setFieldValue(name, '');
        setFieldTouched(name, true);
    }

    return (
        <AddPermitPageContext.Provider value={{
            handleChange, errors, values, touched, isValid, handleSubmit, handleBlur, setFieldValue, validateField, setFieldTouched, clearInput,
            handleAddPermit, handleCloseModal, successModalIsShown, componentState, removeAlert,
            uploadFileModal, closeUploadFileModal, openUploadFileModal,
            errorFileModal, isLoadingFileModal, handleGetInitPermitData, successModalData,
            countActivePermitWithSamePersinId, setCountActivePermitWithSamePersinId, greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit
        }}>
            <AddPermitPage />
        </AddPermitPageContext.Provider>
    );
}

export default AddPermitPageProvider;

export const useAddPermitPageContext = () => useContext(AddPermitPageContext);