import React from 'react';
import style from './selectLimitOfRow.module.css';

const SelectLimitOfRow = ({name, label, handleChange, handleBlur, value}) => {

    const id = name + "Id";

    return (
        <div className={style.formFloating}>
            <label className={style.label}>{label}</label>
            <select
            name={name}
            value={value}
            onChange={event => handleChange(event)}
            onBlur={event => handleBlur(event)} 
            className={style.select} 
            id={id}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
            </select>
        </div>
    )
}

SelectLimitOfRow.defaultProps = {
    name: 'name',
    label: "מס' שורות בדף:",
    handleChange: (event)=> {},
    handleBlur: (event)=> {}
}

export default SelectLimitOfRow;