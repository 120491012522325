import React from 'react';
import style from './paging.module.css';

const NumOrange = ({ num }) => {
    return (
        <span className={style.numOrange}>
            {num}
        </span>
    )
}

NumOrange.defaultProps = {
    num: 0
};

export default NumOrange;