import React from 'react'
import SelectInput from './SelectInput';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';

const SelectPermitType = ({ name, errorMsg, isRequired, handleChange, handleBlur, value }) => {

    const { projectDetails } = useProjectDetailsContext();

    let permitTypes = [
        { key: 'permitTypes1', value: -1, name: 'צריך לבחור...' },
        { key: 'permitTypes2', value: 0, name: 'רגיל' },
    ];

    if (projectDetails.paymentConfig.getGilHaZahavOption()) {
        permitTypes.push({ key: 'permitTypes3', value: 1, name: 'זהב' })
    }

    if (projectDetails.paymentConfig.getStudentOption()) {
        permitTypes.push({ key: 'permitTypes4', value: 2, name: 'סטודנט' })
    }
    
    // TODO handicap is code 3, need to check.


    if (projectDetails.paymentConfig.municipalEmployeeOption) {
        permitTypes.push({ key: 'permitTypes5', value: 4, name: 'עובד עירייה' })
    }

    return (
        <SelectInput
            name={name}
            label={'סוג תו'}
            selects={permitTypes}
            isRequired={isRequired}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={value}
            errorMsg={errorMsg}
        />

    )
}

export default SelectPermitType;