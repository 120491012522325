import React from "react";
import Modal from '../UI/modal/Modal'
import style from './generalModal.module.css';
import GeneralModalClosingBtn from "./GeneralModalClosingBtn";

const GeneralModal = ({ title, content, footer, closingFun, hasHeader, hasFooter, modalSize, isShow }) => {

    let headerClasses = style.header;
    let bodyClasses = style.modalBody;
    let footerClasses = style.footer;
    let bodyStyle = { maxHeight: '80vh' };

    let amountToReduced = 0;

    if(hasHeader) amountToReduced += 6;

    if(hasFooter) amountToReduced += 8;

    let bodyHeight = 80 - amountToReduced;

    bodyStyle.maxHeight = `${bodyHeight}vh`;

    if(modalSize === 'L' || modalSize === 'XL') {
        bodyStyle.height = `${bodyHeight}vh`;
    }

    if(!isShow) {
        return '';
    }
    
    return (
        <Modal modalSize={modalSize}>  
            {hasHeader ? (
            <div className={headerClasses}>
                <span></span>
                <span className={style.modalTitle}>{title}</span>
                <GeneralModalClosingBtn onClick={closingFun}/>
            </div>
            ): ''}
            <div style={bodyStyle} className={bodyClasses}> {content} </div>
            {hasFooter ? (
            <>
                <hr className={style.hr}/>
                <div className={footerClasses}> {footer} </div>
            </>
            ): ''}

        </Modal>
    );

}

GeneralModal.defaultProps = {
    hasHeader: true,
    hasFooter: true,
    footer: '',
    title: '',
    content: '',
    modalSize: 'M'
};

export default GeneralModal;