import React from 'react';
import Table from '../../../Components/UI/displayData/Table/Table';
import BtnBox from '../../../Components/UI/BtnBox/BtnBox';
import DateUtils from '../../../Helpers/time/DateUtils';
import ViewHistoryRecord from '../../../Components/Buttons/History/ViewHistoryRecord';
import { convertHistoryStatusCodeToText } from '../../../Helpers/conversion/convertTypeCodeToText';

const HistoryRecordRow = ({ historyRecord, index, goToViewHistoryRecord }) => {
    const dateUtils = new DateUtils();
    const status = historyRecord.statusDetails.status
    const statusName = convertHistoryStatusCodeToText(status);
    const dateTime = dateUtils.getDateAndTimeStringFromUnixTimestams(historyRecord.statusDetails.date);

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{statusName}</td>
            <td>{dateTime}</td>
            <td>{historyRecord.userName}</td>
            <td>
                <BtnBox>
                    <ViewHistoryRecord onClick={() => goToViewHistoryRecord(index)} />
                </BtnBox>
            </td>
        </tr>
    );
}

const HistoryRecordTable = ({ history, goToViewHistoryRecord }) => {

    const header = ['#', 'סוג פעולה', 'זמן פעולה', 'משתמש', 'צפייה'];
    
    const rows = history.map((historyRecord, index) => {
        return (
            <HistoryRecordRow key={`HistoryRecordRow${index}`} historyRecord={historyRecord} 
            index={index} goToViewHistoryRecord={goToViewHistoryRecord} />
        );
    });

    return (
        <div style={{
            marginTop: '0.5rem',
            padding: '0.5rem',
            borderRadius: '10px',
            backgroundColor: '#EDEEF7',
            fontSize: '1rem'
        }}>
            <Table headers={header} rows={rows} />
        </div>
    );
}

export default HistoryRecordTable;