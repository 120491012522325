import React, {createContext, useContext, useState} from 'react';
import ProjectDetails from '../models/projectDetails/ProjectDetails';
import { UserRoleType } from '../types/UserRoleType';
import { initUserDetailsData } from '../data/default/userDetails.data';

export const ProjectDetailsContext = createContext();

export const ProjectDetailsProvider = (props) => {
    const [projectDetails, setProjectDetails] = useState(new ProjectDetails());
    const [userDetails, setUserDetails] = useState(initUserDetailsData);

    function updateUserDetails(userDetails) {

        if(!userDetails) {
            userDetails = initUserDetailsData;
        }

        setUserDetails({
            userName: userDetails.userName ? userDetails.userName : '',
            userRole: returnIfInUserRoleOrReturnDefault(userDetails.userRole),
        })
    }

    function returnIfInUserRoleOrReturnDefault(userRole) {

        if(!userRole || (
            userRole !== UserRoleType.ADMIN && 
            userRole !== UserRoleType.SECRETARY && 
            userRole !== UserRoleType.CITIZEN)
        ) {
            return UserRoleType.SECRETARY;
        }

        return userRole;
    }

    return (
        <ProjectDetailsContext.Provider value={{projectDetails, setProjectDetails, userDetails, updateUserDetails}}>
            {props.children}
        </ProjectDetailsContext.Provider>
    );
}

export const useProjectDetailsContext = () => useContext(ProjectDetailsContext);