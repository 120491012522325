import { AiOutlineAlert } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineErrorOutline } from "react-icons/md";

export default function getDefaultIconByClass(type) {
    
    if(type === 'secondary') return null;
    if(type === 'success') return <GrStatusGood size={27}/>;
    if(type === 'danger') return <MdOutlineErrorOutline size={27}/>;
    if(type === 'warning') return <AiOutlineAlert size={27}/>;
    if(type === 'info') return <BsInfoCircle size={27}/>;
    if(type === 'light') return null;
    if(type === 'dark') return null;

    return null;
}