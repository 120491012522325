import useAxiosPrivate from "../useAxiosPrivate";
import ResponseStatus from "../../types/ResponseStatus";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";


export const FORM_ACTION = {
    UPDATE_FIELD: 'update_field',
    UPDATE_REQUEST: 'UPDATE_REQUEST',
    UPDATE_OFF_SET: 'UPDATE_OFF_SET',
    UPDATE_FIELD_AND_OFFSET: 'update_field_and_offset',
    UPDATE_LIST: 'UPDATE_LIST',
    SWITCH_STATUS_REQUEST: 'SWITCH_STATUS_REQUEST'
}

export function dataReducer(state, action) {
    let from = {};

    switch (action.type) {
        case FORM_ACTION.UPDATE_FIELD_AND_OFFSET:

            from = { ...state.form, [action.payload.field]: action.payload.val, offset: 0 }
            return { ...state, form: from };
        case FORM_ACTION.UPDATE_FIELD:

            from = { ...state.form, [action.payload.field]: action.payload.val }
            return { ...state, form: from };
        case FORM_ACTION.UPDATE_OFF_SET:

            from = { ...state.form, 'offset': [action.payload.val] }
            return { ...state, form: from };
        case FORM_ACTION.UPDATE_LIST:

            return {
                ...state,
                'data': action.payload.data,
                'count': action.payload.count
            };

        case FORM_ACTION.UPDATE_REQUEST:

            const list = [...state.data];
            list[action.payload.index] = { ...action.payload.newRequest }

            return { ...state, data: list };
        case FORM_ACTION.SWITCH_STATUS_REQUEST:

            const rows = state.data;
            const index = rows.findIndex(row => row.request.id === action.payload.rowId);

            if (index < 0) return state;

            let req = { ...rows[index].request, status: action.payload.status };
            rows[index] = { ...rows[index], request: req }
            return { ...state, data: rows };
        default:
            return state;
    }
}

export const useGetPermitRequsets = () => {

    const axiosPrivate = useAxiosPrivate();


    async function getPermitsRequest(values, signal = undefined) {

        const controller = new AbortController();
        const withAbortSig = signal ? true : false;

        const result = {
            status: ResponseStatus.FAILURE,
            message: 'בעיה בשרת',
            data: {
                count: 0,
                requets: []
            }
        }

        const data = new FormData();
        data.append('pageNum', values.offset);
        data.append('itemPerPage', values.limit);
        data.append('from', values.fromDate);
        data.append('to', values.toDate);
        data.append('requestId', values.requestDBId);
        data.append('requestCreatedBy', values.requestCreatedBy);
        data.append('requestStatus', values.requestStatus);
        data.append('personId', values.personId);
        data.append('carNum', values.carNumber);
        data.append('dateTypeToSearchBy', values.dateTypeToSearchBy);

        try {

            if (!signal) {
                signal = controller.signal;
            }

            const response = await axiosPrivate.post(
                '/request/get-requests',
                data,
                { signal: signal }
            );

            const jsonData = response.data;

            if (jsonData['status'] === ResponseStatus.SUCCESS) {

                result.message = '';
                result.status = ResponseStatus.SUCCESS;
                result.data.count = jsonData.data.count;
                result.data.requets = jsonData.data.requets;
            }

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.status = ResponseStatus.FAILURE;

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                result.data.count = 0;
                result.data.requets = [];
                result.status = ResponseStatus.SUCCESS;
                result.message = '';
                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    async function updateStatus(requestId, newStatus) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: 'בעיה בשרת',
        }

        const form = new FormData();
        form.append('requestId', requestId);
        form.append('newStatus', newStatus);

        try {

            const response = await axiosPrivate.post('/request/update-status-request', form);
            const data = response.data;

            result.status = data.status;
            result.message = data.message;
        } catch (error) {

            if (error.message === 'Network Error') {

                result.message = 'בעיה כללית בשרת קוד: 502';
            }

            if (error?.response?.status === 401) {

                result.message = 'צריך להתחבר מחדש 401';
            }

            console.log('error: ' + error);
        }

        return result;
    }

    async function approveRequestByReqId(requestId) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: 'בעיה בשרת',
        }

        const data = new FormData();
        data.append('requestId', requestId);

        try {

            const response = await axiosPrivate.post(
                '/request/approving-request-by-reqId',
                data
            );

            const jsonData = response.data;

            if (jsonData['status'] !== ResponseStatus.SUCCESS) {
                return;
            }

            result.message = '';
            result.status = ResponseStatus.SUCCESS;

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.status = ResponseStatus.FAILURE;

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                result.data.count = 0;
                result.data.requets = [];
                result.status = ResponseStatus.SUCCESS;
                result.message = '';
                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    return { getPermitsRequest, updateStatus, approveRequestByReqId };
}