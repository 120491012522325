import React from 'react';
import RowPermitReport from './RowPermitReport';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import Flexbox from '../../../../Components/UI/Box/Flexbox';
import SimpleTable from '../../../../Components/UI/displayData/Table/SimpleTable';
import BtnExportCsvPermitReport from '../components/BtnExportCsvPermitReport';

const DisplayPermitReport = ({ dataState }) => {

    const {footer, permitReportRows} = dataState;
    const rows = permitReportRows.map(permitReportRow => <RowPermitReport data={permitReportRow} key={permitReportRow.index} />);
 
    return (
        <>
            <div className='row'>
                <div className='col'>
                    <Flexbox justifyContent='flex-end' marginLeft='1rem'>
                        <BtnExportCsvPermitReport rows={permitReportRows} footer={footer} />
                    </Flexbox>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <SimpleCard>
                        <SimpleTable
                            headers={
                                ['#', 'שם משתמש', 'מספר תווים', 'סכום תשלום', 'סוג תשלום', 'נוצר על ידי']
                            }
                            rows={rows}
                            footers={(
                                <tr className="table-primary">
                                    <th colSpan="2" scope="row">{footer[1]}</th>
                                    <th scope="row">{footer[2]}</th>
                                    <th scope="row">{footer[3]}</th>
                                    <th scope="row">{footer[4]} <br /> {footer[5]}</th>
                                    <th scope="row">{footer[6]} <br /> {footer[7]}</th>
                                </tr>
                            )} />
                    </SimpleCard>
                </div>
            </div>
        </>
    );
}

export default DisplayPermitReport;