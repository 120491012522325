import React from 'react';
import SearchBtnLoading from '../../../../Components/Buttons/SearchBtnLoading';
import { usePermitsListPageContext } from '../../../../contexts/permit/PermitsListPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import DateInput from '../../../../Components/Forms/DateInput';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import SelectInput from '../../../../Components/Forms/select/SelectInput';
import SelectStreet from '../../../../Components/Forms/select/SelectStreet';
import SelectZone from '../../../../Components/Forms/select/SelectZone';
import SelectPermitStatus from '../../../../Components/Forms/select/SelectPermitStatus';
import FormBox from '../../../../Components/Forms/FormBox';
import AdvancedSearchBtn from '../../../../Components/Buttons/AdvancedSearchBtn';

const FormFilterPermitManagement = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { values, handleSubmit, handleBlur, handleChange,
        componentState, setFieldValue, setFieldTouched, handleOnClickAdvSearch } = usePermitsListPageContext();

    const selectUserNames = [{ name: 'כל המשתמשים', value: '' }];
    projectDetails.listUserName.forEach(userName => {
        selectUserNames.push({ name: userName, value: userName });
    });

    const isAdvancedSearchOpen = values.isAdvancedSearchOpen;

    return (
        <FormBox>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-9">
                        <div className='row'>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'תעודת זהות'}
                                    name={"personId"}
                                    value={values.personId}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'מספר רכב'}
                                    name={"carNumber"}
                                    value={values.carNumber}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'מספר תו'}
                                    name={"permitId"}
                                    handleBlur={handleBlur}
                                    value={values.permitId}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <DateInput
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    label='תאריך התחלה'
                                    value={values.from}
                                    name={"from"}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <DateInput
                                    label={'תאריך סוף'}
                                    name={"to"}
                                    value={values.to}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-3 align-self-end">
                        <div className='d-flex justify-content-between'>
                            <AdvancedSearchBtn handleOnClickAdvSearch={handleOnClickAdvSearch} isAdvancedSearchOpen={values.isAdvancedSearchOpen} />
                            <SearchBtnLoading isLoading={componentState.isLoading} />
                        </div>
                    </div>
                </div>
                {isAdvancedSearchOpen ? (
                    <>
                        <div className="row justify-content-center">
                            <div className='col-10'>
                            <hr className="border border-primary border-1 opacity-10" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <SelectZone
                                    name='zone'
                                    value={values.zone}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <SelectPermitStatus
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.status}
                                    name={"status"}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <SelectInput
                                    name='loginUser'
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    value={values.loginUser}
                                    selects={selectUserNames}
                                    label='שם היוצר'

                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <SelectStreet
                                    name={'streetId'}
                                    value={values.streetId}
                                    handleChange={(option) => setFieldValue('streetId', option.value)}
                                    handleBlur={e => {
                                        handleBlur(e);
                                        setFieldTouched('streetId', true);
                                    }}
                                />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'מספר בית'}
                                    name={'houseNum'}
                                    handleBlur={handleBlur}
                                    value={values.houseNum}
                                    handleChange={handleChange} />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'מספר דירה'}
                                    name={'appatmentNum'}
                                    value={values.appatmentNum}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'שם פרטי'}
                                    name={'firstName'}
                                    value={values.firstName}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange} />
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                                <InputGroup
                                    label={'שם משפחה'}
                                    name={'lastName'}
                                    value={values.lastName}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange} />
                            </div>
                        </div>
                    </>
                ) : ''}

            </form>
        </FormBox>
    )
}

export default FormFilterPermitManagement
