import { decodeNCRStringIfNeeded } from '../../language';
import { convertPermitStatusCodeToText } from '../convertTypeCodeToText';
import { convertColorCodeToText, convertMakerCodeToText, convertStreetCodeToText } from '../convertorTablesCodeToText';

export function convPermitRowRawDataFromServer(data) {
    let rowTav = {};

    const startValidityLong = parseInt(data.startValidityDate, 10);
    const endValidityLong = parseInt(data.endValidityDate, 10);
    const statusCode = data.statusTav;
    const status = convertPermitStatusCodeToText(statusCode);

    rowTav['startDate'] = new Date(startValidityLong).toLocaleDateString();
    rowTav['endDate'] = new Date(endValidityLong).toLocaleDateString();

    rowTav['lName'] = decodeNCRStringIfNeeded(data.resident.lastName);
    rowTav['fName'] = decodeNCRStringIfNeeded(data.resident.firstName);
    rowTav['personId'] = data.resident.personId;
    rowTav['vehicleId'] = data.vehicle.vehicleId;
    rowTav['residentId'] = data.resident.residentId;
    rowTav['vehicleNum'] = data.vehicle.vehicleNum;
    rowTav['ownerId'] = data.ownerId;
    rowTav['id'] = data.id;
    rowTav['statusTav'] = status;
    rowTav['statusTavCode'] = statusCode;
    rowTav['paymentMethodCode'] = data.paymentMethod;
    rowTav['moneyAmount'] = data.payment;

    return rowTav;
}

export function convPermitRowRawDataFromServerCsv(data, colors, makers, street) {
   
    let rowTav = {};

    const statusCode = parseInt(data.statusTav);
    const colorCode = parseInt(data.vehicle.vehicleColor);
    const makerCode = parseInt(data.vehicle.vehicleMake);
    const streetCode = parseInt(data.resident.streetId);
    const startValidDateLong = parseInt(data.startValidityDate, 10);
    const endValidDateLong = parseInt(data.endValidityDate, 10);

    const colorName = convertColorCodeToText(colorCode, colors);
    const makerName = convertMakerCodeToText(makerCode, makers);
    const streetName = convertStreetCodeToText(streetCode, street);
   
    const status = convertPermitStatusCodeToText(statusCode);

    rowTav['permitId'] = data.id;
    rowTav['personId'] = data.resident.personId;
    rowTav['fName'] = decodeNCRStringIfNeeded(data.resident.lastName);
    rowTav['lName'] = decodeNCRStringIfNeeded(data.resident.firstName);
    rowTav['street'] = decodeNCRStringIfNeeded(streetName);
    rowTav['houseNum'] = data.resident.houseNum;
    rowTav['appatmentNum'] = data.resident.appatmentNum;
    rowTav['vehicleNum'] = data.vehicle.vehicleNum;

    rowTav['typeCode'] = '';
    rowTav['descType'] = '';
    rowTav['colorCode'] = colorCode;
    rowTav['color'] = decodeNCRStringIfNeeded(colorName);
    rowTav['makerCode'] = makerCode;
    rowTav['maker'] = decodeNCRStringIfNeeded(makerName);
    rowTav['zone'] = data.zone;
    rowTav['permitBy'] = '0';
    rowTav['streetCode'] = streetCode;
    rowTav['startDate'] = new Date(startValidDateLong).toLocaleDateString();
    rowTav['endDate'] = new Date(endValidDateLong).toLocaleDateString();

    rowTav['codeTypePermit'] = data.tavGilZahav;
    rowTav['codeResAppl'] = '';

    rowTav['mobile'] = data.resident.cellPhone;
    rowTav['commet'] = decodeNCRStringIfNeeded(data.resident.comments);
    
    return rowTav;
}