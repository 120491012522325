import React, {createContext, useContext, useReducer} from 'react';
import { initAuthData } from '../data/default/auth.data';
import { AUTH_ACTION, authReducer } from '../reducers/authReducer';


export const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [authState, authDispatch] = useReducer(authReducer, initAuthData);

    function logout() {
        authDispatch({type: AUTH_ACTION.LOGOUT});
    }

    function login(accessToken = '-1', refreshToken = '-1') {

        authDispatch({
            type: AUTH_ACTION.LOGIN,
            payload: {
                accessToken: accessToken,
                refreshToken: refreshToken,
            }
        });
    }

    function setAccessToken(accessToken = '-1') {
        
        authDispatch({
            type: AUTH_ACTION.SET_ACCESS_TOKEN,
            payload: {
                accessToken: accessToken,
            }
        });
    }

    function setTokens(accessToken = '-1', refreshToken = '-1') {
        
        authDispatch({
            type: AUTH_ACTION.SET_TOKENS,
            payload: {
                accessToken: accessToken,
                refreshToken: refreshToken,
            }
        });
    }

    return (
        <AuthContext.Provider value={{authState, logout, login, setAccessToken, setTokens}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export const useAuthContextContext = () => useContext(AuthContext);