import React from 'react';
import style from '../forms.module.css';

const CheckBtn = ({ value, id, name, onClick, onBlur, label, isChecked, disabled, inlineFilx }) => {

    const inlineFilxStyle = inlineFilx ? 'inline-flex' : 'flex'; 

    return (
        <div className={style.checkBoxForm} style={{display: inlineFilxStyle}}>
            <input
                className={style.formCheckInput}
                type="checkbox"
                value={value}
                id={id}
                name={name}
                disabled={disabled}
                onBlur={onBlur}
                checked={isChecked}
                onClick={(e) => onClick(e)}
                onChange={(e) => { }} 
            />
            <label className={style.formCheckLabel} htmlFor={id}>
                {label}
            </label>
        </div>
    );
}

CheckBtn.defaultProps = {

    label: '',
    value: '',
    disabled: false,
    isChecked: false,
    errorMsg: '',
    inlineFilx: false,
    onBlur: () => { },
    onClick: () => { }
}

export default CheckBtn