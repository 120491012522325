import moment from "moment";

export const APP_INFO_ACTION = {
    CHENGE_LANG: 'chengeLang',
    UPDATE_INIT_FORM: 'updateInitForm',
}

export const AppIngoReducer = (state, action) => {

    switch (action.type) {
        case APP_INFO_ACTION.CHENGE_LANG:

            return { ...state, password: action.payload.value };
        case APP_INFO_ACTION.UPDATE_INIT_FORM:

            return { ...state, 
                    formsInitData: {...state.formsInitData, 
                    [action.payload.formName]: {
                        ...state.formsInitData[action.payload.formName],
                        updateDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        currentForm: action.payload.formData
                    }
                } 
            };
        default:
            return state;
    }
} 