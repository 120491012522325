import React from 'react';
import {FcApproval, FcCancel} from 'react-icons/fc';
import LoadingDiv from '../../../../../Components/UI/loadingDiv/LoadingDiv';

const ComparisonDataWithFilesDisplay = ({comparisonData, isLoadingComparison}) => {

    return (
        <>
        {isLoadingComparison ? <LoadingDiv style={{width:'100%'}}/> : (
            <>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">שם שדה</th>
                            <th scope="col">טופס</th>
                            <th scope="col">תעודת זהות</th>
                            <th scope="col">רשיון רכב</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">שם פרטי</th>
                            <td>{comparisonData.fName}</td>
                            <td>{comparisonData.fNameAppearsInPersonId ? <FcApproval /> : <FcCancel />}</td>
                            <td>{comparisonData.fNameAppearsInCarId ? <FcApproval /> : <FcCancel />}</td>
                        </tr>
                        <tr>
                            <th scope="row">שם משפחה</th>
                            <td>{comparisonData.lName}</td>
                            <td>{comparisonData.lNameAppearsInPersonId ? <FcApproval /> : <FcCancel />}</td>
                            <td>{comparisonData.lNameAppearsInCarId ? <FcApproval /> : <FcCancel />}</td>
                        </tr>
                        <tr>
                            <th scope="row">מספר זהות</th>
                            <td>{comparisonData.personId}</td>
                            <td>{comparisonData.personIdNumAppearsInPersonIdCard ? <FcApproval /> : <FcCancel />}</td>
                            <td>{comparisonData.personIdNumAppearsInCarId ? <FcApproval /> : <FcCancel />}</td>
                        </tr>
                        <tr>
                            <th scope="row">מספר רכב</th>
                            <td>{comparisonData.carNum}</td>
                            <td>-</td>
                            <td>{comparisonData.carNumAppearsInCarId ? <FcApproval /> : <FcCancel />}</td>
                        </tr>
                        <tr>
                            <th scope="row">ערי/ישוב מגורים</th>
                            <td>-</td>
                            <td>{comparisonData.projectNInAdditionalPerIdFile ? <FcApproval /> : <FcCancel />}</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )}
        </>
    )
}

export default ComparisonDataWithFilesDisplay