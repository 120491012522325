import React from 'react';
import RequestDetailsForm from './components/RequestDetailsForm';
import { ConfirmModal, CloseModalBtn, GeneralModal as SuccessModal } from '../../../Components/GeneralModal/modal.import';
import HistoryModal from '../../../features/History/HistoryModal';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilesModal from './components/requestFilesModal/FilesModal';
import SendEmailModal from '../../../features/sendEmail/SendEmailModal';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import { useRequestManagmentPageContext } from '../../../contexts/request/RequestDetailsPageContext';
import ButtonsContainer from './components/ButtonsContainer';
import Alert from '../../../Components/Alerts/Alert';
import { convertCodeStatusRequestToText } from '../../../Helpers/conversion/convertTypeCodeToText';
import AlertExistingPermit from './components/alertsRequestPage/AlertExistingPermit';
import AlertExitingPermitWithSomePersonId from './components/alertsRequestPage/AlertExitingPermitWithSomePersonId';

const RequestDetailsPage = () => {

    const { handleApprove, historyModalState, historyModalStateDispatch, closeHistoryModal,
        successModalIsShown, confirmModalIsShown, setConfirmModalIsShown, handleCloseModal,
        componentState, values, isFileModalOpen, refSendEmailModal } = useRequestManagmentPageContext();

    let pageTitle = 'בקשה: ' + convertCodeStatusRequestToText(values.status);

    return (
        <>

            <SendEmailModal ref={refSendEmailModal} />

            <FilesModal isShow={isFileModalOpen} />

            <HistoryModal closeHistoryModal={closeHistoryModal}
                historyModalState={historyModalState} historyModalStateDispatch={historyModalStateDispatch} />

            <ConfirmModal
                title={'אישור סופי של הבקשה'}
                content={
                    <div style={{ padding: '0.5rem', fontSize: '1.4rem' }}>
                        <p>
                            לאחר אישור הבקשה תופנה לדף מעקב בקשות.
                        </p>
                    </div>
                }
                footer={<CloseModalBtn />}
                isShow={confirmModalIsShown}
                confirmTitle={'אישור בקשה'}
                closingFun={() => setConfirmModalIsShown(false)}
                rejsctFunc={() => setConfirmModalIsShown(false)}
                fonfimFunc={() => handleApprove()} />

            <SuccessModal
                isShow={successModalIsShown}
                title={'בקשה נשמרה בהצלחה!'}
                content={
                    <div style={{ padding: '0.5rem', fontSize: '1.4rem' }}>
                        <p>לאחר שמירת הבקשה תופנה לדף מעקב בקשות.</p>
                    </div>
                }
                footer={<CloseModalBtn onClick={handleCloseModal} label='סגור' />}
                closingFun={handleCloseModal}
            />

            {componentState.isLoading ? <LoadingModal /> : ''}

            <AlertExistingPermit />

            <AlertExitingPermitWithSomePersonId />

            <div className='row mt-1'>
                <div className='col-12'>
                    <Alert isShow={componentState.hasError} message={componentState.errorMessage} />
                </div>
            </div>

            <div className='row mt-1'>
                <div className='col-12'>
                    <PageTitle title={pageTitle} />
                </div>
            </div>

            <div className='row mt-3 mb-4'>
                <div className='col-12'>
                    <ButtonsContainer />
                </div>
            </div>

            <div className='row mt-2 mb-4'>
                <div className='col-12'>
                    <RequestDetailsForm />
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-12'>
                    <ButtonsContainer />
                </div>
            </div>
        </>
    );
}

export default RequestDetailsPage;