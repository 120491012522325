import React from "react";
import BtnBox from "../UI/BtnBox/BtnBox";
import GeneralModal from "./GeneralModal";
import ContinueModalBtn from "./ContinueModalBtn";
import CloseModalBtn from "./CloseModalBtn";

const ConfirmModal = ({ title, content, confirmTitle, closingFun, rejsctFunc, fonfimFunc, isShow }) => {

    if (!isShow) {
        return '';
    }

    return (
        <GeneralModal
            isShow={isShow}
            title={title}
            content={content}
            closingFun={closingFun}
            footer={(
                <BtnBox>
                    <ContinueModalBtn onClick={fonfimFunc} label={confirmTitle} />
                    <CloseModalBtn onClick={rejsctFunc} />
                </BtnBox>
            )} />
    );
}

ConfirmModal.defaultProps = {
    confirmTitle: 'אישור'
};

export default ConfirmModal