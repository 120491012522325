import useAxiosPrivate from "../useAxiosPrivate";
import ResponseStatus from "../../types/ResponseStatus";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";


export const useGetPermits = () => {

    const axiosPrivate = useAxiosPrivate();

    async function updateStatusPermit(permitId, newStatus) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: '',
        };

        const form = new FormData();
        form.append("permitId", permitId);
        form.append("newStatus", newStatus);

        try {

            const response = await axiosPrivate.post(
                '/permit/update-permit-status', form);

            const jsonData = response.data;

            if(jsonData.status === ResponseStatus.SUCCESS) {
                
                result.status = ResponseStatus.SUCCESS;
                result.message = 'success';
                return result;
            }

            if(jsonData?.message) {

                result.message = jsonData.message;
            } else {

                result.message = 'לא התקבל אישור לשינוי סטטוס התו.';
            }

        } catch (error) {

            result.message = 'בעיה בשרת';

            if (error.message === 'Network Error') {

                result.message = 'בעיה כללית בשרת קוד: 502';
            }
        }

        return result;
    }

    async function getPermits(values, offset, itemPerPage, signal = undefined) {

        const controller = new AbortController();
        const withAbortSig = signal ? true : false;

        if (!withAbortSig) {
            signal = controller.signal;
        }

        const result = {
            status: ResponseStatus.FAILURE,
            message: '',
            data: {
                permits: [],
                count: 0
            }
        };

        const data = new FormData();
        data.append('pageNum', offset);
        data.append('itemPerPage', itemPerPage);
        data.append('from', values.from);
        data.append('to', values.to);
        data.append('permitId', values.permitId);
        data.append('loginUser', values.loginUser);
        data.append('status', values.status);
        data.append('personId', values.personId);
        data.append('carNumber', values.carNumber);
        data.append('zone', values.zone);
        data.append('streetId', values.streetId);
        data.append('houseNum', values.houseNum);
        data.append('houseNumTo', values.houseNumTo);
        data.append('appatmentNum', values.appatmentNum);
        data.append('firstName', values.firstName);
        data.append('lastName', values.lastName);


        try {

            const response = await axiosPrivate.post(
                '/permit/get-permits',
                data,
                { signal: signal });

            const jsonData = response.data;

            if (jsonData.data.permits !== undefined && jsonData.data.count !== undefined) {

                result.data.permits = jsonData.data.permits;
                result.data.count = jsonData.data.count;
                result.status = ResponseStatus.SUCCESS;
            }
        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.status = ResponseStatus.FAILURE;
      
            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                result.data.count = 0;
                result.data.permits = [];
                result.status = ResponseStatus.SUCCESS;
                result.message = '';
                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    return [getPermits, updateStatusPermit];
}
