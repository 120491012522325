import React from 'react';
import SelectInput from '../../../../Components/Forms/select/SelectInput';

const SelectStatusRequest = ({ name, value, handleChange, handleBlur }) => {

    const selects = [
        { 'value': '-1', 'name': 'כל הבקשות' },
        { 'value': '1', 'name': 'ממתינים לאישור' },
        { 'value': '2', 'name': 'בקשות שאושרו' },
        { 'value': '3', 'name': 'בקשות שנדחו' }
    ]

    const nameTag = 'סטטוס בקשה';

    return (
        <SelectInput
            label={nameTag}
            name={name}
            value={value}
            selects={selects}
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
    )
}

/*
<div className="form-group">
        <label className="form-label" htmlFor={props.inputName}>{nameTag}</label>
        <select
            className="form-select"
            id={props.inputName} 
            name={props.inputName}
            value={props.initState}
            onChange={handelOnChange}>
                {selects}
        </select>
    </div>
*/

export default SelectStatusRequest