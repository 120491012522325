import React, {useState} from 'react';
import Style from './Card.module.css';
import SimpleCardHeader from './SimpleCardHeader';

const SimpleCard = ({title, children, isOpen, withHeader, withClosingBtn, style}) => {

    const [showContent, setShowContent] = useState(isOpen);
    let bodyClasses = [showContent ? Style.simpleBodyShow :  Style.simpleBodyNotShow];
    let header = '';

    if(title != undefined){
        header = <SimpleCardHeader withClosingBtn={withClosingBtn} title={title} showContent={showContent} setShowContent={setShowContent}/>
    }
    
    const card = (
        <div style={style} className={Style.simpleCard}>
            {header}
            <div className={bodyClasses}> {children} </div>
        </div>
    );

    return card;
}

SimpleCard.defaultProps = {
    title: undefined,
    isOpen: true,
    withHeader: false,
    withClosingBtn: false,
    style: {},
}

export default SimpleCard