import React from 'react';
import SelectInput from '../../../../Components/Forms/select/SelectInput';
import InputTextArea from '../../../../Components/Forms/InputTextArea';
import SelectPermitType from '../../../../Components/Forms/select/SelectPermitType';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import SelectZone from '../../../../Components/Forms/select/SelectZone';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitGeneralDetails = () => {
    const { values, handleChange, handleBlur } = usePermitDetailsPageContext();
    const generalTitle = 'פרטים כללים';

    let selects = [
        { key: 1, value: 1, name: 'פעיל' },
        { key: 2, value: 2, name: 'לא פעיל' },
        { key: 3, value: 3, name: 'מושהה' },
        { key: 4, value: 4, name: 'מבוטל' }
    ];


    return (
        <FormSection title={generalTitle}>
            <div className="row">
                <div className='col-lg-6 col-xl-6'>
                    <InputTextArea
                        label='הערה'
                        name='comments'
                        value={values.comments}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        rows={4}
                    />
                </div>

                <div className='col-lg-6 col-xl-6'>
                    <div className="row">
                        <div className='col'>
                            <SelectPermitType
                                name={'permitType'}
                                value={values.permitType}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-lg-12 col-xl-6'>
                            <SelectInput
                                handleBlur={handleBlur}
                                name='statusTav'
                                label='סטטוס'
                                handleChange={handleChange}
                                selects={selects}
                                value={values.statusTav}
                                isRequired={true}
                                errorMsg={undefined}
                            />
                        </div>
                        <div className='col-lg-12 col-xl-6'>
                            <SelectZone
                                name={'zone'}
                                value={values.zone}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                isRequired={true}
                                errorMsg={undefined}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FormSection>
    );
}

export default PermitGeneralDetails;