import React from 'react'
import Flexbox from '../../../../Components/UI/Box/Flexbox';
import ExportToCsvButton from '../../../../Components/Buttons/ExportToCsvButton';

const BtnsRow = ({ list, header }) => {

    if (list.length <= 0) {
        return '';
    }

    return (
        <div className='row'>
            <div className='col'>
                <Flexbox justifyContent='flex-end' marginLeft='1rem'>
                    <ExportToCsvButton data={list} headers={header} fileName='expired permit'/>
                </Flexbox>
            </div>
        </div>
    )
}

export default BtnsRow;