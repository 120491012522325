import React from 'react'
import { TfiSave } from 'react-icons/tfi';
import Button from './Button';

const SaveBtn = ({ onClick, label, color, disabled }) => {
  return (
    <Button
      disabled={disabled}
      theme={color}
      onClick={onClick}
      endIcon={<TfiSave />}>
      {label}
    </Button>
  );
}

SaveBtn.defaultProps = {
  disabled: false,
  color: "primary",
  label: "שמור",
  onClick: () => { }
};

export default SaveBtn