import moment from "moment";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { useState } from "react";
import ResponseStatus from "../../types/ResponseStatus";
import { CALCULATE_END_DATE } from "../../api/calculateEndDate.api";

const useEndDateDefualtValInput = () => {

    const axiosPrivate = useAxiosPrivate();
    const [stateData, setStateDate] = useState({
        isLoading: false,
        isFail: false,
        msg: ''
    });

    async function calcDefualtDate(permitType){

        const body = {
            "permitType": permitType,
            "carNum": '',
            "personId": '',
        };

        try{
            
            setStateDate({
                isLoading: true,
                isFail: false,
                msg: 'loading end date'
            });

            const response = await axiosPrivate.post(CALCULATE_END_DATE, body);
    
            const data = response.data;

            if(data == null || data.data == null) {
                throw new Error('no data from API');
            }

            if(data.status !== ResponseStatus.SUCCESS) {
                const errorMsg = data?.message ? data.message : 'status from API is: ' + data.status;
                throw new Error(errorMsg);
            } 

            setStateDate({
                isLoading: false,
                isFail: false,
                msg: 'loaded end date'
            });
            
    
            return data.data?.endDate ? data.data.endDate : '';
        
        }catch(error) {

            console.log(error)
            setStateDate({
                isLoading: false,
                isFail: true,
                msg: error
            })
            return '';
        }

    }

    return {calcDefualtDate, isLoading: stateData.isLoading, isFail: stateData.isFail, msg: stateData.msg};
}

export default useEndDateDefualtValInput;