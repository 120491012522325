import React from 'react'
import RedStar from './RedStar';

const DateInput = ({ label, value, name, handleChange, handleBlur, errorMsg, isRequired, disabled }) => {
    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if (hasError) { 
        classes += ' is-invalid'; 
    }

    return (
        <>
            <label className="form-label">
                {label}
                {isRequired ? <RedStar /> : ''}
            </label>
            <input 
                type='date'
                className={classes}
                name={name}
                value={value}
                onChange={(event) => handleChange(event)}
                onBlur={event => handleBlur(event)}
                disabled={disabled} />
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

DateInput.defaultProps = {

    value: '',
    isRequired: false,
    disabled: false,
    errorMsg: '',
    handleChange: () => { },
    handleBlur: () => { },
    updateIsTouch: () => { }
}

export default DateInput