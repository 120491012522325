import React from 'react'
import DisplayManagmentRequestTavim from './components/DisplayManagmentRequestTavim';
import FormFilterManagmentRequestTavim from './components/FormFilterManagmentRequestTavim';
import HistoryModal from '../../../features/History/HistoryModal';
import { useRequestManagmentPageContext } from '../../../contexts/request/RequestManagmentPageContext';
import SendSmsModal from '../../../features/sendSms/SendSmsModal';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import Alert from '../../../Components/Alerts/Alert';
import ConfirmModal from '../../../Components/GeneralModal/ConfirmModal';
import CloseModalBtn from '../../../Components/GeneralModal/CloseModalBtn';

const containerStyle = { display: 'flex', flexDirection: 'column', rowGap: '2rem' };

const RequestManagementPage = () => {

    const { componentState, sendSmsModalData, closeSmsModal, updateRequestSms, 
        historyModalState, historyModalStateDispatch, 
        closeHistoryModal,
        closeModalPreApproveReq, approveReq, confirmModalIsShown } = useRequestManagmentPageContext();


    return (
        <>
            <ConfirmModal
                title={'אישור סופי של הבקשה'}
                content={
                    <div style={{ padding: '0.5rem', fontSize: '1.4rem' }}>
                        <p>
                            לאישור סופי של הבקשה.
                        </p>
                    </div>
                }
                footer={<CloseModalBtn />}
                isShow={confirmModalIsShown.isOpen}
                confirmTitle={'אישור בקשה'}
                closingFun={closeModalPreApproveReq}
                rejsctFunc={closeModalPreApproveReq}
                fonfimFunc={() => approveReq()} /> 
    
            <HistoryModal historyModalState={historyModalState} closeHistoryModal={closeHistoryModal}
                historyModalStateDispatch={historyModalStateDispatch} />
          
            <SendSmsModal isShow={sendSmsModalData.isOpen} closingFun={closeSmsModal}
                request={sendSmsModalData.request} index={sendSmsModalData.index} updateRequest={updateRequestSms} />
        
            <div style={containerStyle}>
                <Alert isShow={componentState.hasError} message={componentState.errorMessage} type={'danger'} />
                <PageTitle title={'ניהול בקשות'} />
                <FormFilterManagmentRequestTavim />
                <DisplayManagmentRequestTavim />
            </div>
        </>
    );
}

export default RequestManagementPage;