import React from 'react';
import InputGroup from '../../../../Components/Forms/InputGroup';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitDetails = () => {
    const { values } = usePermitDetailsPageContext();
    const title = 'פרטי התו';

    return (
        <FormSection title={title}>
            <div className="row ">
                <div className='col-lg-4 col-xl-3'>
                    <InputGroup
                        value={values.permitId}
                        disabled={true}
                        label={'מספר תו'}
                        name={'permitId'}
                    />
                </div>

                <div className='col-lg-6 col-xl-3'>
                    <InputGroup
                        value={values.loginUser}
                        disabled={true}
                        label={'אושר על ידי משתמש'}
                        name={'loginUser'}
                    />
                </div>
                <div className='col-lg-6 col-xl-3'>
                    <InputGroup
                        value={values.createdBy}
                        disabled={true}
                        label={'נוצר על ידי'}
                        name={'createdBy'}
                    />
                </div>
            </div>
            <div className="row ">
                <div className='col-lg-6 col-xl-3'>
                    <InputGroup
                        value={values.issueDate}
                        name={'issueDate'}
                        isValid={true}
                        label={'תאריך יצירת התו'}
                        disabled={true}
                    />
                </div>
                <div className='col-lg-6 col-xl-3'>
                    <InputGroup
                        value={values.updateDate}
                        name={'issueDate'}
                        isValid={true}
                        label={'תאריך עידכון אחרון'}
                        disabled={true}
                    />
                </div>
            </div>
        </FormSection>
    );
}

export default PermitDetails