import React from 'react';
import style from './paging.module.css';

const PageBtn = ({ onClick, page }) => {

    return (
        <span className={style.span} onClick={onClick}>
            {page}
        </span>
    );
}

export default PageBtn