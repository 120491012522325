import React, { useContext } from 'react';
import style from './Navbar.module.css';
import UiList from './UiList';
import { ProjectDetailsContext } from "../../../contexts/ProjectDetailsContext";
import UserDetailsBox from './UserDetailsBox';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const Navbar = () => {

    const { projectDetails } = useContext(ProjectDetailsContext);
    const navClassStyle = "navbar navbar-expand-sm navbar-dark bg-primary " + style.navbar;
    const classesNavBarnd = 'navbar-brand ' + style.brandNavbar;

    return (
        <nav className={navClassStyle}>
            <div className="container-fluid">
                <div className={classesNavBarnd}>
                    <div>תווי דייר</div>
                    <div>{decodeNCRStringIfNeeded(projectDetails.projectName)}</div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <UiList />
                <UserDetailsBox />
            </div>
        </nav>
    );


}

export default Navbar;