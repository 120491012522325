import React from 'react';
import DisplayHistoryInput from './DisplayHistoryInput';
import InputCard from './InputCard';

const DisplayPermitHistory = ({ historyRecord }) => {

    return (
        <div className='col'>
            <InputCard>
                <DisplayHistoryInput
                    inputName='dateTime' value={historyRecord.userName}
                    labalName='שם משתמש' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='requestType' value={historyRecord.requestType}
                    labalName='סוג תו' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='permitStatus' value={historyRecord.permitStatus}
                    labalName='סטטוס תו' colSize='col-4' />
            </InputCard>
            <InputCard>
                <DisplayHistoryInput
                    inputName='fName' value={historyRecord.fName}
                    labalName='שם פרטי' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='lName' value={historyRecord.lName}
                    labalName='שם משפחה' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='personId' value={historyRecord.personId}
                    labalName='תעודת זהות' colSize='col-4' />
            </InputCard>
            <InputCard>
                <DisplayHistoryInput
                    inputName='email' value={historyRecord.email}
                    labalName='אימייל' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='mobile' value={historyRecord.mobile}
                    labalName='סלולרי' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='phone' value={historyRecord.phone}
                    labalName='טלפון' colSize='col-4' />

                <DisplayHistoryInput
                    inputName='streetName' value={historyRecord.streetName}
                    labalName='רחוב' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='house' value={historyRecord.house}
                    labalName='בית' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='appartment' value={historyRecord.appartment}
                    labalName='דירה' colSize='col-4' />
            </InputCard>
            <InputCard>
                <DisplayHistoryInput
                    inputName='email' value={historyRecord.vehicleNum}
                    labalName='מספר רכב' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='makerText' value={historyRecord.makerText}
                    labalName='סוג רכב' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='colorText' value={historyRecord.colorText}
                    labalName='צבע רכב' colSize='col-4' />
            </InputCard>

            <InputCard>
                <DisplayHistoryInput
                    inputName='approvalDate' value={historyRecord.approvalDate}
                    labalName='תאריך אישור' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='startValidityDate' value={historyRecord.startValidityDate}
                    labalName='תחילת תוקף' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='endValidityDate' value={historyRecord.endValidityDate}
                    labalName='סוף תוקף' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='createdBy' value={historyRecord.createdBy}
                    labalName='נוצר על ידי' colSize='col-4' />
            </InputCard>

        </div>
    );
}

export default DisplayPermitHistory