import React from "react";
import InputGroup from "../../../Components/Forms/InputGroup";
import style from '../LoginPage.module.css';
import Button from "../../../Components/Buttons/Button";
import { SlLogin } from "react-icons/sl";

const FormLogin = ({ handleChange, errors, values, touched,
    handleSubmit, handleBlur, componentState }) => {

    let isdisable = false;
    const isLoading = componentState.isLoading;

    if (isLoading) {

        isdisable = true;
    }

    return (
        <form onSubmit={handleSubmit} className={style.form}>

            <div className="row">
                <div className="col-12">
                    <InputGroup
                        styleInput={{ fontSize: '1.5rem' }}
                        styleLabel={{ fontSize: '1.5rem' }}
                        label={'שם משתמש'}
                        name={'userName'}
                        value={values.userName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(touched.userName && errors.userName) ? errors.userName : undefined}
                    />
                </div>

                <div className="col-12">
                    <InputGroup
                        styleInput={{ fontSize: '1.5rem' }}
                        styleLabel={{ fontSize: '1.5rem' }}
                        label={'סיסמה'}
                        name={'password'}
                        type='password'
                        value={values.password}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(touched.password && errors.password) ? errors.password : undefined}
                    />
                </div>

                <div className="col-12">
                    <InputGroup
                        styleInput={{ fontSize: '1.5rem' }}
                        styleLabel={{ fontSize: '1.5rem' }}
                        label={'מספר רשות'}
                        name={'projectId'}
                        value={values.projectId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(touched.projectId && errors.projectId) ? errors.projectId : undefined}
                    />
                </div>

            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <Button size={'big'} type='submit' text='התחבר' disabled={isdisable} endIcon={<SlLogin />}/>

                </div>
            </div>

        </form>
    );
}
//                     <button type="submit" className="btn btn-primary mt-3" disabled={isdisable}>התחבר</button>
export default FormLogin
