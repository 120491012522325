import React from 'react';
import style from './BtnBox.module.css';

const BtnBox = (props) => {

    let classes = style.myBtnTableCellBox;

    if(props.stickDown) classes += ' ' + style.stickDown
    
    if(props.expend) classes += ' ' + style.expend

    const box = (
        <div className={classes}>
            {props.children}
        </div>
    );

    return box;
}

BtnBox.defaultProps = {
    stickDown:false,
    expend:false
}

export default BtnBox;