import React from 'react';
import { Link } from 'react-router-dom';

const DropdownItem = ({link, setActiveNav, nameTag, activeNavId}) => {

    return (
        <li>
            <Link className='dropdown-item' aria-current="page" to={link} onClick={handleOnClick}>{nameTag}</Link>
        </li>
    );

    function handleOnClick() {
        setActiveNav(activeNavId);
    }
}

export default DropdownItem;