import { useState } from "react";
import { GET_PERMIT_HISTORY_RECORDS, GET_REQUEST_HISTORY_RECORDS } from "../../api/historyApi";
import ResponseStatus from "../../types/ResponseStatus";
import { HISTORY_MODAL_ACTION } from "../../reducers/historyModalReducer";
import useAxiosPrivate from "../useAxiosPrivate";

const GENERAL_ERROR_MESSAGE = 'בעיה להביא את הנתונים';

function useHistoryModal(historyModalState, historyModalStateDispatch) {

    const axiosPrivate = useAxiosPrivate();
    function goToViewHistoryRecord(recordIndex) {

        let record = {};

        if (historyModalState.recordList.length >= recordIndex) {

            record = historyModalState.recordList[recordIndex];
        }

        historyModalStateDispatch({
            type: HISTORY_MODAL_ACTION.GO_TO_VIEW_RECORD,
            payload: { currentRecord: record }
        });
    }

    function goToHistoryRecordList() {

        historyModalStateDispatch({
            type: HISTORY_MODAL_ACTION.GO_TO_RECORD_LIST
        });
    }

    function updateIdentifier(identifier) {

        historyModalStateDispatch({
            type: HISTORY_MODAL_ACTION.UPDATE_IDENTIFIER,
            action: { identifier: identifier }
        });
    }

    function updateIsRequest(isRequest) {

        historyModalStateDispatch({
            type: HISTORY_MODAL_ACTION.UPDATE_IS_REQUEST,
            action: { isRequest: isRequest }
        });
    }

    function setIsModalLoading() {

        historyModalStateDispatch({ type: HISTORY_MODAL_ACTION.IS_LOADING });
    }

    function setIsNotModalLoading(msg = '') {

        historyModalStateDispatch({ type: HISTORY_MODAL_ACTION.IS_NOT_LOADING, action: { msg: msg } });
    }

    async function getHistory() {

        setIsModalLoading();
        let msg = '';
        let func = historyModalState.isRequest ? GET_REQUEST_HISTORY_RECORDS : GET_PERMIT_HISTORY_RECORDS;
        
        try {

            const response = await axiosPrivate.get(`${func}/${historyModalState.identifier}`);
            const data = response.data.data;

            if (data.status === ResponseStatus.FAILURE) {

                setIsNotModalLoading(GENERAL_ERROR_MESSAGE);
                return;
            }

            historyModalStateDispatch({
                type: HISTORY_MODAL_ACTION.SET_HISTORY_LIST,
                historyList: data.historyRecords
            });

        } catch (error) {

            console.log(error);
            setIsNotModalLoading(GENERAL_ERROR_MESSAGE);
            return {
                status: ResponseStatus.FAILURE,
                msg: '',
                historyRecords: []
            };
        }
    }

    return {
        historyModalState, updateIdentifier,
        updateIsRequest, getHistory,
        goToHistoryRecordList, goToViewHistoryRecord, setIsModalLoading
    };
}

export default useHistoryModal;