export const FORM_ACTION = {
    UPDATE_OFFSET: 'UPDATE_OFFSET',
    UPDATE_FORM_FIELD: 'UPDATE_FORM_FIELD',
    UPDATE_PRMIT_LIST: 'UPDATE_PRMIT_LIST',
    UPDATE_COUNT: 'UPDATE_COUNT'
}

export const listPermitsReducer = (state, action) => {
    
    let newForm = {};

    switch (action.type) {
        case FORM_ACTION.UPDATE_FORM_FIELD:
            newForm = {...state.form, [action.payload.field]:[action.payload.val], offset:0};

            return {...state, form:newForm};
        case FORM_ACTION.UPDATE_OFFSET:
            newForm = {...state.form, offset:action.payload.val};

            return {...state, form:newForm};
        case FORM_ACTION.UPDATE_PRMIT_LIST:
            
            return {...state, list:action.payload.list, count:action.payload.count};
        case FORM_ACTION.UPDATE_COUNT:
            
            return {...state, count:action.payload.count};          
        default:
            return state;
    }
} 
