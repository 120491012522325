import React, { useMemo } from 'react';
import { usePaymentsReportPageContext } from '../../../../contexts/reports/PaymentsReportPageContext';
import { formaterMoneyAmount } from '../../../../Helpers/format/numberFormat';

const TableDistribution = () => {

    const { pRecordsChartArea } = usePaymentsReportPageContext();

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">תקופה</th>
                    <th scope="col">אשראי</th>
                    <th scope="col">מס' תשלומים(אשראי)</th>
                    <th scope="col">מזומן</th>
                    <th scope="col">מס' תשלומים(מזומן)</th>
                </tr>
            </thead>
            <tbody>
            {pRecordsChartArea.map((row, index) => {
                return (
                    <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{row.name}</td>
                        <td>{formaterMoneyAmount(row.creditCardAmount)}</td>
                        <td>{row.creditCardNumberOfUnits}</td>
                        <td>{formaterMoneyAmount(row.cashAmount)}</td>
                        <td>{row.cashNumberOfUnits}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}

export default TableDistribution;