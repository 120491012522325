import { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useFormik } from "formik";
import PermitManagementPage from "../../Pages/Permits/managmentPermits/PermitManagementPage";
import { useGetPermits } from "../../Hooks/permits/useGetPermits";
import ResponseStatus from "../../types/ResponseStatus";
import { FORM_ACTION, listPermitsReducer } from "../../reducers/permits/listPermitsReducer";
import { useAppInfoContext } from "../AppInfoContext";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import { convPermitRowRawDataFromServerCsv } from "../../Helpers/conversion/permit/convPermitRowRawDataFromServer";
import usePublicHistoryModal from "../../Hooks/history/usePublicHistoryModal";
import { initSearchPermitFormData } from "../../data/default/searchPermitForm.data";
import { useComponentState } from "../../Hooks/useComponentState";


export const PermitsListPageContext = createContext();

const PermitsListPageProvider = () => {

    const initFormName = 'permit';
    const { projectDetails } = useProjectDetailsContext();
    const { componentState, setIsLoading, setIsNotLoading, setDangerAlert, removeAlert } = useComponentState();
    const { updateInitForm, getCurrentInitForm } = useAppInfoContext();
    const { historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal } = usePublicHistoryModal();
    const [getPermitsState, getPermitsDispatch] = useReducer(listPermitsReducer, { list: [], count: 0, });
    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
    const [permitToEditStatus, setPermitToEditStatus] = useState(null);
    const [getPermits, updateStatusPermit] = useGetPermits();

    const streetsMap = projectDetails.streets.getAllStreets();
    const typesMap = projectDetails.types.getAllMakers();
    const colorsMap = projectDetails.colors.getAllColors();

    const { values, handleSubmit, handleBlur, setFieldValue, setFieldTouched } = useFormik({
        initialValues: { ...getCurrentInitForm(initFormName, {}) },
        onSubmit: values => {
            setFieldValue('offset', 0);
            handlerSubmit();
        }
    });

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;
        //handlerGetInitRequests(values, signal);
        handlerGetInitPermit(signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    return (
        <PermitsListPageContext.Provider value={{
            values, handleSubmit, handleBlur, handleChange, setFieldValue, handlerUpdatePermitStatus,
            componentState, setFieldTouched,
            historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal,
            handlerSubmit, getPermitsState, changePage,
            showChangeStatusModal, closeChangeStatusModal, openChangeStatusModal,
            permitToEditStatus, setPermitToEditStatus, getRowForCsv, handleOnClickAdvSearch
        }}>
            <PermitManagementPage />
        </PermitsListPageContext.Provider>
    );

    async function getRowForCsv() {

        setIsLoading();
        const response = await getPermits(values, 0, -1);
        let convertedList = [];

        if(response.status === ResponseStatus.FAILURE) {
            
            setDangerAlert(response.message);        
            return [];
        }
                
        const list = response.data.permits;

        if(list && list.length > 0) {

            convertedList = list.map((permit, index) => convPermitRowRawDataFromServerCsv(permit, 
                colorsMap, typesMap, streetsMap));
        }

        setIsNotLoading()
        return convertedList;
    }

    function changePage(offset) {

        setFieldValue('offset', offset);
        const valuesTemp = { ...values, offset: offset };
        updateInitForm(initFormName, valuesTemp);
        handlerSubmit(offset);
    }

    function closeChangeStatusModal() {
        setShowChangeStatusModal(false);
    }

    function openChangeStatusModal(permit) {

        setPermitToEditStatus(permit);
        setShowChangeStatusModal(true);
    }

    async function handlerGetInitPermit(signal) {

        setIsLoading();

        updateInitForm(initFormName, values);

        const response = await getPermits(values, 0, values.limit, signal);

        if (response.status === ResponseStatus.FAILURE) {
    
            setDangerAlert(response.message);
            return;
        }

        const list = response.data.permits;
        const count = response.data.count;

        getPermitsDispatch({
            type: FORM_ACTION.UPDATE_PRMIT_LIST,
            payload: { count: count, list: list }
        });

        setIsNotLoading();
    }

    async function handlerSubmit(offset = 0) {

        setIsLoading();

        updateInitForm(initFormName, values);

        const response = await getPermits(values, offset, values.limit);

        if (response.status === ResponseStatus.FAILURE) {
  
            setDangerAlert(response.message);
            return;
        }

        const list = response.data.permits;
        const count = response.data.count;

        getPermitsDispatch({
            type: FORM_ACTION.UPDATE_PRMIT_LIST,
            payload: { count: count, list: list }
        });

        setIsNotLoading();
    }

    async function handlerUpdatePermitStatus(status = -1, permitId = -1) {

        closeChangeStatusModal();
        setIsLoading();

        const response = await updateStatusPermit(permitId, status);

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        handleSubmit();
        setIsNotLoading();
    }

    function handleOnClickAdvSearch(event) {

        event.preventDefault();
        const isAdvancedSearchOpen = !values.isAdvancedSearchOpen;
        setFieldValue('isAdvancedSearchOpen', isAdvancedSearchOpen);

        if(!isAdvancedSearchOpen) {

            setFieldValue('zone', initSearchPermitFormData.zone);
            setFieldValue('status', initSearchPermitFormData.status);
            setFieldValue('loginUser', initSearchPermitFormData.loginUser);
            setFieldValue('streetId', initSearchPermitFormData.streetId);
            setFieldValue('houseNum', initSearchPermitFormData.houseNum);
            setFieldValue('appatmentNum', initSearchPermitFormData.appatmentNum);
            setFieldValue('firstName', initSearchPermitFormData.firstName);
            setFieldValue('lastName', initSearchPermitFormData.lastName);
        }
    }
}

export default PermitsListPageProvider;

export const usePermitsListPageContext = () => useContext(PermitsListPageContext);