import { useState } from "react";
import { UPLOAD_FILE } from "../../api/uploadFileApi";
import ResponseStatus from "../../types/ResponseStatus";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

const useUploadFileModal = () => {

    const axiosPrivate = useAxiosPrivate();
    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [isLoadingFileModal, setIsLoadingFileModal] = useState(false);
    const [errorFileModal, setErrorFileModal] = useState('');

    async function uploadFileToServer(personId, file) {
        setIsLoadingFileModal(true);

        const result = { status: ResponseStatus.FAILURE, message: '' };
        const form = new FormData();
        form.append('personId', personId);
        form.append('file', file);

        const response = await axiosPrivate.post(UPLOAD_FILE, form);
        const data = response.data;

        if (data.status !== ResponseStatus.SUCCESS) {

            result.message = data?.message ? data?.message : 'בעיה בשרת';
            return result;
        }

        result.status = ResponseStatus.SUCCESS;
        result.message = 'קובץ עולה בהצלחה';

        setIsLoadingFileModal(false);

        if (data.status === ResponseStatus.FAILURE) {
            setErrorFileModal(result.message);
        } else {
            setUploadFileModal(false);
        }

        return result;
    }

    return { uploadFileModal, setUploadFileModal, uploadFileToServer, errorFileModal, isLoadingFileModal };
}

export default useUploadFileModal;