import React from 'react'
import style from './paging.module.css';
import PagingBubtons from './PagingBubtons';
import SelectLimitOfRow from '../../../Forms/selectLimitOfRows/SelectLimitOfRow';
import NumOrange from './NumOrange';

const PagingBox = ({ offset, count, limit, name, changePage, isLoading, handleChengeLimit }) => {

    const offsetInt = parseInt(offset);
    const countInt = parseInt(count);
    const limitInt = parseInt(limit);
    const numOfPages = Math.ceil(countInt / limitInt);
    const fItem = offsetInt * limitInt + 1;
    let lItem = fItem + limitInt;

    if (countInt < lItem) lItem = countInt;

    return (
        <div className={style.pagingContainer}>
            <div className={style.totalCount}>מספר {name}: <NumOrange num={countInt} /> </div>
            {numOfPages > 1 ? (
                <PagingBubtons
                    changePage={changePage}
                    isLoading={isLoading}
                    numOfPages={numOfPages}
                    offsetInt={offsetInt}
                />
            ) : ''}
            <div>
                <SelectLimitOfRow
                    value={limit}
                    name={'limit'}
                    handleChange={handleChengeLimit} />
                <span>עמוד מס {offsetInt + 1} מתוך {numOfPages} |  תוצאות {fItem} - {lItem}</span>
            </div>
        </div>
    );
}

PagingBox.defaultProps = {
    offset: 0, 
    count: 0, 
    limit: 0, 
    changePage: () => {}, 
    isLoading: false, 
    handleChengeLimit : () => {},
    name: "שורות",
};

export default PagingBox;