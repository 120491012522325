import React from 'react'

const RadioBtn = ({ value, id, name, onChange, onClick, onBlur, label, isChecked, disabled }) => {
    //console.log(isChecked)
    return (
        <>
            <input
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                onChange={(e) => onChange(e)}
                id={id}
                name={name}
                className="btn-check"
                type='radio'
                autoComplete="off"
                onClick={(e) => onClick(e)}
                disabled={disabled}
            />
            <label 
                className="btn btn-outline-success" 
                htmlFor={id}
                >
                {label}
            </label>
        </>
    )
}

RadioBtn.defaultProps = {

    label: '',
    value: '',
    isRequired: false,
    disabled: false,
    errorMsg: '',
    onBlur: () => { },
    onChange: () => { },
    onClick: () => { }
}

export default RadioBtn