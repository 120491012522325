import React from 'react';
import { IoClose, IoIosClose } from 'react-icons/io';
import style from './generalModal.module.css';

const GeneralModalClosingBtn = ({onClick}) => {
  return (
    <button title='סוגר חלונית' className={style.closingBtn} onClick={onClick}>
        <IoIosClose fontSize={60} color={'#4D4D4D'}/>
    </button>
  )
}

export default GeneralModalClosingBtn