import React from 'react';
import SelectStreet from '../../../../Components/Forms/select/SelectStreet';
import InputGroup from '../../../../Components/Forms/InputGroup';
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitContectDetails = () => {
    const { errors, values, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = useAddPermitPageContext();
    const contectTitle = 'כתובת';

    return (
        <FormSection title={contectTitle}>
            <div className='row'>
                <div className="col-lg-6 col-xl-6">
                    <SelectStreet
                        value={values.street}
                        name='street'
                        isRequired={true}
                        errorMsg={(errors.street && touched.street) ? errors.street : undefined}
                        handleChange={(option) => setFieldValue('street', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('street', true);
                        }}
                        />
                </div>
                <div className="col-lg-6 col-xl-3">
                    <InputGroup
                        name='houseNum'
                        label='מספר בית'
                        value={values.houseNum}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.houseNum && touched.houseNum) ? errors.houseNum : undefined}
                    />
                </div>
                <div className="col-lg-6 col-xl-3">
                    <InputGroup
                        name='appartmentNum'
                        label='מספר דירה'
                        value={values.appartmentNum}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.appartmentNum && touched.appartmentNum) ? errors.appartmentNum : undefined}
                    />
                </div>
            </div>

            <div className="row ">
                <div className="col-lg-6 col-xl-4">
                    <InputGroup
                        name='email'
                        label='אימייל'
                        type='email'
                        value={values.email}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.email && touched.email) ? errors.email : undefined}
                    />
                </div>
                <div className="col-lg-6 col-xl-4">
                    <InputGroup
                        name='mobile'
                        label='טלפון נייד'
                        value={values.mobile}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.mobile && touched.mobile) ? errors.mobile : undefined}
                    />
                </div>
                <div className="col-lg-6 col-xl-4">
                    <InputGroup
                        name='phone'
                        label='טלפון נוסף'
                        value={values.phone}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.phone && touched.phone) ? errors.phone : undefined}
                    />
                </div>
            </div>
        </FormSection>
    );
}

export default PermitContectDetails;