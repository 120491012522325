import React from 'react';
import { MdDownloadDone } from 'react-icons/md';
import Button from './Button';

const CreatePermitBtn = ({ onClick, label, color, disabled, style }) => {
  return (
    <Button
    key={'CreatePermitBtn'}
      disabled={disabled}
      theme={color}
      onClick={onClick}
      style={style}
      endIcon={<MdDownloadDone />}
    >
      {label}
    </Button>
  )
}

CreatePermitBtn.defaultProps = {
  disabled: false,
  color: "success",
  label: "צור תו",
  onClick: () => { },
  style: {}
};

export default CreatePermitBtn;