import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { usePaymentsReportPageContext } from '../../../../contexts/reports/PaymentsReportPageContext';
import TablesPayment from './TableDistribution';
import { formaterMoneyAmount } from '../../../../Helpers/format/numberFormat';

const COLORS = ['#00C49F', '#0088FE'];

const ChartDistribution = () => {

    const { displayByPieChart, setDisplayByPieChart, pRecordsChartArea } = usePaymentsReportPageContext();
    const showLabel = true;

    return (

        <div style={{
            height: "35rem", maxHeight: "35rem",
            textOverflow: 'ellipsis', padding: '2rem',
            backgroundColor: 'white',
            overflowY: 'auto',
            borderRadius: '4px', flexGrow: 1,
            boxShadow: "0px 5px 20px 0px rgba(0,0,0,0.1)"
        }}>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio" name="displayBy" id="graphRadioBtn"
                        checked={displayByPieChart === 1} />
                    <label className="form-check-label" for="graphRadioBtn" onClick={() => setDisplayByPieChart(1)}>
                        הצג גרף
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio" name="displayBy" id="tableRadioBtn"
                        checked={displayByPieChart === 2} />
                    <label className="form-check-label" for="tableRadioBtn" onClick={() => setDisplayByPieChart(2)}>
                        הצג טבלה
                    </label>
                </div>
            </div>
            {displayByPieChart == 1 ? (
                <ResponsiveContainer width="100%" height="92%" style={{ direction: 'ltr' }}>

                    <BarChart width={730} height={250} data={pRecordsChartArea}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={showLabel ? { value: 'סכום', angle: -90, position: 'insideLeft' } : ''}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar name='מזמן' dataKey="cashAmount" fill={COLORS[0]} />
                        <Bar name='אשראי' dataKey="creditCardAmount" fill={COLORS[1]} />
                    </BarChart>

                </ResponsiveContainer>
            ) : ''}
            {displayByPieChart == 2 ? (
                <TablesPayment />
            ) : ''}

        </div>

    );

}

export default ChartDistribution;

function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
        return (
            <div style={{
                display: 'flex', flexDirection: 'column', alignContent: 'center', justifyItems: 'center',
                backgroundColor: 'rgb(250, 252, 255, 1)', padding: '0.5rem', border: '1px gray solid',
                borderRadius: '4px', boxShadow: "0px 5px 20px 0px rgba(0,0,0,0.1)"
            }}>
                <strong>{`${label}`}</strong>
                <hr style={{marginBottom: '0px', marginTop: '0px'}}/>
                <span style={{ color: `${payload[0].fill}`}}>
                    {`${payload[0].name}: ${formaterMoneyAmount(payload[0].value)}`}
                </span>
                <span style={{ color: `${payload[1].fill}`}}>
                    {`${payload[1].name}: ${formaterMoneyAmount(payload[1].value)}`}
                </span>
            </div>
        );
    }

    return null;
}
