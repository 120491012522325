function hasMoreNCRStart(index, str) {

    return str.indexOf('&#', index) > -1;
}

export const decodeNCRStringIfNeeded = (str = '') => {
    let decodeNCRString = '';

    if(str === null || str === undefined) return decodeNCRString;
    
    if (!str.length) return decodeNCRString;

    if(!str.includes("&#")) return str;
    
    let index = 0;

    while(index < str.length - 1 && str.indexOf('&#', index) > -1) {
        
        let indexSemicolon = -1; 
        let code = -1;
        let decodeNCRStringTemp = '';
        
        if(str.indexOf('&#', index) - index > 0) {

            decodeNCRStringTemp = str.substring(index, str.indexOf('&#', index));
        }

        index = str.indexOf('&#', index);
        indexSemicolon = str.indexOf(';', index);

        if(indexSemicolon === -1) {
            break;
        }

        code = Number(str.substring(index + 2, indexSemicolon));
        try {
            
            decodeNCRStringTemp += String.fromCodePoint(code);
        } catch(e) {
            index++;
            decodeNCRStringTemp += code;
        }
        
        index = indexSemicolon + 1;
        decodeNCRString += decodeNCRStringTemp;
    }
    
    if(index <= str.length) {
        decodeNCRString += str.substring(index);
    }
    

    return decodeNCRString;
}

export const includesHebrew = (str) => (/[\u0590-\u05FF]/).test(str);
// עמית קורן 78.
export const encodeStringToNCRIfNeeded = (str) => {
    if (!includesHebrew(str)) return str;
    return str
        .split('')
        .map((s, i) => `&#${str.charCodeAt(i)};`)
        .join('');
}

export const encodeHebrewInValues = (obj) => {
    const res = {...obj}
    for (const [key, value] of Object.entries(res)) {
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                if (typeof value[0] === "string") {
                    res[key] = value.map(item => encodeStringToNCRIfNeeded(item));
                } else {
                    res[key] = value.map(item => encodeHebrewInValues(item));
                }
            } else {
                res[key] = encodeHebrewInValues(value);
            }
        } else if (typeof value === "string") {
            if (includesHebrew(value)) {
                res[key] = encodeStringToNCRIfNeeded(value);
            }
        }
    }
    return res;
}