

/** get the payments destrebut by days.
 * 
 * @Method: POST
 * @Body: FormData
 * 
 * - in the formData:
 *  fromDate - The start of the time.
 *  toDate - The end of the time. 
 */
export const GET_PAYMENTS_REPORT = '/payments/get-payment-report-stats';