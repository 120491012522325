import React from 'react';
import Table from './Table';

const SimpleTable = ({ rows, headers, footers, name }) => {

    const count = Array.isArray(rows) ? rows.length : 0;

    return (
        <>
            <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>
                מספר {name} <span style={{ 'fontWeight': 'bold', color: 'orange'}}>{count}</span>
            </div>
            <Table headers={headers} rows={rows} footers={footers} name={name} />
        </>
    );
}

SimpleTable.defaultProps = {
    headers: [],
    rows: [],
    footers: [],
    name: 'שורות'
};

export default SimpleTable;