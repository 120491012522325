import React from 'react';
import { BsInfoCircle } from "react-icons/bs";

const InfoCircle = ({ infoMsg }) => {

    return (
        <span title={infoMsg}><BsInfoCircle /></span>
    );
}

export default InfoCircle;