import { useContext } from "react";
import { ProjectDetailsContext } from "../../contexts/ProjectDetailsContext";
import useAxiosPrivate from "../useAxiosPrivate";
import { useAuthContextContext } from "../../contexts/AuthContext";
import ResponseStatus from "../../types/ResponseStatus";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";
import { ADD_PERMIT, GET_PERMIT, UPDATE_PERMIT } from "../../api/permitApi";


export const useAddPermit = () => {

    const { logout } = useAuthContextContext();
    const axiosPrivate = useAxiosPrivate();

    async function addPermit(values) {

        const form = new FormData();

        Object.keys(values).forEach(name => {

            form.append(name, values[name]);
        });

        const response = await axiosPrivate.post(ADD_PERMIT, form);
        return response.data;
    }

    async function getPermit(permitId) {

        const result = {
            data: {
                permit: undefined,
                resident: undefined,
                vehicle: undefined
            },
            status: ResponseStatus.FAILURE,
            message: ''
        }

        try {

            const response = await axiosPrivate.get(`${GET_PERMIT}/${permitId}`);
            const data = response?.data?.data;
            const resident = data.permit.resident;
            const vehicle = data.permit.vehicle;

            result.status = data.status;
            result.message = data.message;
            result.data = {
                permit: data.permit,
                resident: resident,
                vehicle: vehicle
            }
        } catch (error) {
            const status = error.response?.status;
            const errorData = error.response?.data;
            let errorMsg = `קרתה שגיעה בשרת קוד שגיעה: ${status}`;

            if (errorData) {

                errorMsg = errorData?.message;
                console.log(`error.message: ${JSON.stringify(errorData?.message)}`)
            }

            console.error(error);
            result.message = errorMsg;
        }

        return result;
    }

    async function updatePermit(values) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: ''
        }

        const form = new FormData();

        Object.keys(values).forEach(name => {

            form.append(name, values[name]);
        });

        try {

            const response = await axiosPrivate.post(UPDATE_PERMIT, form);

            const data = response.data;

            result.status = data.status;
            result.message = data.message
        } catch (error) {
            console.log('error ' + error);
            result.message = error?.response?.data?.message; //'בעיה כללית בשרת';

            if (error.message === 'permit is valid') {

                result.message = 'קיים תו זהה אשר תוקפו גדול משלושה חודשים';
            }

            if (error.message === 'Network Error') {

                result.message = 'בעיה כללית בשרת קוד: 502';
            }

            if (error?.response?.status === 401) {

                result.message = 'צריך להתחבר מחדש 401';
                logout();
            }
        }

        return result;
    }

    return { addPermit, updatePermit, getPermit };
}