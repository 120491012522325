import { useReducer, useEffect } from "react";
import { useAuthContextContext } from "../../contexts/AuthContext";
import useAxiosPrivate from "../useAxiosPrivate";
import { FORM_ACTION, permitReportReducer } from "../../reducers/permits/permitReportReducer";
import PaymentMethod from '../../types/PaymentMethod';
import CreatedByTypes from '../../types/CreatedByTypes';
import { initSearchPermitReportFormData } from "../../data/default/searchPermitReportForm.data";
import { GET_PERMIT_REPORT_DETAILS } from "../../api/permitApi";
import { useComponentState } from "../useComponentState";
import { formaterMoneyAmount } from "../../Helpers/format/numberFormat";

export const usePermitReport = () => {

    const { logout } = useAuthContextContext()
    const axiosPrivate = useAxiosPrivate();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [dataState, dataDispatch] = useReducer(permitReportReducer, initSearchPermitReportFormData);

    useEffect(() => {

        getPermitReportRows();
    }, []);

    async function getPermitReportRows() {

        setIsLoading();

        const data = new FormData();
        data.append('fromDate', dataState.form.fromDate);
        data.append('toDate', dataState.form.toDate);

        try {

            const response = await axiosPrivate.post(GET_PERMIT_REPORT_DETAILS, data);
            const jsonData = response.data;

            const permitReportRows = jsonData.permitReportRows.map((row, index) => {
                return convertRawDataFormServer((index + 1), row);
            });

            const footer = calcTheFooter(permitReportRows.length, jsonData);

            dataDispatch({
                type: FORM_ACTION.UPDATE_DATA,
                payload: {
                    permitReportRows: permitReportRows,
                    totalCardPayment: jsonData.totalCardPayment,
                    totalCashPayment: jsonData.totalCashPayment,
                    totalCitizenCount: jsonData.totalCitizenCount,
                    totalCount: jsonData.totalCount,
                    totalPayment: jsonData.totalPayment,
                    footer: footer
                }
            });

        } catch (error) {

            let message = error?.response?.data?.message

            if (error?.response?.status === 400 || error?.response?.status === 500) {
                
                console.log(`error, message - ${message}`);
                setDangerAlert('בעיה בשרת, קוד: ' + error?.response?.status);
            }

            if (error?.response?.status === 401 || error?.response?.status === 403) {

                logout();
            }

            console.log(`error - ${error}`);
        }
        
        setIsNotLoading();
    }

    return { getPermitReportRows, dataState, dataDispatch, componentState, removeAlert };

    function calcTheFooter(index, jsonData) {
        
        const totalPayment = formaterMoneyAmount(jsonData.totalPayment);
        const totalCardPayment = formaterMoneyAmount(jsonData.totalCardPayment);
        const totalCashPayment = formaterMoneyAmount(jsonData.totalCashPayment);

        const footer = [
            index,
            'סיכום',
            jsonData.totalCount,
            totalPayment,
            `אשראי: ${totalCardPayment}`,
            `מזומן: ${totalCashPayment}`,
            `קבלת קהל: ${jsonData.totalCount - jsonData.totalCitizenCount} `,
            `אזרח: ${jsonData.totalCitizenCount}`,
        ];

        return footer;
    }

    function convertRawDataFormServer(index, row) {

        let totalPayment = row.payment * row.count;
        let payment = formaterMoneyAmount(row.payment);
        let createdBy = CreatedByTypes.CITIZEN === row.createdBy ? 'אזרח' : 'קבלת קהל';
        let paymentMethod = 'אין';

        if (row.paymentMethod === PaymentMethod.CARD) paymentMethod = 'אשראי';
        if (row.paymentMethod === PaymentMethod.CASH) paymentMethod = 'מזומן';

        return {
            index: index++,
            loginUser: row.loginUser,
            count: row.count,
            payment: `${totalPayment} (מחיר לתו: ${payment})`,
            paymentMethod: paymentMethod,
            createdBy: createdBy,
        }
    }

}