export const initLoginData = {
    projectId: 1,
    userName: '',
    password: ''
}

export const initDammyLoginData = {
    projectId: 1,
    userName: 'plola1',
    password: 'lola1'
}