import React from 'react';
import Button from '../../../../../Components/Buttons/Button';
import { VscGitPullRequestGoToChanges } from 'react-icons/vsc';

const BtnOpenCPSModal = ({ onClick }) => {

    return (
        <Button endIcon={<VscGitPullRequestGoToChanges />} onClick={onClick}>שינוי סטטוס</Button>
    );
}

export default BtnOpenCPSModal;