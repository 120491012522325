import React from 'react'
import { BiHide, BiShow } from 'react-icons/bi';
import RequestStatusType from '../../types/RequestStatusType';
import Button from './Button';

const UpdateRquestStatusBtn = ({ onClick, disabled, currentStatus, size }) => {

    let label = 'דחיה';
    let icon = <BiHide />
    let color = 'danger';
    let newStatus = -1;

    if (currentStatus === RequestStatusType.PENDING) {

        newStatus = RequestStatusType.REJECTED;
    }

    if (currentStatus === RequestStatusType.REJECTED) {

        label = 'בטל דחיה';
        icon = <BiShow />;
        color = 'secondary';
        newStatus = RequestStatusType.PENDING;
    }

    return (
        <Button 
        disabled={disabled} 
        size={size} 
        theme={color} 
        onClick={() => onClick(newStatus)}
        endIcon={icon}
        >
        {label}
        </Button>
    )
}

UpdateRquestStatusBtn.defaultProps = {
    disabled: false,
    currentStatus: RequestStatusType.REJECTED,
    onClick: () => { },
    size: ''
};

export default UpdateRquestStatusBtn;