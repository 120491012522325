import React from 'react';
import Alert from '../../../Components/Alerts/Alert';
import DisplayManagementPermit from './components/DisplayManagementPermit';
import HistoryModal from '../../../features/History/HistoryModal';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import { usePermitsListPageContext } from '../../../contexts/permit/PermitsListPageContext';
import FormFilterPermitManagement from './components/FormFilterPermitManagement';
import ChangePermitStatusModal from './components/changePermitStatusModal/ChangePermitStatusModal';

const containerStyle = { display: 'flex', flexDirection: 'column', rowGap: '2rem' };

const PermitManagementPage = () => {

    const pageTitle = 'ניהול תווים';
    const { historyModalState, historyModalStateDispatch, closeHistoryModal, showChangeStatusModal, componentState, 
        closeChangeStatusModal, permitToEditStatus, handlerUpdatePermitStatus } = usePermitsListPageContext();

    return (
        <>  
            <HistoryModal historyModalState={historyModalState} 
                historyModalStateDispatch={historyModalStateDispatch} closeHistoryModal={closeHistoryModal} />

            <ChangePermitStatusModal isShow={showChangeStatusModal} closingFun={closeChangeStatusModal}
                permit={permitToEditStatus} 
                onApprove={(newStatus, permitId) => handlerUpdatePermitStatus(newStatus, permitId)} />
          
            <div style={containerStyle}>
                <Alert isShow={componentState.hasAlert} message={componentState.message} />
                <PageTitle title={pageTitle} />
                <FormFilterPermitManagement />
                <DisplayManagementPermit />
            </div>
        </>
    );
}

export default PermitManagementPage;
