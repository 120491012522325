

/** Upload file decoded to 64bit.
 * 
 * @Method: POST
 * @Body: FormData
 * 
 * - in the formData:
 *  personId - The person number id.
 *  file - The file decoded to 64bit. 
 */
export const UPLOAD_FILE = '/files/add';

