import React from 'react';
import style from '../sendEmail.module.css';

const SmallTitle = ({title}) => {
  return (
    <h5 className={style.smallTitle}>
      {title}
    </h5>
  );
}

export default SmallTitle;