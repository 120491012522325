import React from 'react';
import { ImShrink2, ImEnlarge2 } from "react-icons/im";
import Style from './Card.module.css';

const ExpandBtn = ({showContent, clickHandle }) => {
    let icon = <ImEnlarge2 />;

    if (showContent) icon = <ImShrink2 />;

    return (
        <button
            className={Style.expandBtn}
            onClick={clickHandle}>
            {icon}
        </button>
    );
}

const SimpleCardHeader = ({showContent, title, setShowContent, withClosingBtn}) => {
    const classes = Style.simpleHeaderTitle;
    const btn = '';
    const clickHandle = () => setShowContent(!showContent);

    if(withClosingBtn) btn = <ExpandBtn showContent={showContent} clickHandle={clickHandle}/>;
    
    return (
        <div className={classes}>
            <div className={Style.headerTitle}>{title}</div>
            {btn}
        </div>
    );
}

export default SimpleCardHeader;