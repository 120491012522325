export const HISTORY_MODAL_ACTION = {
    CLOSE_HISTORY_MODAL: 'CLOSE_HISTORY_MODAL',
    OPEN_HISTORY_MODAL: 'OPEN_HISTORY_MODAL',
    SET_HISTORY_LIST: 'SET_HISTORY_LIST',
    UPDATE_IDENTIFIER: 'UPDATE_IDENTIFIER',
    UPDATE_IS_REQUEST: 'UPDATE_IS_REQUEST',
    GO_TO_RECORD_LIST: 'GO_TO_RECORD_LIST',
    GO_TO_VIEW_RECORD: 'GO_TO_VIEW_RECORD',
    IS_LOADING: 'IS_LOADING',
    IS_NOT_LOADING: 'IS_NOT_LOADING',
}

// Identifier updateIsRequest
export const historyModalReducer = (state, action) => {

    switch (action.type) {
        case HISTORY_MODAL_ACTION.CLOSE_HISTORY_MODAL:

            return {...state, isShowHistoryModal: false};
        case HISTORY_MODAL_ACTION.UPDATE_IDENTIFIER:

            return {
                ...state, identifier: action.identifier
            };
        case HISTORY_MODAL_ACTION.SET_HISTORY_LIST:

            return {
                ...state,
                recordList: action.historyList,
                currentRecord: {},
                isShowRecordRow: false,
                modalStatus: { isLoading: false, msg: '' }
            };
        case HISTORY_MODAL_ACTION.GO_TO_RECORD_LIST:

            return {
                ...state,
                currentRecord: {},
                isShowRecordRow: false
            };
        case HISTORY_MODAL_ACTION.GO_TO_VIEW_RECORD:

            return {
                ...state,
                currentRecord: action.payload.currentRecord,
                isShowRecordRow: true
            };
        case HISTORY_MODAL_ACTION.IS_LOADING:

            return {
                ...state,
                modalStatus: { isLoading: true, msg: '' }
            };
        case HISTORY_MODAL_ACTION.IS_NOT_LOADING:

            return {
                ...state,
                modalStatus: { isLoading: false, msg: action.msg }
            };
        case HISTORY_MODAL_ACTION.OPEN_HISTORY_MODAL:
            console.log(action.identifier)
            return {
                ...state,
                isShowHistoryModal: true,
                isRequest: action.payload.isRequest,
                identifier: action.payload.identifier,
                recordList: [],
                currentRecord: {},
                isShowRecordRow: false,
                modalStatus: { isLoading: false, msg: '' }
            };

        default:
            return state;
    }
}