import React from 'react';
import ViewPermitBtn from '../../../../Components/Buttons/ViewPermitBtn';

const ExpiredPermitsRow = ({ permit, index }) => {

    return (
        <tr>
            <th scope="row">{index}</th>
            <td >{permit.fullName}</td>
            <td >{permit.vehicleNum}</td>
            <td >{permit.cellPhone}</td>
            <td >{permit.additionalPhone}</td>
            <td >{permit.endValidityDate}</td>
            <td >{permit.makerName}</td>
            <td >
                <ViewPermitBtn rowDbId={permit.id} />
            </td>
        </tr>
    );
}

export default ExpiredPermitsRow;