import React, { useEffect, useState } from 'react';
import { CiImageOn } from "react-icons/ci";
import { VscFilePdf } from "react-icons/vsc";
import { GrNext, GrPrevious } from "react-icons/gr";
import style from '../../requestDetailsPage.module.css';
import RequestFileViewer from './RequestFileViewer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import GeneralModal from '../../../../../Components/GeneralModal/GeneralModal';
import { useRequestManagmentPageContext } from '../../../../../contexts/request/RequestDetailsPageContext';
import ComparisonDataWithFilesDisplay from './ComparisonDataWithFilesDisplay';
import DangerAlert from '../../../../../Components/Alerts/DangerAlert';
import LoadingDiv from '../../../../../Components/UI/loadingDiv/LoadingDiv';
import ResponseStatus from '../../../../../types/ResponseStatus';
import { initComparisonRequestDataAndFiles } from '../../../../../data/default/requestPermit.data';
import { FileTypes } from '../../../../../types/FileTypes';


const FilesModal = ({ isShow }) => {

  const { closeFilesModal, values } = useRequestManagmentPageContext();
  const axiosPrivate = useAxiosPrivate();
  const [files, setFiles] = useState([]);
  const [activeFile, setActiveFile] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComparison, setIsLoadingComparison] = useState(false);
  const [message, setMessage] = useState('');

  const [comparisonData, setComparisonData] = useState(initComparisonRequestDataAndFiles);

  let content = '';
  let filesContainer = '';
  let previewFile = [];

  if (!isLoading) {

    previewFile = (
      <div className={style.previewFileBtnsContainet}>
        <button onClick={prevFile} className={style.noneStyleBtn + " " + style.prevBtn}>
          <GrNext />
        </button>
        {files.map((file, index) => {

          let classes = style.previewFileBtn;
          let isPdf = file.type === FileTypes.PDF_TYPE ? true : false;

          if (activeFile === index) {

            classes += ' ' + style.previewFileBtnActive;
          }

          return (
            <button
              key={`moveFile${index}`}
              type="button"
              onClick={() => setActiveFile(index)}
              className={classes}
            >
              {isPdf ? <VscFilePdf /> : <CiImageOn />}
            </button>
          );
        })}
        <button onClick={nextFile} className={style.noneStyleBtn + " " + style.nextBtn}>
          <GrPrevious />
        </button>
      </div>
    );

    filesContainer = (
      <div id="carouselExampleIndicators" className={"carousel slide " + style.filesContainer}>
        <div className="carousel-inner">
          {files.map((file, index) => {

            let isActive = false;

            if (activeFile === index) isActive = true;

            return <RequestFileViewer nextFile={nextFile} prevFile={prevFile} key={`file_${file.lastModified}_${index}`} isActive={isActive} file={file} />
          })
          }
        </div>
      </div>
    );
  }

  if (isLoading) {

    filesContainer = <LoadingDiv />;
  }

  useEffect(() => {

    if(isShow) {
      getRequestFiles(values);
      getComparisonDataWithFiles(values);
    }
  }, [values, isShow]);

  content = (
    <div className={style.filesModalContent}>
      <div className={style.filesModalFilesItem}>
        {previewFile}
        {filesContainer}
      </div>
      <div className={style.filesModalDetailsItem}>
        {message ? <DangerAlert message={message} /> : ''}
        <ComparisonDataWithFilesDisplay comparisonData={comparisonData} isLoadingComparison={isLoadingComparison} />
      </div>
    </div>
  );

  if(!isShow) {
    return '';
  }

  return (
    <GeneralModal
      isShow={isShow}
      hasFooter={false}
      title={'תמונות ופרטים'}
      closingFun={closeFilesModal}
      content={content} modalSize={'XL'}
    />
  );

  function nextFile() {

    if (activeFile >= files.length - 1) {

      setActiveFile(0);
      return;
    }

    setActiveFile(activeFile + 1);
  }

  function prevFile() {

    if (activeFile === 0) {

      setActiveFile(files.length - 1);
      return;
    }

    setActiveFile(activeFile - 1);
  }

  async function getComparisonDataWithFiles(values) {

    setMessage('');

    let responseData = null;
    let resultComp = null;
    const url = `/request/get-comparing-files-with-data/${values.requestId}`;

    try {
      setIsLoadingComparison(true);
      const response = await axiosPrivate.get(url);
      setIsLoadingComparison(false);


      if (!response.data?.data) {

        setMessage('no data from server!');
        return;
      }

      responseData = response.data;
      resultComp = responseData.data;
      const comperingFiles = resultComp.comparingFiles;

      if (responseData.status === ResponseStatus.FAILURE) {
        setMessage('תוכן הקבצים לא נבדק');
      }

      if (!resultComp) {
        return;
      }

      console.log(resultComp)

      setComparisonData({
        "personId": resultComp.personId,
        "carNum": resultComp.carNum,
        "fName": resultComp.fName,
        "lName": resultComp.lName,
        "lNameAppearsInPersonId": comperingFiles.lNameInPersonIdFile,
        "personIdNumAppearsInCarId": comperingFiles.personIdNumInLicenseFile,
        "personIdNumAppearsInPersonIdCard": comperingFiles.personIdNumInPersonIdFile,
        "lNameAppearsInCarId": comperingFiles.lNameInLicenseFile,
        "carNumAppearsInCarId": comperingFiles.carNumInLicenseFile,
        "fNameAppearsInPersonId": comperingFiles.fNameInPersonIdFile,
        "fNameAppearsInCarId": comperingFiles.fNameInLicenseFile,
        "projectNInAdditionalPerIdFile": comperingFiles.projectNInAdditionalPerIdFile,
      });

    } catch (error) {
      setIsLoadingComparison(false);
      console.log('[getPermitRequest] error: ', error);
    }

    setIsLoadingComparison(false);
  }

  async function getRequestFiles(values) {

    setIsLoading(true);
    const data = {
      citizenIdPath: '',
      carIdPath: '',
      pathScandLeasing: '',
      extraFiles: '',
      personId: String(values.personId)
    };

    if (values.citizenIdPath != undefined) {

      data.citizenIdPath = values.citizenIdPath.substring(values.citizenIdPath.lastIndexOf('/'));
    }

    if (values.carIdPath != undefined) {
      data.carIdPath = values.carIdPath.substring(values.carIdPath.lastIndexOf('/'));
    }

    if (values.pathScandLeasing != undefined) {

      data.pathScandLeasing = values.pathScandLeasing.substring(values.pathScandLeasing.lastIndexOf('/'));
    }

    if (values.extraFilesPaths != undefined) {

      data.extraFiles = values.extraFilesPaths;
    }

    try {

      const response = await axiosPrivate.post('/files/request-files', data);

      if (response.data?.data?.files)
        setFiles(response.data.data.files);

    } catch (error) {
      console.log('[getPermitRequest] error: ', error);
    }

    setIsLoading(false);
  }
}



export default FilesModal;