import { useEffect, useState } from "react";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import ResponseStatus from "../../types/ResponseStatus";
import { decodeNCRStringIfNeeded } from "../../Helpers/language";

const useSendSms = (request, isShow) => {

    const axiosPrivate = useAxiosPrivate();
    const [smsMessage, setSmsMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({ hasError: false, message: '' });
    const [isBtnDesable, setisBtnDesable] = useState(true);

    async function sendSms() {

        const form = new FormData();

        form.append('mobileNum', request.mobile);
        form.append('requestId', request.id);

        try {

            const response = await axiosPrivate.post('/sms/send-sms-to-request', form);
            const data = response.data;

            if (data.status === ResponseStatus.SUCCESS) {

                return { status: ResponseStatus.SUCCESS, message: 'מסרון נשלח בהצלחה', date: data.data.date };
            }

            return { status: ResponseStatus.FAILURE, message: data.message };
        } catch (error) {

            console.log(error);
            return { status: ResponseStatus.FAILURE, message: 'בעיה בשרת' };
        }
    }

    async function getSmsContentApi() {

        setIsLoading(true);

        try {

            const response = await axiosPrivate.get(`sms/sms-message-request/${request.id}`);
            const data = response.data;
            let message = '';

            if (data.status === ResponseStatus.SUCCESS) {

                message = decodeNCRStringIfNeeded(data.data.smsMessage);
                setSmsMessage(message);
                setisBtnDesable(false);
            }

        } catch (error) {

            console.log(error);
            setError({ hasError: true, message: 'בעיה בשרת - נא לפנות ללולהטק' });
        }

        setIsLoading(false);
    }

    useEffect(() => { 

        if(isShow)
            getSmsContentApi();

    }, [isShow]);

    return { sendSms, smsMessage, isLoading, error, setError, isBtnDesable, setisBtnDesable };
}


export default useSendSms;