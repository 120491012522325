import React from 'react'
import RedStar from './RedStar';
import style from './forms.module.css';

const InputTextArea = ({ label, value, name, handleChange, handleBlur, errorMsg, isRequired, disabled, rows, placeholder, inputStyle }) => {

    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if(hasError){
        classes += ' is-invalid';
    }

  return (
    <>
        {isRequired ? <RedStar /> : ''}
        {label ? <label className="form-label" htmlFor={`${name}Id`}>{label}</label> : ''}
        <textarea
            style={inputStyle}
            placeholder={placeholder}
            className={classes}
            rows={rows}
            disabled={disabled}
            value={value}
            required={isRequired}
            name={name} 
            id={`${name}Id`}
            onChange={handleChange} 
            onBlur={handleBlur}
        /> 
        {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
    </>
  )
}

InputTextArea.defaultProps = {
    label: '',
    disabled: false,
    required: false,
    rows: 3,
    placeholder: '',
    inputStyle: {},
}

export default InputTextArea;