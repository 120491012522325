import { useState } from "react";
import { initSendMsgModalFormData } from "../data/default/sendMessageModal.data";
import { useComponentState } from "./useComponentState";
import useAxiosPrivate from "./useAxiosPrivate";
import { useFormik } from "formik";
import ResponseStatus from "../types/ResponseStatus";
import translateErrorCode from "../Helpers/errors/translateErrorCode";
import { useProjectDetailsContext } from "../contexts/ProjectDetailsContext";
import { decodeNCRStringIfNeeded } from "../Helpers/language";
import { sendMessageVatidationSchema } from "../validation/sendMessageVatidationSchema";

export const useSendMessageModal = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, setIsLoading, setIsNotLoading, setDangerAlert, removeAlert } = useComponentState();
    
    const messageConfig = projectDetails.messageConfig;
    const messageEmailConfig = messageConfig.messageEmailConfig;

    const axiosPrivate = useAxiosPrivate();
    const formik = useFormik({
        initialValues: { ...initSendMsgModalFormData },
        onSubmit: values => handleSendMessage(values),
        validateOnMount: true,
        validateOnChange: true,
        validationSchema: sendMessageVatidationSchema,
    });
    
    const { setFieldValue, resetForm } = formik;
    const [isOpenModal, setIsOpenModal] = useState(false);


    function openSendMessageModal(isRequest, identifier, email, mobileNum) {

        setIsOpenModal(true);
        setFieldValue('isRequest', isRequest);
        setFieldValue('identifier', identifier);
        setFieldValue('email.citizenEmail', email);
        setFieldValue('sms.mobileNum', mobileNum);
        setFieldValue('sendViaEmail', messageConfig.sendingCitizenMsgVia);
        setFieldValue('email.cityEmail', messageEmailConfig.generalEmailConfig.cityEmailAddress);
        setFieldValue('email.emailSubject', decodeNCRStringIfNeeded(messageEmailConfig.generalEmailConfig.subject));
    }

    function closeSendMessageModal() {

        resetForm();
        setIsOpenModal(false);
    }

    async function handleSendMessage(values) {
        
        setIsLoading();      

        const emailVals = values.email;
        const dataSent = {...values};

        dataSent.email.sendCopyToCity = emailVals.sendCopyToCity === 1;
    
        try {

            //const response = await timeout(1000);
            const response = await axiosPrivate.post('/request/send-general-message', dataSent);

            const data = response?.data ? response.data : {};

            if (!data?.status || data.status === ResponseStatus.FAILURE) {
                throw Error('הודעה לא נשלחה')
            }

            setIsNotLoading();
            closeSendMessageModal();
        } catch (error) {

            const errMap = {
                generalError: 'אימייל לא נשלח, בעיה כללית בשרת',
                BAD_REQUEST: 'אימייל לא נלשח, לא כל הנתונים הגיעו לשרת'
            }

            let message = errMap.generalError;

            if (error.response) {

                message = translateErrorCode(error, errMap);
            } else if (error.request) {

                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                console.log('user canceled the request', error.message);
            }

            setDangerAlert(message);
        }
    }

    function handleClickOptionList(event) {

        const isChecked = event.currentTarget.checked;
        const value = Number(event.currentTarget.value);
        const name = event.currentTarget.name;

        const arrNames = name.split('.');
        
        if(arrNames.length !== 2) {
            return;
        }

        const currentList = [...formik.values[arrNames[0]][arrNames[1]]];

        if (isChecked) {

            currentList.push(value);
            setFieldValue(name, [...currentList]);
            return;
        }

        const index = currentList.indexOf(value);
        currentList.splice(index, 1);
        setFieldValue(name, [...currentList]);
    }

    return {
        isOpenModal, openSendMessageModal, closeSendMessageModal, 
        componentState, removeAlert, formik, handleClickOptionList
    };
} 