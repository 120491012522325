import React from 'react';
import SelectCarMaker from '../../../../Components/Forms/select/SelectCarMaker';
import SelectCarColor from '../../../../Components/Forms/select/SelectCarColor';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioBtn from '../../../../Components/Forms/radio/RadioBtn';
import InputGroup from '../../../../Components/Forms/InputGroup';
import OwnershipCarType from '../../../../types/OwnershipCarType';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitCarDetails = () => {

    const { errors, values, touched, setFieldValue, handleChange, handleBlur, setFieldTouched } = usePermitDetailsPageContext();
    const carTitle = 'פרטי הרכב';

    return (
        <FormSection title={carTitle}>
            <div className="row">
                <div className='col-lg-6 col-xl-3'>
                    <InputGroup
                        name={'carNumber'}
                        label={'מספר רכב'}
                        value={values.carNumber}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired={true}
                        errorMsg={(errors.carNumber && touched.carNumber) ? errors.carNumber : undefined}
                    />
                </div>
                <div className="col-lg-6 col-xl-3">
                    <SelectCarMaker
                        name={'vType'}
                        value={values.vType}
                        handleChange={(option) => setFieldValue('vType', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vType', true);
                        }}
                        errorMsg={(errors.vType && touched.vType) ? errors.vType : undefined}
                    />
                </div>
                <div className="col-lg-6 col-xl-3">
                    <SelectCarColor
                        name={'vColor'}
                        value={values.vColor}
                        handleChange={(option) => setFieldValue('vColor', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('vColor', true);
                        }}
                        errorMsg={(errors.vColor && touched.vColor) ? errors.vColor : undefined}
                    />
                </div>
                <div className="col">
                    <RadioBtnsContainer label={'בעלות רכב'}>
                        <RadioBtn
                            isChecked={values.ownershipCarType === OwnershipCarType.PRIVATE ? true : false}
                            label='רכב פרטי'
                            value={OwnershipCarType.PRIVATE}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeYes"
                            name='ownershipCarType'
                        />
                        <RadioBtn
                            isChecked={values.ownershipCarType === OwnershipCarType.COMPANY ? true : false}
                            label='רכב חברה'
                            value={OwnershipCarType.COMPANY}
                            onChange={() => { }}
                            onClick={handleClick}
                            id="ownershipCarTypeNo"
                            name='ownershipCarType'
                        />
                    </RadioBtnsContainer>

                </div>
            </div>

        </FormSection>
    );

    function handleClick(e) {
        setFieldValue('ownershipCarType', Number(e.currentTarget.value));
    }
}

export default PermitCarDetails