import React from 'react'
import RedStar from '../RedStar';

const SelectInput = ({ name, label, errorMsg, selects, isRequired, handleChange, handleBlur, value, disabled }) => {

    let classes = 'form-select ';
    const options = selects.map((option, index) => {

        return <option key={`select${name}_${index}`} value={option.value}>{option.name}</option>;
    });

    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes += ' is-invalid';
    }
    
    return (
        <>

            <label className="form-label" htmlFor={`${name}id`}>
                {label}
                {isRequired && <RedStar />}
            </label>

            <select
                className={classes}
                id={`${name}id`}
                name={name}
                value={value}
                onChange={handleChange}
                required={isRequired}
                onBlur={handleBlur}
                aria-label="Default select example"
                disabled={disabled}
                >
                {options}
            </select>
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    );
}

SelectInput.defaultProps = {
    disabled: false,
}

export default SelectInput;