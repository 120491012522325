import React from 'react';
import FormAddPermit from './components/FormAddPermit';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import Alert from '../../../Components/Alerts/Alert';
import PermitDetailsButtonsBox from './components/PermitDetailsButtonsBox';
import UploadFileModal from '../../../features/uploadFile/UploadFileModal';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import { useAddPermitPageContext } from '../../../contexts/permit/AddPermitPageContext';
import AlertExistingPermit from './components/alertsRequestPage/AlertExistingPermit';
import AlertExitingPermitWithSomePersonId from './components/alertsRequestPage/AlertExitingPermitWithSomePersonId';
import SuccesCreatePermitModal from './components/SuccesCreatePermitModal';

const AddPermitPage = () => {

  const { componentState, values, uploadFileModal, closeUploadFileModal, handleUploadFile,
    errorFileModal, isLoadingFileModal, removeAlert } = useAddPermitPageContext();

  const { isLoading, message, hasAlert,  } = componentState;

  return (
    <>

      <UploadFileModal
        isShow={uploadFileModal}
        error={errorFileModal}
        isLoading={isLoadingFileModal}
        closingFun={closeUploadFileModal}
        handleUploadFile={file => handleUploadFile(values.personId, file)} />

      <SuccesCreatePermitModal />

      {isLoading ? <LoadingModal /> : ''}

      <div className='row'>
        <div className='col-12'>
          <Alert onClose={removeAlert} isShow={hasAlert} type='danger' subject={'תו לא נוצר'} message={message} />
        </div>
      </div>

      <AlertExistingPermit />

      <AlertExitingPermitWithSomePersonId />

      <div className='row'>
        <div className='col-12'>
          <PageTitle title='דף יצירת תו' />
        </div>
      </div>

      <div className='row mt-1 mb-2'>
        <div className='col-12'>
          <PermitDetailsButtonsBox />
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <FormAddPermit />
        </div>
      </div>

      <div className='row mt-2'>
        <div className='col-12'>
          <PermitDetailsButtonsBox />
        </div>
      </div>
    </>
  );
}

export default AddPermitPage;