import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import { FileEarmarkSpreadsheet } from 'react-bootstrap-icons';

const ExportToCsvButton = ({ data, headers, getRowsFunc, fileName }) => {

    const title = 'יצוא דף לאקסל';
    const datas = getRowsFunc ? getRowsFunc : data;

    return (
        <CsvDownloader filename={fileName} extension=".csv" separator="," columns={headers} datas={datas}>
            <button className="btn btn-success">{title} <FileEarmarkSpreadsheet /></button>
        </CsvDownloader>
    );
}

ExportToCsvButton.defaultProps = {
    data: null,
    headers: null,
    getRowsFunc: null,
    fileName: 'csv file'
};


export default ExportToCsvButton;