import React, { useState, createContext, useContext, useMemo, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import RequestDetailsPage from "../../Pages/request/requestDetails/RequestDetailsPage";
import { useRequestDetails } from "../../Hooks/requests/useRequestDetails";
import DateUtils from "../../Helpers/time/DateUtils";
import { requestSchema } from "../../validation/request/permitRequestValidation";
import { ResponseStatus } from "../../types/types.import.js";
import { decodeNCRStringIfNeeded } from "../../Helpers/language";
import usePublicHistoryModal from "../../Hooks/history/usePublicHistoryModal";
import { initRequestPermitData } from "../../data/default/requestPermit.data.js";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js";
import { useComponentState } from "../../Hooks/useComponentState.js";
import { useProjectDetailsContext } from "../ProjectDetailsContext.jsx";

export const RequestDetailsPageContext = createContext();

const RequestDetailsPageProvider = () => {

    const { projectDetails } = useProjectDetailsContext();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();
    const dateUtils = new DateUtils();
    const { saveRequest, approveRequest, getPermitRequest } = useRequestDetails();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [requestId, setRequestId] = useState(-1);
    const { historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal } = usePublicHistoryModal();
    const [successModalIsShown, setSuccessModalIsShown] = useState(false);
    const [confirmModalIsShown, setConfirmModalIsShown] = useState(false);
    const [isFileModalOpen, setIsFileModalOpen] = useState(false);
    const [greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit] = useState('');
    const [exitingPermitWithSamePId, setExitingPermitWithSamePId] = useState(0);
    
    const refSendEmailModal = useRef(null);

    const { errors, values, touched, isValid, handleSubmit, handleBlur, setFieldValue, validateForm, setFieldTouched, setErrors } = useFormik({
        initialValues: { ...initRequestPermitData },
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: requestSchema,
    });


    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    // onload page
    useEffect(() => {

        if (requestId > 0)
            handleOnLoadingPage();
    }, [requestId]);

    useEffect(() => {

        setRequestId(params.requestId);
    }, [params.requestId]);

    async function handleOnLoadingPage() {

        setIsLoading();
        const response = await getPermitRequest(requestId);
        const paymentConfig = projectDetails.paymentConfig;
        const colorAndType = paymentConfig.colorAndType;

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        const citizenSticker = response.data.citizenSticker;
        const endDate = response.data.endDate;
        const greatestEndDateOfActivePermit = response.data.greatestEndDateOfActivePermit;
        const exitingPermitWithSamePId = response.data.exitingPermitWithSamePId;

        const vColor = colorAndType ? citizenSticker.agreeDataTransfer : 164;
        const vType = colorAndType ? citizenSticker.agreeToVolunteer : 2096;

        setGreatestEndDateOfActivePermit(greatestEndDateOfActivePermit);
        setExitingPermitWithSamePId(exitingPermitWithSamePId);

        setFieldValue('projectId', citizenSticker.projectId);
        setFieldValue('requestId', citizenSticker.id);
        setFieldValue('carNumber', citizenSticker.carNum);
        setFieldValue('personId', citizenSticker.personId);
        setFieldValue('phone', citizenSticker.phone);
        setFieldValue('mobile', citizenSticker.mobile);
        setFieldValue('house', citizenSticker.house);
        setFieldValue('appartment', citizenSticker.appartment);
        setFieldValue('street', citizenSticker.street);
        setFieldValue('email', citizenSticker.email);
        setFieldValue('hasPaidCash', citizenSticker.hasPaidCash);
        setFieldValue('hasPaidCredit', citizenSticker.hasPaidCredit);
        setFieldValue('asmachta', citizenSticker.asmachta);
        setFieldValue('moneyAmount', citizenSticker.moneyAmount);
        setFieldValue('reportNum', citizenSticker.reportNum);
        setFieldValue('insertUserId', citizenSticker.insertUserId);
        setFieldValue('tavDayar', citizenSticker.tavDayar);
        setFieldValue('hardCopyPersonId', citizenSticker.hardCopyPersonId);
        setFieldValue('hardCopyLicense', citizenSticker.hardCopyLicense);
        setFieldValue('hardCopyLeasing', citizenSticker.hardCopyLeasing);
        setFieldValue('approveUserId', citizenSticker.approveUserId);
        setFieldValue('userInsertName', citizenSticker.userInsertName);
        setFieldValue('createdBy', citizenSticker.createdBy);
        setFieldValue('status', citizenSticker.status);

        setFieldValue('privateName', decodeNCRStringIfNeeded(citizenSticker.privateName));
        setFieldValue('familyName', decodeNCRStringIfNeeded(citizenSticker.familyName));
        setFieldValue('mailCity', decodeNCRStringIfNeeded(citizenSticker.mailCity));
        setFieldValue('mailHouse', decodeNCRStringIfNeeded(citizenSticker.mailHouse));
        setFieldValue('mailStreet', decodeNCRStringIfNeeded(citizenSticker.mailStreet));
        setFieldValue('mailAppartment', decodeNCRStringIfNeeded(citizenSticker.mailAppartment));
        setFieldValue('mailMikod', decodeNCRStringIfNeeded(citizenSticker.mailMikod));
        setFieldValue('secreteryMessage', decodeNCRStringIfNeeded(citizenSticker.secreteryMessage));
        setFieldValue('endValidityDate', decodeNCRStringIfNeeded(endDate));

        setFieldValue('vColor', vColor);
        setFieldValue('vType', vType);
        setFieldValue('permitType', citizenSticker.tavGilZahav);
        setFieldValue('ownershipCarType', citizenSticker.vehiclePrivte);

        setFieldValue('citizenIdPath', citizenSticker.citizenIdPath);
        setFieldValue('carIdPath', citizenSticker.carIdPath);
        setFieldValue('workStatmentPath', citizenSticker.workStatmentPath);
        setFieldValue('extraFilesPaths', citizenSticker.extra3);

        let userRegisterDate = dateUtils.getDateAndTimeStringFromUnixTimestams(Number(citizenSticker.userRegisterDate));
        let updateDate = dateUtils.getDateAndTimeStringFromUnixTimestams(Number(citizenSticker.updateDate));
        let approvalDate = '';

        if (Number(citizenSticker.approvalDate) > 0) {

            approvalDate = dateUtils.getDateAndTimeStringFromUnixTimestams(Number(citizenSticker.approvalDate));
        }

        setFieldValue('userRegisterDate', userRegisterDate);
        setFieldValue('updateDate', updateDate);
        setFieldValue('approvalDate', approvalDate);
        
        setErrors({});
        setIsNotLoading();
    }

    async function handleUpdateStatus(newStatus) {
        const requestId = values.requestId;

        const form = new FormData();
        form.append('requestId', requestId);
        form.append('newStatus', newStatus);

        try {
            const response = await axiosPrivate.post('/request/update-status-request', form);

            if (response.status === ResponseStatus.FAILURE) {

                setDangerAlert('בעיה כללית בשרת');

                console.log(response.msg);
                return;
            }

            setFieldValue('status', newStatus);

        } catch (error) {

            console.log('error: ' + error);
        }
    }

    async function handleUpdateRequest() {

        setIsLoading();

        const jsonErrors = await validateForm();

        if (Object.keys(jsonErrors).length > 0) {

            setIsNotLoading();
            return;
        }

        const responseData = await saveRequest(values);
        if (responseData.status === ResponseStatus.SUCCESS) {

            setSuccessModalIsShown(true);
            setIsNotLoading();
        } else {

            setDangerAlert('בעיה כללית בשרת');
        }
    }

    async function handleApprove() {

        setIsLoading();

        const jsonErrors = await validateForm();

        if (Object.keys(jsonErrors).length > 0) {

            setIsNotLoading();
            return;
        }

        const response = await approveRequest(values);

        if (response.status === ResponseStatus.SUCCESS) {

            navigate('/permit-request/requestManagementPage');
            return;
        }

        setDangerAlert(response.message);
    }

    async function handlePreApprove() {
        const jsonErrors = await validateForm();

        if (Object.keys(jsonErrors).length > 0) return;

        setConfirmModalIsShown(true);
    }

    function handleCloseModal() {

        setSuccessModalIsShown(false);
        navigate('/permit-request/requestManagementPage');
    }

    function closeFilesModal() {
        setIsFileModalOpen(false);
    }

    function openFilesModal() {

        setIsFileModalOpen(true);
    }

    return (
        <RequestDetailsPageContext.Provider value={{
            handleChange, errors, values, touched, isValid, handleSubmit, handleBlur, setFieldValue, setFieldTouched,
            handleUpdateRequest, handleApprove, handlePreApprove, handleUpdateStatus, getPermitRequest,
            historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal,
            successModalIsShown, setSuccessModalIsShown,
            confirmModalIsShown, setConfirmModalIsShown, handleCloseModal,
            componentState, setIsLoading, setIsNotLoading,
            requestId, setRequestId,
            isFileModalOpen, closeFilesModal, openFilesModal, refSendEmailModal,
            greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit, exitingPermitWithSamePId, setExitingPermitWithSamePId
        }}>
            <RequestDetailsPage />
        </RequestDetailsPageContext.Provider>
    );


}

export default RequestDetailsPageProvider;

export const useRequestManagmentPageContext = () => useContext(RequestDetailsPageContext);