import React from 'react'
import Button from '../../../Components/Buttons/Button'
import { BiMessageAltDetail } from 'react-icons/bi';

const SendSmsBtn = ({ onClick, disabled }) => {

  let title = 'שלח מסרון';
  
  return (
    <Button
      style={{width: 'fit-content'}}
      onClick={onClick}
      disabled={disabled}
      endIcon={<BiMessageAltDetail />}>
      {title}
    </Button>
  );

}

export default SendSmsBtn;