class ZoneMap{

    constructor(jsonZones = []){
        let arr = [];

        if(Array.isArray(jsonZones)) arr = jsonZones;
        
        this.zoneMap = arr;
    }

    getAllZones(){

        return this.zoneMap;
    }
}

export default ZoneMap;