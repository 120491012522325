import React from 'react'
import RedStar from './RedStar';
import ClearButton from './ClearButton';
import InfoCircle from './InfoCircle';

const InputGroup = ({ styleInput, styleLabel, label, value, name,
    handleChange, handleBlur, errorMsg, isRequired, type, disabled, handleClearInput, infoMsg, size }) => {

    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if(size === 's') {
        classes += ' form-control-sm';
    } else if(size === 'l') {
        classes += ' form-control-lg';
    }
    
    if (hasError) {
        classes += ' is-invalid';
    }

    return (
        <>
            {label ? (
                <label className="form-label" style={styleLabel}>
                    {label}
                    {isRequired ? <RedStar /> : ''}
                    {infoMsg ? <>&nbsp;<InfoCircle infoMsg={infoMsg} /></> : ''}
                </label>
            ) : ''}

            {type === 'file' ? (
                <input type={type} onBlur={handleBlur} className={classes}
                    onChange={(event) => handleChange(event)} name={name} disabled={disabled} style={styleInput} />
            ) : (
                <input type={type} onBlur={handleBlur} className={classes} style={styleInput}
                    onChange={(event) => handleChange(event)} name={name} value={value} disabled={disabled} />
            )}
            {handleClearInput && <ClearButton onClick={() => handleClearInput(name)} />}

            {hasError ? <div className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

InputGroup.defaultProps = {
    styleInput: {},
    styleLabel: {},
    type: 'text',
    value: '',
    infoMsg: '',
    isRequired: false,
    disabled: false,
    errorMsg: '',
    handleChange: () => { },
    updateIsTouch: () => { },
    handleClearInput: undefined,
    size: 'm'
}

export default InputGroup