import React from 'react'
import CheckBtn from '../../../Components/Forms/check/CheckBtn';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const TextsOptionContainer = ({ textOptions, handleClick, handleBlur, values, name }) => {

    const arrNames = name.split('.');
    let currentList = [];

    if(arrNames.length === 2) {
        
        currentList = [...values[arrNames[0]][arrNames[1]]];
    }

    return (
        <div>
            {textOptions.map(textOption => {
                return (
                    <CheckBtn
                        onBlur={handleBlur}
                        name={name}
                        isChecked={currentList && currentList.includes(Number(textOption.code))}
                        value={textOption.code}
                        label={decodeNCRStringIfNeeded(textOption.text)}
                        id={textOption.text}
                        onClick={handleClick}
                        key={`textOptions${textOption.code}`}
                    />
                );
            })}
        </div>
    )
}

export default TextsOptionContainer;