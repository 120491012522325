import { useReducer } from "react";
import { initHistoryModalData } from "../../data/default/historyModal.data";
import { HISTORY_MODAL_ACTION, historyModalReducer } from "../../reducers/historyModalReducer";

function usePublicHistoryModal() { 

    const [historyModalState, historyModalStateDispatch] = useReducer(historyModalReducer, initHistoryModalData);
    
    function openHistoryModal(isRequest, identifier) {

        historyModalStateDispatch({
            type: HISTORY_MODAL_ACTION.OPEN_HISTORY_MODAL, 
            payload: {
                isRequest: isRequest,
                identifier: identifier,
            }
        });
    }

    function closeHistoryModal() {

        historyModalStateDispatch({type: HISTORY_MODAL_ACTION.CLOSE_HISTORY_MODAL});
    }

    return {historyModalState, historyModalStateDispatch, openHistoryModal, closeHistoryModal}
}

export default usePublicHistoryModal;