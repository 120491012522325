import axios from "axios";
import index from '../config/index';

const BASE_URL = index.baseApiUrlCitizenSticker;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL + '/secretary'
});
