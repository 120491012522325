import React from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Button from './Button';

const ViewPermitBtn = ({ rowDbId, size }) => {

    const url = `/permits/permit-details/${rowDbId}`;

    return (
        <Link to={url}>
            <Button size={size} endIcon={<AiOutlineFileSearch />}>
                צפייה בתו
            </Button>
        </Link>);
}

ViewPermitBtn.defaultProps = {
    size: '',
};

export default ViewPermitBtn;