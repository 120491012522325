import React from 'react';
import Style from './listOfRows.module.css';

const RowCardItem = ({ label, value }) => {

    return (
        <div className={Style.rowCardItem}>
            <div className={Style.rowCardItemLabel}>{label}:</div>
            <div className={Style.rowCardItemValue}>{value}</div>
        </div>
    )
}

export default RowCardItem