import React from 'react';
import TextsOptionContainer from './TextsOptionContainer';
import InputErrorMessage from '../../../Components/Forms/InputErrorMessage';
import style from '../sendEmail.module.css';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';

const TextOptionsRow = ({ textOptions, errorChecksInputAndFreeText, handleBlur, handleClick, values, name }) => {

    const { projectDetails } = useProjectDetailsContext();

    return (
        <>
            <span className={style.rejectionOption}>רשימת תגובות מובנות</span>
            <TextsOptionContainer textOptions={textOptions} handleBlur={handleBlur} handleClick={handleClick} values={values} name={name} />
            {errorChecksInputAndFreeText ? (
                <InputErrorMessage key='errorChecksInputAndFreeTextId' message={errorChecksInputAndFreeText} />
            ) : ''}
        </>
    );
}

export default TextOptionsRow;