
export default class DateUtils{

    /**
    * @abstract convert Unix timestamps in milliseconds to date.
    * @param {Number} number milisecads - milisecads
    * @returns {string} dateStr - return the date formeted yyyy-mm-dd 
    */
    getDateStringFromUnixTimestams(dateNumber = 0){
        const date = new Date(Number(dateNumber));

        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

        return ye + '-' + mo + '-' + da;
    }

    /**
    * @abstract convert Unix timestamps in milliseconds to date.
    * @param {Number} number milisecads - milisecads
    * @returns {string} dateStr - return the date formeted yyyy-mm-dd HH
    */
    getDateAndTimeStringFromUnixTimestams(dateNumber = 0){
        const date = new Date(Number(dateNumber));

        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          };
      
        let timeStr = new Intl.DateTimeFormat('en-UK', options).format(date);
        return timeStr;
    }

    /**
    *
    * @param {Date} date - date object
    * @returns {string} dateStr - return the date formeted yyyy-mm-dd 
    */
    getDateString(date = new Date()){

        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

        return ye + '-' + mo + '-' + da;
    }

    /**
    *
    * @param {Date} date - date object
    * @returns {string} dateStr - return the date formeted yyyy-mm-dd 
    */
    getTodayDateString(){

        const d = new Date(); 
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

        return ye + '-' + mo + '-' + da;
    }
}