import PermitType from "../types/PermitType";

/** Calculate the end date of the permit base on it permit type and privuse permit.
 * @Method POST
 * @Body
 * {
 *   permitType - {@link PermitType},
 *   carNum - string,
 *   personId - string,
 *   streetId - int,
 *   houseNum - int,
 * }
 */
export const CALCULATE_INIT_PERMIT_DATA = "/request/calculate-init-permit-data";
