import { decodeNCRStringIfNeeded } from "../language";
import DateUtils from "../time/DateUtils";
import { convertCodeStatusRequestToText, convertHistoryStatusCodeToText, convertPermitStatusCodeToText } from "./convertTypeCodeToText";
import { convertColorCodeToText, convertMakerCodeToText, convertStreetCodeToText } from "./convertorTablesCodeToText";

export function convertHistoryRecordRawData(rawData, projectDetails, isRequest) {

    const dateUtils = new DateUtils();
    const jsonHistoryRecord = {};

    const statusDetails = rawData.statusDetails;
    const taveiDaiarHistoryDetails = rawData.taveiDaiarHistoryDetails;
    const historyStatus = statusDetails.status;
    const statusPermit = Number(taveiDaiarHistoryDetails.statusTav);
    const streetCode = taveiDaiarHistoryDetails.street;
    const vehicleMakeCode = taveiDaiarHistoryDetails.vehicleMake;
    const vehicleColorCode = taveiDaiarHistoryDetails.vehicleColor;
    const streetStr = convertStreetCodeToText(streetCode, projectDetails.streets.getAllStreets());
    const vehicleMakeStr = convertMakerCodeToText(vehicleMakeCode, projectDetails.types.getAllMakers());
    const vehicleColorStr = convertColorCodeToText(vehicleColorCode, projectDetails.colors.getAllColors());
    
    jsonHistoryRecord['dateTime'] = dateUtils.getDateAndTimeStringFromUnixTimestams(statusDetails.date);
    jsonHistoryRecord['status'] = historyStatus;
    jsonHistoryRecord['statusDetails'] = rawData.statusDetails;
    
    jsonHistoryRecord['statusName'] = convertHistoryStatusCodeToText(historyStatus);
    jsonHistoryRecord['statusTav'] = taveiDaiarHistoryDetails.statusTav;
    jsonHistoryRecord['fName'] = decodeNCRStringIfNeeded(taveiDaiarHistoryDetails.firstName);
    jsonHistoryRecord['lName'] = decodeNCRStringIfNeeded(taveiDaiarHistoryDetails.lastName);
    jsonHistoryRecord['requestStatus'] = isRequest ? convertCodeStatusRequestToText(statusPermit) : 'ריק';
    jsonHistoryRecord['permitStatus'] = (!isRequest) ? convertPermitStatusCodeToText(statusPermit) : 'ריק';
    jsonHistoryRecord['requestType'] = taveiDaiarHistoryDetails.tavGilZahav; 
    jsonHistoryRecord['streetName'] = decodeNCRStringIfNeeded(streetStr);
    jsonHistoryRecord['makerText'] = decodeNCRStringIfNeeded(vehicleMakeStr);
    jsonHistoryRecord['colorText'] = decodeNCRStringIfNeeded(vehicleColorStr);
    jsonHistoryRecord['approvalDate'] = dateUtils.getDateAndTimeStringFromUnixTimestams(taveiDaiarHistoryDetails.approvalDate);
    jsonHistoryRecord['endValidityDate'] = dateUtils.getDateAndTimeStringFromUnixTimestams(taveiDaiarHistoryDetails.endValidityDate);
    jsonHistoryRecord['startValidityDate'] = dateUtils.getDateAndTimeStringFromUnixTimestams(taveiDaiarHistoryDetails.startValidityDate);

    jsonHistoryRecord['userRegisterDate'] = dateUtils.getDateAndTimeStringFromUnixTimestams(taveiDaiarHistoryDetails.userRegisterDate);
    jsonHistoryRecord['userUpdateDate'] = dateUtils.getDateAndTimeStringFromUnixTimestams(taveiDaiarHistoryDetails.userUpdateDate);

    jsonHistoryRecord['hasPaidCash'] = taveiDaiarHistoryDetails.hasPaidCash;
    jsonHistoryRecord['hasPaidCredit'] = taveiDaiarHistoryDetails.hasPaidCredit;
    jsonHistoryRecord['createdBy'] = taveiDaiarHistoryDetails.extraValue1;
    jsonHistoryRecord['email'] = taveiDaiarHistoryDetails.email;
    jsonHistoryRecord['asmachta'] = taveiDaiarHistoryDetails.asmachta;
    jsonHistoryRecord['amount'] = taveiDaiarHistoryDetails.amount;
    jsonHistoryRecord['house'] = taveiDaiarHistoryDetails.house;
    jsonHistoryRecord['appartment'] = taveiDaiarHistoryDetails.appartment;
    jsonHistoryRecord['personId'] = taveiDaiarHistoryDetails.personId;
    jsonHistoryRecord['mobile'] = taveiDaiarHistoryDetails.mobile;
    jsonHistoryRecord['phone'] = taveiDaiarHistoryDetails.phone;
    jsonHistoryRecord['vehicleNum'] = taveiDaiarHistoryDetails.vehicleNum;

    jsonHistoryRecord['mailAppt'] = taveiDaiarHistoryDetails.mailAppt;
    jsonHistoryRecord['mailCity'] = taveiDaiarHistoryDetails.mailCity;
    jsonHistoryRecord['mailHouse'] = taveiDaiarHistoryDetails.mailHouse;
    jsonHistoryRecord['mailStreet'] = taveiDaiarHistoryDetails.mailStreet;
    jsonHistoryRecord['mailZip'] = taveiDaiarHistoryDetails.mailZip;

    return jsonHistoryRecord;
}