import useAxiosPrivate from '../useAxiosPrivate';
import { useAuthContextContext } from '../../contexts/AuthContext';
import translateErrorCode from '../../Helpers/errors/translateErrorCode';

const useViewExpiredPermits = () => {

    const {logout} = useAuthContextContext()
    const axiosPrivate = useAxiosPrivate();

    async function getExpiredPermits (values) {

        const result = {
            isSuccess: false,
            message: '',
            data: { count: 0, list: [] }
        }
 
        const form = {
            from: values.startDate,
            to: values.endDate,
            itemPerPage: values.limit,
            offset: values.offset,
            onlyActivePermits: values.onlyActivePermits === '1'
        };

        try {
            
            const response = await axiosPrivate.post(
                '/permit/get-end-validity-permits', JSON.stringify(form), { headers: { "Content-Type": "application/json" } });
            const responseData = response.data;
            
            result.data.count = responseData?.data?.count ? responseData.data.count : 0;
            result.data.permits = responseData?.data?.permits ? responseData.data.permits : [];
            result.isSuccess = true;
            result.message = 'success';

        }catch(error) {

            result.message = 'בעיה כללית בשרת';
            result.isSuccess = false;
      
            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }

        return result;
    }

    return { getExpiredPermits };
}

export default useViewExpiredPermits;