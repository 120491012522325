export const EXPIRED_PERMITS_ACTION = {
    UPDATE_LIST: 'UPDATE_LIST',
}

export const expiredPermitsReducer = (state, action) => {
 
    switch (action.type) {
        case EXPIRED_PERMITS_ACTION.UPDATE_LIST:
         
            return {...state, 
                count:action.payload.count, 
                list:action.payload.list,
            };
        default:
            return state;
    }
}