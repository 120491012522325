import { SendMsgOptionType } from "../../types/SendMsgOptionType";


export function convertMessageConfigFromServer(messageConfigRaw) {
    
    return {
        "sendingCitizenOtpCodeVia": messageConfigRaw?.sendingCitizenOtpCodeVia ? messageConfigRaw?.sendingCitizenOtpCodeVia: SendMsgOptionType.EMAIL,
        "sendingCitizenMsgVia": messageConfigRaw?.sendingCitizenMsgVia ? messageConfigRaw?.sendingCitizenMsgVia: SendMsgOptionType.EMAIL,
        "messageEmailConfig": convertMessageEmailConfigFromServer(messageConfigRaw?.messageEmailConfig ? messageConfigRaw.messageEmailConfig : {}),
        "messageSmsConfig": convertMessageSmsConfigFromServer(messageConfigRaw?.messageSmsConfig ? messageConfigRaw.messageSmsConfig : {})
    }
}

function convertMessageEmailConfigFromServer(messageEmailConfigRaw) {

    const generalEmailConfig = messageEmailConfigRaw?.generalEmailConfig ? messageEmailConfigRaw.generalEmailConfig: {};
    const rejectionEmailConfig = messageEmailConfigRaw?.rejectionEmailConfig ? messageEmailConfigRaw.rejectionEmailConfig: {};

    return {
        "generalEmailConfig": {
            "enableOption": generalEmailConfig?.enableOption ? generalEmailConfig.enableOption: false,
            "cityEmailAddress": generalEmailConfig?.cityEmailAddress ? generalEmailConfig.cityEmailAddress: "",
            "contentOptions": generalEmailConfig?.contentOptions ? generalEmailConfig.contentOptions: [],
            "subject": generalEmailConfig?.subject ? generalEmailConfig.subject: "",
        },
        "rejectionEmailConfig": {
            "enableOption": rejectionEmailConfig?.enableOption ? rejectionEmailConfig.enableOption: false,
            "cityEmailAddress": rejectionEmailConfig?.cityEmailAddress ? rejectionEmailConfig.cityEmailAddress: "",
            "contentOptions": rejectionEmailConfig?.contentOptions ? rejectionEmailConfig.contentOptions: [],
            "subject": rejectionEmailConfig?.subject ? rejectionEmailConfig.subject: "",
        }
    }
}

function convertMessageSmsConfigFromServer(MessageSmsConfigRaw) {

    const generalSmsConfig = MessageSmsConfigRaw?.generalSmsConfig ? MessageSmsConfigRaw.generalSmsConfig: {};
    const rejectionSmsConfig = MessageSmsConfigRaw?.rejectionSmsConfig ? MessageSmsConfigRaw.rejectionSmsConfig: {};

    return {
        "generalSmsConfig": {
            "enableOption": generalSmsConfig?.enableOption ? generalSmsConfig.enableOption: false,
            "contentOptions": generalSmsConfig?.contentOptions ? generalSmsConfig.contentOptions: [],
        },
        "rejectionSmsConfig": {
            "enableOption": rejectionSmsConfig?.enableOption ? rejectionSmsConfig.enableOption: false,
            "contentOptions": rejectionSmsConfig?.contentOptions ? rejectionSmsConfig.contentOptions: [],
        }
    }
}